import React, { Component } from "react";
import { 
  EuiPage, 
  EuiPageBody,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiModalFooter,
  EuiButton,
  EuiButtonEmpty,
  EuiFlexItem,
  EuiDatePicker,
} from "@elastic/eui";
import TablePage from "../components/TablePage";
import Utils from "../Utils";
import Error from '../services/Error';
import Voucher from '../services/Vouchers';
import { debounce } from 'lodash';
import moment from "moment";

const VouchersService = new Voucher();
const ErrorService = new Error();

class Vouchers extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    order: 'ASC',
    orderBy: 'code',
    loading: false,
    saveLoading: false,
    deleteLoading: false,
    data: [],
    column: [
      
      {
        field: "code",
        name: "Kode"
      },
      {
        field: "value",
        name: "Nilai Diskon"
      },
      {
        field: "startTime",
        name: "Tanggal Mulai",
      },
      {
        field: "endTime",
        name: "Tanggal Selesai",
      },
      {
        field: "quota",
        name: "Kuota",
      },
      {
        field: "maximumDiscount",
        name: "Maksimum Diskon",
      },
    ],
    code: '',
    value: '',
    startTime: moment(),
    endTime: moment(),
    quota: 0,
    maximumDiscount: 0,
    oldCode: '',
    modal: false,
    validationFields: {
      code: {
        type: "string",
        isInvalid: false,
        errors: ["Kode tidak boleh kosong"],
        isValidFunc: function (value) {
          return value && value.length > 0;
        }
      },
      value: {
        type: "string",
        isInvalid: false,
        errors: ["Nilai diskon tidak boleh kosong","Masukkan jumlah diskon dengan benar. Ex: 20%, =20000, -50000"],
        isValidFunc: function (value) {
          if (value === null) {
            return false;
          }
          const re = /^[0-9 % = -]+$/;
          return  re.test(value) && value.length > 0 && (value.indexOf("%") === value.length -1 || value.indexOf("-") === 0 || value.indexOf("=") === 0);
        }
      },
      startTime: {
        type: "string",
        isInvalid: false,
        errors: ["Tanggal mulai tidak boleh kosong"],
        isValidFunc: function (value) {
            return value && value._isAMomentObject;
        }
      },
      endTime: {
        type: "string",
        isInvalid: false,
        errors: ["Tanggal selesai tidak boleh kosong"],
        isValidFunc: function (value) {
            return value && value._isAMomentObject;
        }
      },
    },
  }

  page = page => {
    page = page || 1;
    this.setState({loading: true});
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };
    var result
    VouchersService.getAllVouchers(payload)
      .then(response => {
        result = response
        console.log(result)
        for (let i in result.data) {
          result.data[i].startTime = moment.unix(result.data[i].start_time).format('YYYY-MM-DD HH:mm:ss');
          result.data[i].endTime = moment.unix(result.data[i].end_time).format('YYYY-MM-DD HH:mm:ss');
          if (result.data[i].quota == null) {
            result.data[i].quota = 0
          }
          if (result.data[i].maximum_discount == null) {
            result.data[i].maximumDiscount = 0
          } else {
            result.data[i].maximumDiscount = result.data[i].maximum_discount
          }
        }
        console.log(result)
        this.setState({
          page: result.page,
          limit: result.limit,
          total: result.total,
          order: result.order,
          orderBy: result.order_by,
          data: result.data || [],
          loading: false,
        },() => { this.forceUpdate(); });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loading: false,
          data: [],
        });
      });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };
  componentDidMount = () => {
    this.page(1);
  };

  add = () => {
    this.setState({
      oldCode: '',
      code: '',
      value: '',
      startTime: moment(),
      endTime: moment(),
      quota: 0,
      maximumDiscount: 0,
      modal: true,
      isNew: true,
    })
  }

  handleChange = e => {
    let value = e.target.value || null;
    let obj = {};
    obj[e.target.name] = value;
    obj["failedAttempt"] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };
  
  save = () => {
    let state = { ...this.state };
    this.setState({ saveLoading: true }, () => {
      Utils.validateFields(state).then(result => {
        this.setState({ validationFields: result.validationFields });
        this.forceUpdate();
        if (!result.isValid) {
          this.setState({ saveLoading: false })
          console.log(result);
          return;
        }
        let payload = {
          code: this.state.code,
          value: this.state.value,
          start_time: this.state.startTime.unix(),
          end_time: this.state.endTime.unix(),
          quota: parseInt(this.state.quota),
          maximum_discount: parseFloat(this.state.maximumDiscount),
        }
        console.log(payload);
        if (!this.state.isNew && this.state.oldCode.length > 0) {
          VouchersService.updateVouchers(payload, this.state.oldCode)
          .then((result) => {
            this.setState({ saveLoading: false, modal: false })
            this.page(1);
          })
          .catch((err) => {
            this.setState({ saveLoading: false });
            ErrorService.handle(err);    
          })
        } else {
          VouchersService.createVoucher(payload)
          .then((result) => {
            this.setState({ saveLoading: false, modal: false, isNew: false })
            this.page(1);
          })
          .catch((err) => {
            console.log(err)
            this.setState({ saveLoading: false });
            ErrorService.handle(err);    
          })
        }
      });
    });
  };

  onItemClick = (item) => {
    console.log(item)
    this.setState({
      oldCode: item.code,
      code: item.code,
      value: item.value,
      startTime: moment(item.startTime, 'YYYY-MM-DD HH:mm:ss'),
      endTime: moment(item.endTime, 'YYYY-MM-DD HH:mm:ss'),
      quota: item.quota,
      maximumDiscount: item.maximumDiscount,
      modal: true,
      isNew: false,
    })
  }

  delete = () => {
    if (!(this.state.code && this.state.code.length > 0)) {
      return;
    }
    if (!window.confirm("Apakah Anda yakin ingin menghapus item ini?")) {
      return;
    }
    this.setState({ deleteLoading: true})
    VouchersService.deleteVoucher(this.state.code)
      .then(result => {
        this.setState({ modal: false, deleteLoading: false });
        this.page(1);
      })
      .catch(err => {
        console.log(err.response)
        this.setState({ modal: false, deleteLoading: false });
        ErrorService.handle(err);
      });
  };

  handleOnSearch = value => {
    this.setState({ search: value, loading: true });
    this.handleSearchDebounce();
  };

  handleSearchDebounce = debounce(() => {
    this.page(1)
  }, 2000);

  handleDateChangeStart = (date) => {
    // in moment object format
    console.log(date)
    this.setState({ startTime: date });
  };

  handleDateChangeEnd = (date) => {
    // in moment object format
    console.log(date)
    this.setState({ endTime: date });
  };

  render() {
      return (
        <>
        {this.state.modal && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => {this.setState({ modal: false })}}
              initialFocus="[name=popswitch]"
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>
                  Tambah Voucher 
                </EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                <EuiForm>
                  <EuiFormRow 
                    label="Kode"
                    isInvalid={
                      this.state.validationFields['code']
                        .isInvalid
                    }
                    error={
                      this.state.validationFields['code']
                        .isInvalid &&
                      this.state.validationFields['code']
                        .errors
                    }
                  >
                    <EuiFieldText
                      placeholder="Kode"
                      value={this.state.code}
                      name="code"
                      onChange={this.handleChange}
                      aria-label="code"
                    />
                  </EuiFormRow>
                  <EuiFormRow 
                    label="Nilai Diskon"
                    isInvalid={
                      this.state.validationFields['value']
                        .isInvalid
                    }
                    error={
                      this.state.value === null || this.state.value === "" ? this.state.validationFields['value']
                        .errors[0] : this.state.validationFields['value'].errors[1]
                      }
                  >
                    <EuiFieldText
                      placeholder="Contoh: 15%,-10000,=50000"
                      value={this.state.value}
                      onChange={this.handleChange}
                      name="value"
                      aria-label="value"
                    />
                  </EuiFormRow>
                  <EuiFormRow
                    label="Tanggal Mulai"
                    isInvalid={
                      this.state.validationFields['startTime']
                        .isInvalid
                    }
                    error={
                      this.state.validationFields['startTime']
                        .isInvalid &&
                      this.state.validationFields['startTime']
                        .errors
                    }
                  >
                    <EuiDatePicker
                      selected={this.state.startTime}
                      onChange={this.handleDateChangeStart}
                    />
                  </EuiFormRow>
                  <EuiFormRow
                    label="Tanggal Akhir"
                    isInvalid={
                      this.state.validationFields['endTime']
                        .isInvalid
                    }
                    error={
                      this.state.validationFields['endTime']
                        .isInvalid &&
                      this.state.validationFields['endTime']
                        .errors
                    }
                  >
                    <EuiDatePicker
                      selected={this.state.endTime}
                      onChange={this.handleDateChangeEnd}
                    />
                  </EuiFormRow>
                  <EuiFormRow label="Kuota">
                    <EuiFieldText
                      placeholder="Kuota"
                      value={this.state.quota}
                      onChange={this.handleChange}
                      name="quota"
                      aria-label="quota"
                    />
                  </EuiFormRow>
                  <EuiFormRow label="Maksimum Diskon">
                    <EuiFieldText
                      placeholder="Maksimum Diskon"
                      value={this.state.maximumDiscount}
                      onChange={this.handleChange}
                      name="maximumDiscount"
                      aria-label="maximumDiscount"
                    />
                  </EuiFormRow>
                </EuiForm>
              </EuiModalBody>
              <EuiModalFooter>
                <EuiButtonEmpty
                  onClick={() => {this.setState({ modal: false })}}
                >
                  Batal
                </EuiButtonEmpty>
                {this.state.oldCode && this.state.oldCode.length > 0 ? (
                  <>
                    <EuiButton
                      color={"danger"}
                      onClick={() => this.delete()}
                      disabled={this.state.saveLoading}
                      isLoading={this.state.deleteLoading}
                      fill
                    >
                      Hapus
                    </EuiButton>
                    <EuiButton fill isLoading={this.state.saveLoading} disabled={this.state.deleteLoading} onClick={() => this.save()}>
                      Update
                    </EuiButton>
                  </>
                ) : (
                  <EuiButton fill isLoading={this.state.saveLoading} disabled={this.state.deleteLoading} onClick={() => this.save()}>
                    Tambah
                  </EuiButton>
                )}
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: "row" }}
            className={"content-container"}
          >
            <TablePage
              title={"Voucher"}
              buttonLabel={'Tambah Baru'}
              buttonFunc={() => this.add()}
              placeholder={"Nama Voucher"}
              data={this.state.data}
              column={this.state.column}
              disablePagination={this.state.loading}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={this.page}
              onItemClick={this.onItemClick}
              loading={this.state.loading}
              searchComp={
                <EuiFlexItem>
                  <EuiFieldText
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        this.handleOnSearch(this.state.search);
                      }
                    }}
                    value={this.state.search}
                    onChange={e => this.handleOnSearch(e.target.value)}
                    placeholder={this.props.placeholder}
                    append={
                      <EuiButtonEmpty
                        onClick={() => this.handleOnSearch(this.state.search)}>
                        Cari
                      </EuiButtonEmpty>
                    }
                  />
                </EuiFlexItem>
              }
            />
          </EuiPageBody>
        </EuiPage>
      </>
      )
  }
}

export default Vouchers;
