import React, { Component } from 'react';
import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiSpacer,
  EuiPageContentBody,
  EuiTitle,
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalHeader,
  EuiOverlayMask,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiFormRow,
  EuiFilePicker,
  EuiModalFooter,
  EuiImage,
} from '@elastic/eui';
import TablePage from '../components/TablePage';
import swal from 'sweetalert';
import Blobs from '../services/Blob';
import Config from '../Config';

const blobService = new Blobs();

class BannerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      page: 1,
      limit: 10,
      total: 0,
      search: '',
      saveLoading: false,
      deleteLoading: false,
      data: [],
      file: null,
      showModal: false,
      column: [
        {
          field: 'id',
          name: 'ID',
        },
        {
          field: 'filename',
          name: 'Nama Banner',
        },
        {
          field: '',
          name: 'Banner',
          render: (item) => (
            <EuiImage
              size='m'
              allowFullScreen
              alt={item.filename}
              src={Config.apiHost + '/api/banner/' + item.id}
            />
          ),
        },
        {
          field: '',
          name: 'URL Banner',
          render: (item) => (
            <a href={Config.apiHost + '/api/banner/' + item.id}>
              {Config.apiHost + '/api/banner/' + item.id}
            </a>
          ),
        },
        {
          field: '',
          name: 'Aksi',
          render: (item) => (
            <>
              <EuiButtonEmpty
                color='danger'
                onClick={() => {
                  if (
                    !window.confirm(
                      'Apakah Anda yakin ingin menghapus banner ini?'
                    )
                  ) {
                    return;
                  }

                  blobService
                    .deleteBannerByID(item.id)
                    .then((res) => {
                      swal('Sukses', 'Banner berhasil dihapus', 'success');
                      this.setState({ isLoading: true }, () =>
                        this.page(this.state.page)
                      );
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                Hapus
              </EuiButtonEmpty>
            </>
          ),
        },
      ],
    };
  }

  componentDidMount = () => {
    this.page(1);
  };

  page = (page) => {
    page = page || 1;
    blobService
      .getBanners(this.state.limit, page, this.state.search)
      .then((res) => {
        this.setState({
          page: res.page,
          data: res.list || [],
          total: res.total || 0,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false, data: [] });
      });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  render() {
    let modal;
    if (this.state.showModal) {
      modal = (
        <EuiOverlayMask>
          <EuiModal
            onClose={() => {
              if (this.state.saveLoading) {
                return;
              }

              this.setState({
                showModal: false,
                file: null,
              });
            }}
            initialFocus='[name=reports]'
          >
            <EuiModalHeader>
              <EuiModalHeaderTitle>Unggah Berkas Banner</EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <EuiFormRow
                label='Unggah Banner'
                helpText={
                  <p style={{ fontSize: 12, marginBottom: 4 }}>
                    Gunakan rasio gambar persegi panjang (7:3) atau ukuran
                    350x150 piksel.
                  </p>
                }
              >
                <EuiFilePicker
                  accept='image/png, image/gif, image/jpeg'
                  isInvalid={this.state.errorUpload}
                  onChange={(file) => {
                    this.setState({
                      file: file[0],
                      errorUpload: false,
                    });
                  }}
                  isLoading={this.state.saveLoading}
                />
              </EuiFormRow>
            </EuiModalBody>
            <EuiModalFooter>
              <EuiButton
                isLoading={this.state.saveLoading}
                onClick={() => {
                  if (
                    !(
                      this.state.file?.type === 'image/png' ||
                      this.state.file?.type === 'image/jpeg' ||
                      this.state.file?.type === 'image/gif'
                    )
                  ) {
                    alert(
                      'Berkas yang diunggah hanya boleh berjenis png, jpeg, atau gif'
                    );
                    this.setState({ errorUpload: true });
                    return;
                  }

                  if (
                    !window.confirm(
                      'Apakah Anda yakin ingin menggungah banner ini?'
                    )
                  ) {
                    return;
                  }

                  this.setState({ saveLoading: true }, () => {
                    blobService
                      // .uploadImage(this.state.file)
                      .uploadFile(this.state.file, 'banner')
                      .then((res) => res)
                      .then((blobID) => {
                        swal({
                          title: 'Berhasil unggah banner',
                          icon: 'success',
                          type: 'success',
                        }).then((value) => {
                          this.setState(
                            {
                              saveLoading: false,
                              showModal: false,
                              loading: true,
                            },
                            () => this.page(this.state.page)
                          );
                        });
                      })
                      .catch((err) => {
                        console.log(err);
                        this.setState({ saveLoading: false });
                      });
                  });
                }}
                fill
                disabled={this.state.saveLoading || !this.state.file}
              >
                Submit
              </EuiButton>
              <EuiButtonEmpty
                disabled={this.state.saveLoading}
                onClick={() =>
                  this.setState({
                    file: null,
                    showModal: false,
                  })
                }
              >
                Batal
              </EuiButtonEmpty>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      );
    }
    console.log(this.state);
    return (
      <EuiPage style={{ textAlign: 'left' }}>
        <EuiPageBody className={'content-container'}>
          {modal}
          <EuiPageContent>
            <TablePage
              title={'Daftar Banner'}
              data={this.state.data}
              column={this.state.column}
              disablePagination={this.state.loading}
              loading={this.state.loading}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={(page) =>
                this.setState({ isLoading: true }, () => this.page(page))
              }
              buttonLabel={'Tambah banner'}
              buttonFunc={() => this.setState({ showModal: true, file: null })}
            />
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
    );
  }
}

export default BannerPage;
