import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';

const ErrorService = new Error();
const MiscService = new Misc();

class Medicines {
  // Applications
  getApplications = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.Order || 'ASC';
      let url = `${Config.apiHost}/api/medicine-applications?page=${payload.page}&limit=${payload.limit}&order=${payload.order}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  createApplication = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/medicine-application`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  updateApplication = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/medicine-application/${payload.id}`;
      axios
        .put(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  deleteApplication = (id) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/medicine-application/${id}`;
      axios
        .delete(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  // Dosages
  getDosages = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.Order || 'ASC';
      let url = `${Config.apiHost}/api/medicine-dosages?page=${payload.page}&limit=${payload.limit}&order=${payload.order}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  createDosage = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/medicine-dosage`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  updateDosage = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/medicine-dosage/${payload.id}`;
      axios
        .put(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  deleteDosage = (id) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/medicine-dosage/${id}`;
      axios
        .delete(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  // Medicines
  getMedicines = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.order || 'DESC';
      payload.search = payload.search || '';
      let url = `${Config.apiHost}/api/medicines?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&search=${payload.search}`;
      if (payload.search && payload.search.length > 0) {
        url += `&search=${payload.search}`;
      }
      if (payload.vendor && payload.vendor.length > 0) {
        url += `&vendor=${payload.vendor}`;
      }
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  createMedicine = (payload) => {
    console.log(payload);
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/medicine`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  updateMedicine = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/medicine/${payload.id}`;
      axios
        .put(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  deleteMedicine = (id) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/medicine/${id}`;
      axios
        .delete(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  searchMedicines = (name, vendor) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/medicines?search=${name}&vendor=${vendor}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getSizeUnit = (vendor) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/medicine-numero-units?vendor=${vendor}&limit=1000`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getMedicineInstructions = () => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/medicine-instructions?limit=20&orderBy=index_position&order=ASC'`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getMedicineDosages = () => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/medicine-dosages?limit=999&orderBy=index_position&order=ASC'`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  postMedicinePrescription = (payload) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/prescription`;
      payload = payload || {};
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  postMedicinePrescriptionRef = (payload) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/prescription-ref`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getMedicinesStock = (payload) => {
    return new Promise((resolve, reject) => {
    let url = `${Config.apiHost}/api/v1/get-medicine-stock`;
    axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

}

export default Medicines;
