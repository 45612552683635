import React, { Component } from 'react';
import { debounce } from 'lodash';
import {
  EuiPage,
  EuiPageBody,
  EuiFlexItem,
  EuiFieldText,
  EuiButtonEmpty,
  EuiPopover,
  EuiPinnableListGroup,
  EuiCollapsibleNavGroup,
  EuiPinnableListGroupItemProps,
  EuiTab,
  EuiPageContent,
  EuiPageContentHeaderSection,
  EuiTitle,
  EuiButtonIcon,
  EuiPageContentHeader,
  EuiTabs,
  EuiPageContentBody,
  EuiFlexGroup,
  EuiSelect,
  EuiDatePicker,
  EuiOverlayMask,
  EuiModal,
  EuiText,
  EuiModalHeaderTitle,
  EuiModalHeader,
  EuiModalFooter,
  EuiButton,
  EuiTextArea,
  EuiModalBody,
  EuiPanel
} from '@elastic/eui';
import TablePage from '../components/TablePage';
import PrescriptionModal from '../components/PrescriptionModal';

import PharmaciesService from '../services/Pharmacies';
import moment from 'moment';
import Blobs from '../services/Blob';
import Patients from '../services/Patients';
import downloadjs from 'downloadjs';
import Config from '../Config';
import swal from 'sweetalert';
import Payments from '../services/Payments';

const pharmaciesService = new PharmaciesService();
const blobService = new Blobs();
const patientService = new Patients();
const paymentsService = new Payments();

const PrescriptionStatus = {
  Canceled: 8,
  Done: 10,
};

const DetailResepModal = (props) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const showModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);
  const [description,  setdescription] = React.useState('')

  const updatePrescriptionRefStatus = (status) => {
    console.log(props.item);
    console.log(description);
    let payload = {
      id: props.item.id,
      status: status,
      description: description,
    };
    pharmaciesService
      .updatePrescriptionRef(payload)
      .then((result) => {
        console.log(result);
        swal({
          title: 'Status berhasil diubah.',
          icon: 'success',
          type: 'success',
        });
        closeModal();
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: '',
          text: 'Gagal mengubah status.',
          icon: 'error',
          type: 'error',
          confirmButtonText: 'OK',
          closeOnClickOutside: true,
          closeOnEsc: true,
        });
      });
  };

  const renderItem = () => {
    let list = [];
    for (let i in props.item.items) {
      // check for compound
      if ((props.item.items[i].compounded_items || []).length > 0) {
        // get the name of the compound in string
        const compoundName = props.item.items[i].compounded_items
          .map((item) => {
            return item.medicine_name;
          })
          .join(', ');

        // build list item for compound
        let listItemCompound: EuiPinnableListGroupItemProps[] = [];
        for (let j in props.item.items[i].compounded_items) {
          listItemCompound.push({
            // create list item html
            label: (
              // border
              <div style={{ borderBottom: '1px solid gray', marginLeft: 20 }}>
                <div>
                  <b>{props.item.items[i].compounded_items[j].medicine_name}</b>
                </div>
                <div>
                  <span>
                    {`Numero: ${props.item.items[i].compounded_items[j].numero} ${props.item.items[i].compounded_items[j].numero_unit}`}
                  </span>
                </div>
              </div>
            ),
          });
        }

        const listItem: EuiPinnableListGroupItemProps[] = [
          {
            label: `Numero: ${props.item.items[i].numero} ${props.item.items[i].numero_unit}`,
          },
          {
            label: `Dosis: ${props.item.items[i].dose_size} ${props.item.items[i].dose_size_unit}`,
          },
          { label: `Frekuensi: ${props.item.items[i].dose_frequency || '-'}` },
          {
            label: `Alasan/petunjuk: ${props.item.items[i].instruction || '-'}`,
          },
          { label: `Catatan obat: ${props.item.items[i].note || '-'}` },
          { label: `Daftar Racikan: ` },
          ...listItemCompound,
        ];

        list.push(
          <EuiCollapsibleNavGroup
            title={`Racikan: ${compoundName || 'n/a'}`}
            isCollapsible={true}
            initialIsOpen={false}
            titleSize='s'
          >
            <EuiPinnableListGroup
              listItems={listItem}
              maxWidth='none'
              color='dark'
              gutterSize='none'
              size='s'
            >
              <EuiPinnableListGroup
                listItems={listItem}
                maxWidth='none'
                color='dark'
                gutterSize='none'
                size='s'
              />
            </EuiPinnableListGroup>
          </EuiCollapsibleNavGroup>
        );

        continue;
      }

      const listItem: EuiPinnableListGroupItemProps[] = [
        { label: `Nama Obat: ${props.item.items[i].medicine_name || 'n/a'}` },
        {
          label: `Numero: ${props.item.items[i].numero} ${props.item.items[i].numero_unit}`,
        },
        {
          label: `Dosis: ${props.item.items[i].dose_size} ${props.item.items[i].dose_size_unit}`,
        },
        { label: `Frekuensi: ${props.item.items[i].dose_frequency || '-'}` },
        { label: `Alasan/petunjuk: ${props.item.items[i].instruction || '-'}` },
        { label: `Catatan obat: ${props.item.items[i].note || '-'}` },
      ];
      list.push(
        <EuiCollapsibleNavGroup
          title={props.item.items[i].medicine_name || 'n/a'}
          isCollapsible={true}
          initialIsOpen={false}
          titleElement='span'
        >
          <EuiPinnableListGroup
            listItems={listItem}
            maxWidth='none'
            color='dark'
            gutterSize='none'
            size='s'
          />
        </EuiCollapsibleNavGroup>
      );
    }
    return list;
  };

  let modal;
  if (isModalVisible) {
    modal = (
      <>
        <EuiOverlayMask>
          <EuiModal onClose={closeModal} style={{ width: 600 }}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>Batalkan Resep Obat</EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
              <EuiPanel>
                <EuiFlexGroup direction='column'>
                  <EuiPanel>
                    <EuiFlexItem >
                      <EuiText>{renderItem()}</EuiText>
                    </EuiFlexItem>
                  </EuiPanel>
                  <EuiFlexItem>
                    <EuiText>
                      <p>Catatan Resep:</p>
                    </EuiText>
                    <EuiText>
                      <p>{props.item.note ? props.item.note : '-'}</p>
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiPanel>
              <br></br>
              <br></br>
              <EuiPanel>
                <EuiFlexGroup direction='column'>
                  <EuiFlexItem>
                    <EuiText>
                      <p>Alasan dibatalkan :</p>
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiTextArea
                      fullWidth
                      rows={3}
                      placeholder="Masukan alasan...."
                      value={description}
                      onChange={(e) => {
                        setdescription(e.target.value)
                      }}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiPanel>
              
            </EuiModalBody>
            <EuiModalFooter style={{ padding: '0 24px 24px !important' }}>
              <EuiButton onClick={() => updatePrescriptionRefStatus(PrescriptionStatus.Canceled)} fill>
                Simpan
              </EuiButton>
              <EuiButton onClick={closeModal}>
                Tutup
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      </>
    );
  }
  return (
    <>
      <EuiButtonEmpty
        size='xs'
        disabled={!props.item.items}
        className='btnList'
        onClick={showModal}
      >
        Resep dibatalkan
      </EuiButtonEmpty>

      {modal}
    </>
  );
};

class KimiaFarmaPharmacies extends Component {
  state = {
    page: 1,
    limit: 10,
    search: '',
    loading: true,
    orderBy: 'created_at',
    order: 'desc',
    pharmacy: 'kimiafarma',
    popOver: {},
    phoneNumberPatient: 0,
    column: [
      {
        field: 'created_at',
        name: 'Tanggal Pembuatan Resep',
        sortable: true,
      },
      {
        field: 'session_id',
        name: 'ID Sesi',
      },
      {
        field: 'pharmacy_vendor',
        name: 'Vendor',
      },
      {
        field: 'external_id',
        name: 'ID Transaksi',
      },
      {
        field: 'pharmacy_prescription_code',
        name: 'Kode Pengambilan Obat',
      },
      {
        field: 'author_name',
        name: 'Nama Dokter',
      },
      {
        field: 'expired_at',
        name: 'Masa Berlaku',
      },
      {
        field: 'patient_name',
        name: 'Nama Pasien',
      },
      {
        field: 'pharmacy_name',
        name: 'Nama Apotek',
      },
      {
        field: 'pharmacy_address',
        name: 'Lokasi Apotek',
        sortable: false,
      },
      {
        field: 'status',
        name: 'Status',
      },
      {
        field: 'description',
        name: 'Deskripsi',
      },
      {
        field: 'receipt_blob_id',
        name: 'Aksi',
        width: '8%',
        render: (prescription_receipt, item) => (
          <>
            <EuiPopover
              ownFocus={false}
              button={
                <EuiButtonIcon
                  onClick={() => this.onPopOverClick(item.id)}
                  iconType='boxesHorizontal'
                  aria-label='option'
                />
              }
              isOpen={this.state.popOver[item.id]?.isOpen}
              closePopover={() => this.onPopOverClick(item.id)}
              anchorPosition='upCenter'
              panelPaddingSize='none'
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <EuiButtonEmpty
                  size='xs'
                  className='btnList'
                  onClick={() =>
                    this.updatePrescriptionRefStatus(
                      item.id,
                      PrescriptionStatus.Done
                    )
                  }
                >
                  Resep selesai
                </EuiButtonEmpty>
                <DetailResepModal
                  item={item}
                />
                { item.status === 'Resep sedang diproses' && (
                    <EuiButtonEmpty
                      size='xs'
                      className='btnList'
                      isLoading={item.pickupLoading === true}
                      onClick={() =>
                        this.updatePrescriptionRefStatusKF(
                          item.id,
                          item.external_secondary_id,
                          'READY_FOR_PICKUP',
                        )
                      }
                    >
                      Resep siap diambil
                    </EuiButtonEmpty>
                  )
                }
                
                <EuiButtonEmpty
                  size='xs'
                  className='btnList'
                  onClick={async () => {
                    try {
                      let phoneNumberPatient =
                        await this.getPatientPhoneNumberById(item.patient_id);
                      if (phoneNumberPatient) {
                        window.open(
                          'https://wa.me/' + phoneNumberPatient,
                          '_blank'
                        );
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  Kontak WhatsApp
                </EuiButtonEmpty>
                <EuiButtonEmpty
                  size='xs'
                  className='btnList'
                  onClick={() => this.onViewPrescription(item)}
                >
                  Lihat Resep
                </EuiButtonEmpty>
                <EuiButtonEmpty
                  size='xs'
                  className='btnList'
                  onClick={() =>
                    this.props.history.push({
                      pathname: '/events',
                      state: { secondary_key: item.session_id },
                    })
                  }
                >
                  Log Events
                </EuiButtonEmpty>
                <EuiButtonEmpty
                  size='xs'
                  className='btnList'
                  onClick={() =>
                    this.props.history.push({
                      pathname: '/thirdparty-logs',
                      state: {
                        key: 'payload',
                        keyword: item.id,
                      },
                    })
                  }
                >
                  Log Bridging
                </EuiButtonEmpty>
                {item.status === 'Resep telah dibuat' && (
                  <EuiButtonEmpty
                    size='xs'
                    className='btnList'
                    disabled={item.is_event_created === true}
                    onClick={() =>
                      this.sendPrescriptionToThirdparty(item.session_id)
                    }
                  >
                    Teruskan
                  </EuiButtonEmpty>

                )}
                {item.status === 'Resep sedang diproses' &&
                  <EuiButtonEmpty
                    size='xs'
                    className='btnList'
                    isLoading={item.approvalLoading === true}
                    disabled={item.retried === true || item?.approved === true}
                    onClick={() => this.retryPrescriptionApproval(item.session_id) }
                  >
                    Ulangi Approval KF
                  </EuiButtonEmpty>
                }
                <EuiButtonEmpty
                  size='xs'
                  className='btnList'
                  disabled={!item.order_id}
                  isLoading={this.state.downloadReceipt}
                  onClick={() => this.getPrescriptionReceipt(item.session_id)}
                >
                  Unduh Kwitansi
                </EuiButtonEmpty>
                { item.order_id !== undefined &&
                  <EuiButtonEmpty
                    size='xs'
                    className='btnList'
                    disabled={item.statusInt === 3} // Disable if prescription processed
                    onClick={() => this.completePayment(item.order_id) }
                  >
                    Tandai resep sudah dibayar
                  </EuiButtonEmpty>
                }
              </div>
            </EuiPopover>
          </>
        ),
        sortable: false,
      },
    ],
    selectedTabId: 'all',
    tabs: [
      {
        id: 'all',
        name: 'Semua',
        disabled: false,
      },
      {
        id: 'CREATED',
        name: 'Resep dibuat',
        disabled: false,
      },
      {
        id: 'INVOICE',
        name: 'Invoice dibuat',
        disabled: false,
      },
      {
        id: 'PAID',
        name: 'Resep Dibayar',
        disabled: false,
      },
      {
        id: 'PROCESSED',
        name: 'Resep diproses',
        disabled: false,
      },
      {
        id: 'READY_FOR_PICKUP',
        name: 'Siap diambil',
        disabled: false,
      },
      {
        id: 'CANCELLED',
        name: 'Resep dibatalkan',
        disabled: false,
      },
      {
        id: 'FAILED',
        name: 'Resep gagal diproses',
        disabled: false,
      },
      {
        id: 'DONE',
        name: 'Resep diterima',
        disabled: false,
      },
    ],
    dateRangeOptions: [
      {
        text: 'Semua Tanggal',
        value: 'all',
      },
      {
        text: 'Tanggal Pembuatan Resep',
        value: 'created_at',
      },
      {
        text: 'Tanggal Masa Berlaku',
        value: 'expired_at',
      },
    ],
    searchOptions: [
      {
        text: 'Dokter',
        value: 'author_name',
      },
      {
        text: 'Pasien',
        value: 'patient_name',
      },
      {
        text: 'Nama Apotek',
        value: 'pharmacy_name',
      },
      {
        text: 'ID Session',
        value: 'session_id',
      },
    ],
    vendorOptions: [
      {
        text: 'Semua Vendor',
        value: '',
      },
      {
        text: 'Kimia Farma',
        value: 'kimiafarma',
      },
      {
        text: (Config.variant || '').toUpperCase(),
        value: (Config.variant || '').toLowerCase(),
      },
    ],
    searchString: this.props.location.state?.searchString || '',
    searchBy: this.props.location.state?.searchBy || 'author_name',
    searchStartDate: moment(),
    searchEndDate: moment(),
    specificDate: moment(),
    dateRange: 'all',
    data: [],
    item: {},
    searchVendor: '',
  };

  componentDidMount = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get('status');
    if (status) {
      this.setState({ selectedTabId: status });
    }
    this.page(1);
  };

  getPatientPhoneNumberById = async (uuid) => {
    try {
      let patient = await patientService.getPatient(uuid);
      if (patient.phoneNumber) {
        return patient.phoneNumber;
      }
    } catch (e) {
      console.log(e);
    }

    return '';
  };

  page = (page) => {
    page = page || 1;
    let payload = {
      page: page,
      limit: this.state.limit,
      order: this.state.order,
      orderBy: this.state.orderBy,
      pharmacy: this.state.pharmacy,
      search: this.state.searchString,
      searchBy: this.state.searchBy,
      searchDateBy: this.state.dateRange,
      searchStartDate: moment(this.state.searchStartDate).format('YYYY-MM-DD'),
      searchEndDate: moment(this.state.searchEndDate).format('YYYY-MM-DD'),
      status:
        this.state.selectedTabId === 'all' ? '' : this.state.selectedTabId,
      vendor: this.state.searchVendor,
    };

    if (this.state.dateRange === 'all') {
      console.log('DATE RANGE IS ALL');
      payload.searchDateBy = '';
      payload.searchEndDate = '';
      payload.searchStartDate = '';
    }

    console.log('sented payload ------> ', payload);

    pharmaciesService
      .getPharmacyPrescriptions(payload)
      .then((res) => {
        for (let i in res.items) {
          res.items[i].status = res.items[i].status || 0;
          res.items[i].statusInt = res.items[i].status;
          switch (res.items[i].status) {
            case 0:
              res.items[i].status = 'Resep telah dibuat';
              break;
            case 1:
              res.items[i].status = 'Invoice resep telah dibuat';
              break;
            case 2:
              res.items[i].status = 'Resep telah dibayar';
              break;
            case 3:
              res.items[i].status = 'Resep sedang diproses';
              break;
            case 4:
              res.items[i].status = 'Resep siap untuk diambil';
              break;
            case 8:
              res.items[i].status = 'Resep dibatalkan';
              break;
            case 9:
              res.items[i].status = 'Resep gagal diproses';
              break;
            case 10:
              res.items[i].status = 'Resep sudah diterima oleh pasien';
              break;
            default:
              res.items[i].status = 'Status tidak ditemukan';
              break;
          }

          res.items[i].created_at = res.items[i].created_at
            ? moment(res.items[i].created_at, 'YYYY-MM-DD HH:mm:ss').format(
                'DD MMMM YYYY HH:mm'
              )
            : '-';

          res.items[i].expired_at = res.items[i].expired_at
            ? moment(res.items[i].expired_at, 'YYYY-MM-DD HH:mm:ss').format(
                'DD MMMM YYYY HH:mm'
              )
            : '-';
        }
        console.log(res.items);
        this.setState(
          {
            page: res.page,
            limit: res.limit,
            data: res.items,
            total: res.total,
            loading: false,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };
  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  onViewPrescription = (item) => {
    this.setState({ item, isModalVisible: true }, () =>
      this.onPopOverClick(item.id)
    );
  };

  onPopOverClick = (id) => {
    let state = this.state;
    if (!state.popOver[id]) {
      state.popOver[id] = { isOpen: true };
    } else {
      state.popOver[id] = { isOpen: !state.popOver[id].isOpen };
    }
    this.setState(state);
  };

  downloadReceipt = (id) => {
    blobService
      .getBlob(id)
      .then((result) => {
        console.log(result);
        downloadjs(
          `data:${result.contentType};base64,${result.base64}`,
          `prescription_${id}.pdf`,
          `${result.contentType}`
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  sendPrescriptionToThirdparty = (id) => {
    pharmaciesService.sendPrescriptionsToThirdParty(id)
    .then((result) => {
      console.log(result);
      let data = this.state.data;
      for (let i in data ) {
        if (data[i].session_id === id) {
          console.log(data[i].session_id);
          data[i].is_event_created = true;
          break;
        } 
      }
      this.setState({data: data},
        () => {
          this.forceUpdate();
        }
      );
      swal({
        title: 'Event telah berhasil dibuat.',
        icon: 'success',
        type: 'success',
      })
    })
    .catch((err) => {
      console.log(err);
    })
  }

  retryPrescriptionApproval = (id) => {
    let data = this.state.data;
    for (let i in data ) {
      if (data[i].session_id === id) {
        data[i].approvalLoading = true;
        break;
      } 
    }
    this.setState({data: data});
    pharmaciesService.retryApprovePrescription(id)
    .then((result) => {
      console.log(result);
      for (let i in data ) {
        if (data[i].session_id === id) {
          console.log(data[i].session_id);
          data[i].approvalLoading = false;
          data[i].retried = true;
          break;
        } 
      }
      this.setState({data: data},
        () => {
          this.forceUpdate();
        }
      );
      swal({
        title: 'Approval telah berhasil dikirim ulang.',
        icon: 'success',
        type: 'success',
      }).then((value) => {
        this.page(this.state.page);
      });
    })
    .catch((err) => {
      console.log(err);
      swal({
          title: '',
          text: 'Gagal mengulang approval Resep.',
          icon: 'error',
          type: 'error',
          confirmButtonText: 'OK',
          closeOnClickOutside: true,
          closeOnEsc: true,
        });
      for (let i in data ) {
        if (data[i].session_id === id) {
          console.log(data[i].session_id);
          data[i].approvalLoading = false;
          break;
        } 
      }
    })
  }

  completePayment = (id) => {
    if (id && id.length !== 36) {
      swal({
        title: '',
        icon: 'error',
        type: 'error',
        text: 'Mohon maaf, id order invalid',
      });
    }
    paymentsService
      .completePaymentByOrderId(id)
      .then((result) => {
        swal({
          title: 'Sukses',
          icon: 'success',
          type: 'success',
          text: 'Resep telah dibayar',
        }).then((value) => {
          this.page(this.state.page);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updatePrescriptionRefStatus = (id, status) => {
    let payload = {
      id: id,
      status: status,
    };
    pharmaciesService
      .updatePrescriptionRef(payload)
      .then((result) => {
        console.log(result);
        this.forceUpdate();
        swal({
          title: 'Status berhasil diubah.',
          icon: 'success',
          type: 'success',
        });
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: '',
          text: 'Gagal mengubah status.',
          icon: 'error',
          type: 'error',
          confirmButtonText: 'OK',
          closeOnClickOutside: true,
          closeOnEsc: true,
        });
      });
  };

  updatePrescriptionRefStatusKF = (id, externalId, status) => {
    let data = this.state.data;
    let payload = {
      id: externalId,
      status: status,
    };
    data.forEach((i) => {
      if (i.id === id) {
        i.pickupLoading = true;
      } 
    })
    this.setState({ data });
    pharmaciesService
      .updatePrescriptionRefStatus(payload)
      .then((result) => {
        console.log(result);
        data.forEach((i) => {
          if (i.id === id) {
            i.pickupLoading = false;
          } 
        });
        this.setState({ data });
        this.forceUpdate();
        swal({
          title: 'Status berhasil diubah.',
          icon: 'success',
          type: 'success',
        }).then((value) => {
          this.page(this.state.page);
        });
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: '',
          text: 'Gagal mengubah status.',
          icon: 'error',
          type: 'error',
          confirmButtonText: 'OK',
          closeOnClickOutside: true,
          closeOnEsc: true,
        });
        data.forEach((i) => {
          if (i.id === id) {
            i.pickupLoading = false;
          } 
        })  
      });
  };

  onSelectedTabChanged = (id) => {
    this.setState({ loading: true, selectedTabId: id, searchDate: '' }, () => {
      this.page(1);
    });
  };

  renderTabs = () => {
    return this.state.tabs.map((tab, index) => (
      <EuiTab
        {...(tab.href && { href: tab.href, target: '_blank' })}
        onClick={() => this.onSelectedTabChanged(tab.id)}
        isSelected={tab.id === this.state.selectedTabId}
        disabled={tab.disabled}
        key={index}
      >
        {tab.name}
      </EuiTab>
    ));
  };

  onDateRangeChange = (e) => {
    let value = e.target.value || null;
    this.setState(
      { dateRange: value, searchStartDate: moment(), searchEndDate: moment() },
      () => {
        if (value === 'all') {
          this.page(1);
        }
      }
    );
  };

  handleDateChange = (date) => {
    // in moment object format
    this.setState({ searchStartDate: date });
  };

  handleDateChangeEnd = (date) => {
    // in moment object format
    this.setState({ searchEndDate: date, loading: true }, () => {
      this.page(1);
    });
  };

  onSearchByChange = (e) => {
    let value = e.target.value || null;
    this.setState({ searchBy: value, searchString: '' });
  };

  searchStringChange = debounce((value) => {
    this.setState({ searchString: value, loading: true }, () => {
      this.page(1);
    });
  }, 1000);

  onSearchStringChange = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      this.setState({ searchString: '' });
    }
    this.setState({ searchString: value });
    this.searchStringChange(value);
  };

  getPrescriptionReceipt = (id) => {
    this.setState({ downloadReceipt: true }, () => {
      pharmaciesService
        .getPrescriptionReceipt(id)
        .then((result) => {
            downloadjs(result, `prescription-receipt-${id}`, `application/pdf`);
        })
        .catch((err) => {
          console.log(err);
          // parse error message
          let message = err?.message || '';
          if (err?.response?.data?.code) {
            message = err.response.data.code;
          }

          if (err.response?.status === 404) {
            message = 'Mohon maaf, kwitansi resep ini belum terbuat';
          }

          swal({
            title: 'Gagal mengunduh kwitansi resep',
            icon: 'error',
            type: 'error',
            text: message,
          });
        })
        .finally(() => {
          this.setState({
            downloadReceipt: false,
          });
        });
    });
  };

  renderFilterComponent = () => {
    return (
      <div>
        <div style={{ display: 'inline-block' }}>
          <EuiFlexGroup>
            <EuiFlexItem grow={false} style={{ marginRight: '-10px' }}>
              <EuiSelect
                style={{ display: 'inline-block !important' }}
                placeholder='Cari berdasarkan tanggal'
                options={this.state.dateRangeOptions}
                value={this.state.dateRange}
                name='dateRange'
                onChange={this.onDateRangeChange}
                aria-label='Cari berdasarkan tanggal'
              />
            </EuiFlexItem>
            {this.state.dateRange !== 'all' && (
              <EuiFlexGroup style={{ margin: '0px' }}>
                <EuiFlexItem grow={false}>
                  <EuiDatePicker
                    selected={this.state.searchStartDate}
                    onChange={this.handleDateChange}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <div style={{ marginTop: 10 }}>sampai</div>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiDatePicker
                    selected={this.state.searchEndDate}
                    onChange={this.handleDateChangeEnd}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
          </EuiFlexGroup>
        </div>
        <div style={{ display: 'inline-block', marginLeft: 30 }}>
          <EuiFlexGroup>
            <EuiFlexItem grow={false} style={{ marginRight: '-10px' }}>
              <EuiSelect
                style={{ display: 'inline-block !important' }}
                placeholder='Vendor'
                options={this.state.vendorOptions}
                value={this.state.searchVendor}
                name='searchBy'
                onChange={(e) =>
                  this.setState(
                    {
                      searchVendor: e.target.value,
                      loading: true,
                    },
                    () => this.page(this.state.page)
                  )
                }
                aria-label='Vendor'
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false} style={{ marginRight: '-10px' }}>
              <EuiSelect
                style={{ display: 'inline-block !important' }}
                placeholder='Cari berdasarkan'
                options={this.state.searchOptions}
                value={this.state.searchBy}
                name='searchBy'
                onChange={this.onSearchByChange}
                aria-label='Cari berdasarkan'
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFieldText
                style={{ display: 'inline-block !important' }}
                placeholder='Cari...'
                name='searchString'
                onChange={this.onSearchStringChange}
                value={this.state.searchString}
                append={
                  <EuiButtonEmpty onClick={() => this.onSearchStringChange}>
                    Cari
                  </EuiButtonEmpty>
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </div>
    );
  };

  render = () => {
    const sorting = {
      sort: {
        field: this.state.orderBy,
        direction: this.state.order,
      },
    };

    return (
      <>
        <EuiPage className='class'>
          <EuiPageBody
            style={{ flexDirection: 'row' }}
            className={'content-container'}
          >
            <EuiPageContent>
              <EuiPageContentHeader>
                <EuiPageContentHeaderSection>
                  <EuiTitle>
                    <h2>Daftar Resep Obat</h2>
                  </EuiTitle>
                </EuiPageContentHeaderSection>
              </EuiPageContentHeader>
              <EuiPageContentHeader>
                <EuiTabs>{this.renderTabs()}</EuiTabs>
              </EuiPageContentHeader>
              <EuiPageContentHeader>
                {this.renderFilterComponent()}
              </EuiPageContentHeader>
              <EuiPageContentBody>
                <PrescriptionModal
                  item={this.state.item}
                  showModal={this.state.isModalVisible}
                  closeModal={() => {
                    this.setState({ isModalVisible: false });
                  }}
                />
                <EuiFlexItem>
                  <EuiPage className='class'>
                    <TablePage
                      data={this.state.data}
                      column={this.state.column}
                      disablePagination={this.state.loading}
                      page={this.state.page}
                      limit={this.state.limit}
                      total={this.state.total}
                      prev={this.prevPage}
                      next={this.nextPage}
                      toPage={this.page}
                      onItemClick={this.onItemClick}
                      loading={this.state.loading}
                      sort={sorting}
                      change={(v) => {
                        this.setState(
                          {
                            order: v.sort.direction,
                            loading: true,
                          },
                          () => this.page(this.state.page)
                        );
                      }}
                    />
                  </EuiPage>
                </EuiFlexItem>
              </EuiPageContentBody>
            </EuiPageContent>
          </EuiPageBody>
        </EuiPage>
      </>
    );
  };
}

export default KimiaFarmaPharmacies;
