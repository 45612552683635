import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';

const ErrorService = new Error();
const MiscService = new Misc();

class Pharmacies {
  // Dosage
  getPharmacyGroups = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.order || 'DESC';
      payload.search = payload.search || '';
      let url = `${Config.apiHost}/api/pharmacy-groups?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&search=${payload.search}`;
      if (payload.search && payload.search.length > 0) {
        url += `&search=${payload.search}`;
      }
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  createPharmacyGroup = (payload) => {
    console.log(payload);
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/pharmacy-group`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  updatePharmacyGroup = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/pharmacy-group/${payload.id}`;
      axios
        .put(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  deletePharmacyGroup = (id) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/pharmacy-group/${id}`;
      axios
        .delete(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  getPharmacies = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.Order || 'ASC';
      payload.search = payload.search || '';

      let url = `${Config.apiHost}/api/pharmacies?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&search=${payload.search}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getPharmacy = (uuid) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/pharmacy/${uuid}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  createPharmacy = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/pharmacy`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  updatePharmacy = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/pharmacy/${payload.id}`;
      axios
        .put(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getPharmacyPrescriptions = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.order || 'ASC';
      payload.orderBy = payload.orderBy || '';
      payload.search = payload.search || '';
      payload.searchBy = payload.searchBy || '';
      payload.filterByStatus = payload.filterByStatus || '';
      payload.searchDateBy = payload.searchDateBy || '';
      payload.searchStartDate = payload.searchStartDate || '';
      payload.searchEndDate = payload.searchEndDate || '';
      payload.status = payload.status || '';
      payload.pharmacy = payload.pharmacy || '';
      payload.sessionId = payload.sessionId || '';
      payload.vendor = payload.vendor || '';

      let url = `${Config.apiHost}/api/prescription-refs?showAll=true&page=${payload.page}&limit=${payload.limit}&order=${payload.order}&pharmacy=${payload.pharmacy}`;

      if (payload.orderBy != '') {
        url += `&orderBy=${payload.orderBy}`;
      }

      if (payload.search != '' && payload.searchBy != '') {
        url += `&search=${payload.search}&searchBy=${payload.searchBy}`;
      }

      console.log('====== service =======');
      console.log('DAteBY -----> ', payload.searchDateBy);
      console.log('searchStartDate -----> ', payload.searchStartDate);
      console.log('searchEndDate -----> ', payload.searchEndDate);

      if (
        payload.searchDateBy != '' &&
        payload.searchStartDate != '' &&
        payload.searchEndDate != ''
      ) {
        url += `&searchDateBy=${payload.searchDateBy}&searchStartDate=${payload.searchStartDate}&searchEndDate=${payload.searchEndDate}`;
      }

      if (payload.status != '') {
        url += `&status=${payload.status}`;
      }

      if (payload.sessionId != '') {
        url += `&sessionId=${payload.sessionId}`;
      }

      if (payload.vendor != '') {
        url += `&vendor=${payload.vendor}`;
      } else {
        url += '&allVendor=true';
      }

      console.log('URL PHARMACY ===========================> ', url);
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getPharmaciesV1 = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.order || 'ASC';
      payload.orderBy = payload.orderBy || 'name';
      payload.search = payload.search || '';
      payload.vendor = payload.vendor || '';

      let url = `${Config.apiHost}/api/v1/pharmacies?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&order_by=${payload.orderBy}&search=${payload.search}&vendor=${payload.vendor}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getMedicineNumeroUnits = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.order || 'ASC';
      payload.orderBy = payload.orderBy || 'name';
      payload.search = payload.search || '';
      payload.vendor = payload.vendor || '';

      let url = `${Config.apiHost}/api/medicine-numero-units?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&order_by=${payload.orderBy}&search=${payload.search}&vendor=${payload.vendor}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getMedicines = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.order || 'ASC';
      payload.orderBy = payload.orderBy || 'name';
      payload.search = payload.search || '';
      payload.vendor = payload.vendor || '';

      let url = `${Config.apiHost}/api/medicines?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&order_by=${payload.orderBy}&search=${payload.search}&vendor=${payload.vendor}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getDosages = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.order || 'ASC';
      payload.orderBy = payload.orderBy || 'dose';
      payload.search = payload.search || '';
      payload.vendor = payload.vendor || '';
      let url = `${Config.apiHost}/api/medicine-dosages?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&orderBy=${payload.orderBy}&search=${payload.search}&vendor=${payload.vendor}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getInstructions = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.order || 'ASC';
      payload.orderBy = payload.orderBy || 'name';
      payload.search = payload.search || '';
      payload.vendor = payload.vendor || '';

      let url = `${Config.apiHost}/api/medicine-instructions?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&order_by=${payload.orderBy}&search=${payload.search}&vendor=${payload.vendor}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  putPrescription = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/v1/prescription`;
      axios
        .put(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getRefPrescriptionCopy = (id, lang, forceRegenerate) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/prescription/prescription-copy/${id}?lang=${lang}`;
      if (forceRegenerate) {
        url += '&forceRegenerate=true';
      }
      axios
        .get(url, {
          headers: MiscService.generateHeaders(),
          responseType: 'arraybuffer',
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  sendPrescriptionsToThirdParty = (id) => {
    console.log(id);
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/prescription-send-thirdparty/${
        id || ''
      }`;
      axios
        .post(url, null, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  updatePrescriptionRef = (payload) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/prescription-ref`;
      axios
        .put(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  retryApprovePrescription = (id) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/retry-prescription-kf/${id || ''}`;
      axios.post(url, null, { headers: MiscService.generateHeaders() })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
        ErrorService.handle(err);
      })
    });
  };

  updatePrescriptionRefStatus = (payload) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/v1/thirdparty/prescription-status`;
      axios
        .put(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getPrescriptionReceipt = (id) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/prescription/receipt/${id}`;
      axios
        .get(url, {
          headers: MiscService.generateHeaders(),
          responseType: 'arraybuffer',
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export default Pharmacies;
