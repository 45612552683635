
import {
    EuiButtonEmpty,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
    EuiText,
    EuiPanel,
    EuiRadioGroup,
    EuiTextArea,
    EuiLoadingSpinner,
    EuiOverlayMask,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiModalFooter,
    EuiButton,
  } from '@elastic/eui';
import moment from 'moment';
import React from 'react';

import SessionService from '../../services/Sessions';
import ErrorService from '../../services/Error.js';

const errorService = new ErrorService();
const sessionsService = new SessionService();

const SessionDescriptionModal = (props) => {
    const { sessionItem, onScheduleChange } = props;
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [description, setDescription] = React.useState(sessionItem?.description);
  
    const handleSubmit = () => {
      let payload = {};
      payload.Status = sessionItem.status;
      payload.Description = description;
      payload.Id = sessionItem.id;
  
      setLoading(true);
      sessionsService
        .updateSessionStatus(payload)
        .then(() => {
          setLoading(false);
          setSuccess(true);
          closeModal();
          onScheduleChange();
        })
        .catch((err) => {
          setLoading(false);
          errorService.handle(err);
        });
    };
  
    const closeModal = (resetSuccessState) => {
      if (resetSuccessState) {
        setSuccess(false);
      }
      setIsModalVisible(false);
      setDescription(sessionItem?.description);
    };
  
    const showModal = () => setIsModalVisible(true);
  
    let oldSlotPanel = (
      <EuiPanel>
        <EuiText>
          <p>
            <b>{sessionItem?.patientName}</b>
            <br />
            <small>{sessionItem?.patientExternalId}</small>
            <br />
            <br />
            Jadwal Saat Ini
            <br />
            <small>
              <b>{sessionItem?.staffName}</b>
            </small>
            <br />
            <small>{sessionItem?.clinicName}</small>
            <br />
            <small>
              {`${moment(sessionItem?.scheduleDate).format('dddd')}`},{' '}
              {`${moment(sessionItem?.scheduleDate).format('LL')}`} pukul{' '}
              {sessionItem?.scheduleTime} <br />
            </small>
            <br />
          </p>
        </EuiText>
      </EuiPanel>
    );
  
    let form = (
      <EuiPanel>
        <EuiForm>
          <EuiFormRow label='Deskripsi Sesi*'>
            <div>
              <EuiTextArea
                placeholder='Masukan deskripsi...'
                aria-label='description'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </EuiFormRow>
        </EuiForm>
      </EuiPanel>
    );
  
    let modal;
    if (isModalVisible) {
      modal = (
        <EuiOverlayMask>
          <EuiModal onClose={closeModal} initialFocus='[name=popswitch]'>
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                Deskripsi Temu-Janji Konsultasi
              </EuiModalHeaderTitle>
            </EuiModalHeader>
  
            <EuiModalBody>
              {oldSlotPanel}
              <EuiSpacer />
              <br />
              <br />
              {loading ? (
                <p>
                  <EuiLoadingSpinner size='xl' />
                </p>
              ) : (
                form
              )}
            </EuiModalBody>
  
            {loading ? (
              ''
            ) : !success ? (
              <EuiModalFooter>
                <EuiButton onClick={handleSubmit} fill disabled={!description}>
                  Submit
                </EuiButton>
                <EuiButtonEmpty onClick={closeModal}>Batal</EuiButtonEmpty>
              </EuiModalFooter>
            ) : (
              <EuiModalFooter>
                <EuiButtonEmpty onClick={() => closeModal(true)}>
                  Batal
                </EuiButtonEmpty>
              </EuiModalFooter>
            )}
          </EuiModal>
        </EuiOverlayMask>
      );
    }
    return (
      <>
        <EuiButtonEmpty
          disabled={sessionItem.status !== 1}
          className='btnList'
          size='xs'
          onClick={showModal}
        >
          Deskripsi/Catatan Sesi
        </EuiButtonEmpty>
        {modal}
      </>
    );
  };

  export default SessionDescriptionModal