import React, { useEffect, useState } from 'react';
import { EuiPage, EuiPageBody, EuiFlexItem, EuiButtonEmpty, EuiFieldText, EuiBadge, EuiTitle, EuiPageContent, EuiPageContentHeaderSection, EuiPageContentHeader, EuiPageContentBody } from '@elastic/eui';
import TablePage from '../components/TablePage';
import SessionService from '../services/Sessions';
import { debounce } from 'lodash';

const sessionsService = new SessionService();

const OfflineConsultation = () => {
  const [data, setData] = useState();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date');
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getSessions();
  }, []);

  const column = [
    {
      field: 'createdTime',
      name: 'Tanggal Pendaftaran',
    },
    {
      field: 'clinicName',
      name: 'Klinik',
    },
    {
      field: 'staffName',
      name: 'Dokter',
    },
    {
      field: 'patientName',
      name: 'Pasien',
    },
    {
      field: 'scheduleTime',
      name: 'Awal',
    },
    {
      field: 'scheduleDate',
      name: 'Tanggal Tatap Muka',
      render: (item) => {
        return item;
      },
    },
    {
      field: 'paymentMethod',
      name: 'Metode Pembayaran',
    },
    {
      field: 'status',
      name: 'Status',
      render: (item) => {
        return (
          <EuiBadge color="#81ff81" style={{ padding: '3px 12px 3px 12px', borderRadius: '20px' }}>
            BOOKED
          </EuiBadge>
        );
      },
    },
  ];

  const getSessions = (page) => {
    setLoading(true);
    let payload = {
      page: page,
      limit: limit,
      search: search,
      order: order,
      orderBy: orderBy,
      consultationType: 1,
    };
    sessionsService
      .getSessions(payload)
      .then((s) => {
        setData(s.Items);
        setTotal(s.Total);
        console.log(s);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const nextPage = () => {
    getSessions(page + 1);
  };

  const prevPage = () => {
    getSessions(page - 1);
  };

  const handleOnSearch = (e) => {
    setLoading(true);
    setSearch(e.target.value);
    handleSearchDebounce();
  };

  const handleSearchDebounce = debounce(() => {
    getSessions(1);
  }, 2000);

  return (
    <>
      <EuiPage className="class">
        <EuiPageBody style={{ flexDirection: 'row' }} className={'content-container'}>
          <EuiPageContent>
            <EuiPageContentHeader>
              <EuiPageContentHeaderSection>
                <EuiTitle>
                  <h2>Temu-Janji Tatap Muka</h2>
                </EuiTitle>
              </EuiPageContentHeaderSection>
            </EuiPageContentHeader>
            <EuiPageContentBody>
              <TablePage
                title={''}
                searchComp={
                  <EuiFlexItem>
                    <EuiFieldText
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          handleOnSearch(search);
                        }
                      }}
                      value={search}
                      onChange={handleOnSearch}
                      placeholder="Cari ..."
                      append={<EuiButtonEmpty onClick={() => handleOnSearch(search)}>Cari</EuiButtonEmpty>}
                    />
                  </EuiFlexItem>
                }
                placeholder={'Cari'}
                column={column}
                data={data}
                loading={loading}
                page={page}
                limit={limit}
                disablePagination={loading}
                toPage={getSessions}
                prev={prevPage}
                next={nextPage}
              />
            </EuiPageContentBody>
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
    </>
  );
};

export default OfflineConsultation;
