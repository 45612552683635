import {
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButton,
  EuiFlexItem,
  EuiFlexGroup,
} from "@elastic/eui";
import moment from "moment";
import React, { useState } from "react";
import EventsService from "../../services/Events";
import ErrorService from '../../services/Error.js';

const eService = new EventsService();
const errorService = new ErrorService();
const eventState = (state) => {
  let states = {
    1: "SCHEDULED",
    2: "CANCELLED",
    3: "DONE",
    4: "IMMEDIATE",
    5: "MAX_RETRY_REACHED",
  };

  return states[state] || "-";
};

const resultState = (state) => {
  let states = {
    1: "ERROR",
    2: "SUCCESS",
    3: "DATA_CLEARED",
    4: "CHANNEL_CANCEL_OP",
  };

  return states[state] || "-";
};
const EventModal = (props) => {
  const { selectedItem, onActionStart, onClose } = props;

  const onRetryEvent = (id) => {
    onActionStart();
    eService
      .retryEvent(id)
      .then((_) => {})
      .catch((err) => {
        console.log(err);
        errorService.handle(err);
      });
    onClose();
  };

  const onCancelEvent = (id) => {
    onActionStart();
    eService
      .cancelEvent(id)
      .then((_) => {})
      .catch((err) => {
        console.log(err);
        errorService.handle(err);
      });
    onClose();
  };

  return (
    <EuiOverlayMask>
      <EuiModal onClose={onClose}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Detail Event</EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiFlexGroup>
            <EuiFlexItem>Secondary Key</EuiFlexItem>
            <EuiFlexItem>{selectedItem.secondary_key}</EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem>Code</EuiFlexItem>
            <EuiFlexItem>{selectedItem.code}</EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem>Attempt</EuiFlexItem>
            <EuiFlexItem>{selectedItem.attempt}</EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem>Scheduled Time</EuiFlexItem>
            <EuiFlexItem>
              {selectedItem.scheduled_time
                ? moment
                    .unix(selectedItem.scheduled_time)
                    .format("YYYY-MM-DD HH:mm:ss")
                : "-"}
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem>State</EuiFlexItem>
            <EuiFlexItem>{eventState(selectedItem.state)}</EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem>Attempt Time</EuiFlexItem>
            <EuiFlexItem>
              {selectedItem.attempt_time
                ? moment
                    .unix(selectedItem.attempt_time)
                    .format("YYYY-MM-DD HH:mm:ss")
                : "-"}
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem>Result State</EuiFlexItem>
            <EuiFlexItem>{resultState(selectedItem.result_state)}</EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem>Result Log</EuiFlexItem>
            <EuiFlexItem>{selectedItem.result_log || "-"}</EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButton
            fill
            color="primary"
            onClick={() => onRetryEvent(selectedItem.id)}
          >
            Ulangi Event
          </EuiButton>
          <EuiButton
            fill
            color="danger"
            onClick={() => onCancelEvent(selectedItem.id)}
          >
            Batalkan Event
          </EuiButton>
          <EuiButton onClick={onClose}>Tutup</EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  );
};

export default EventModal;
