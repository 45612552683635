import Config from '../Config';
import axios from 'axios';
import Misc from './Misc';

const MiscService = new Misc();

class Summaries {
  get = (start_date, end_date) => {
    start_date = start_date || '';
    end_date = end_date || '';

    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/v1/service-summaries?start_date=${start_date}&end_date=${end_date}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then(async (response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export default Summaries;
