import Config from "../Config";
import axios from "axios";
import Error from './Error';
import Misc from "./Misc";

const ErrorService = new Error();
const MiscService = new Misc();

class Whatsapp {
  getQrcode = () => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/viktor/verification-channel-pair-challenge`
      axios.get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(err)
          if (!(err && err.message.includes('404'))) {
            ErrorService.handle(err)
          }
        })
    });
  }
  logout = () => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/whatsapp/logout`
      axios.get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(err)
          ErrorService.handle(err)
        })
    });
  }
  logoutV2 = () => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/viktor/disconnect-channel-pair-challenge`
      axios.get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(err)
          ErrorService.handle(err)
        })
    });
  }
}

export default Whatsapp;
