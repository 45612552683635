import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';

const ErrorService = new Error();
const MiscService = new Misc();

class Sessions {
  getSessions = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 1;
      payload.consultationType = payload.consultationType || 0;
      let url = `${Config.apiHost}/api/sessions?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&consultationType=${payload.consultationType}`;

      switch (payload.orderBy) {
        case 'date':
          payload.orderBy = 'schedule';
          break;
        case 'staffName':
          payload.orderBy = 'STAFFNAME';
          break;
        case 'patientName':
          payload.orderBy = 'PATIENTNAME';
          break;
        case 'clinicName':
          payload.orderBy = 'CLINICNAME';
          break;
        default:
          payload.orderBy = 'schedule_start_utc';
          break;
      }

      if (payload.orderBy && payload.orderBy.length > 0) {
        url += `&orderby=${payload.orderBy}`;
      }
      if (payload.slotId && payload.slotId.length > 0) {
        url += `&slotId=${payload.slotId}`;
      }
      if (payload.search && payload.search.length > 0 && !payload.searchBy) {
        url += `&search=${payload.search}`;
      }
      if (payload.search && payload.search.length > 0 && payload.searchBy && payload.searchBy.length > 0) {
        url += `&search=${payload.search}`;
        url += `&searchBy=${payload.searchBy}`;
      }
      if (payload.filterBy && payload.filterBy.length > 0) {
        url += `&filterBy=${payload.filterBy}`;
      }
      if (payload.date && payload.date.length > 0) {
        url += `&date=${payload.date}`;
      }
      if (payload.hasInsuranceVendor) {
        url += `&hasInsuranceVendor=${payload.hasInsuranceVendor}`;
      }

      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getSessionById = (id) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/session/${id}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getSessionInfoById = (id) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/v2/session/info-by-id/${id}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  updateSessionStatus = (payload) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/session-update-status`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  updateSessionFinishedPlatform = (id, payload) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/session-update-finished-platform/${id}`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getSessionsByStaffId = (id, payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.order || 'ASC';
      payload.orderBy = payload.orderBy || 'schedule';
      let url = `${Config.apiHost}/api/sessions-staff/${id}?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&orderby=${payload.orderBy}`;
      if (payload.date && payload.date.length > 0) {
        url += `&date=${payload.date}`;
      }
      if (payload.search && payload.search.length > 0) {
        url += `&searchBy=patientName&search=${payload.search}`;
      }
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getSessionsByPatientId = (id, payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.order || 'ASC';
      payload.orderBy = payload.orderBy || 'schedule';
      let url = `${Config.apiHost}/api/sessions-patient/${id}?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&orderBy=${payload.orderBy}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getMedRecordBySessionId = (sessionId) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/medical-records-by-session-id/${sessionId}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getPrescriptionBySessionId = (sessionId) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/prescriptions-by-session-id/${sessionId}?allStatus=true`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getDiagnosticBySessionId = (sessionId) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/diagnostic-request/${sessionId}?allStatus=true`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getThirdpartySessionAuth = (sessionId) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/session/third-party-session-auth/${sessionId}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  updateThirdpartySessionAuth = (sessionAuth) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/session/third-party-session-auth/`;
      axios
        .put(url, sessionAuth, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  deleteSessionById = (id) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/session/${id}`;
      axios
        .delete(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getSessionReceipt = (sessionId, lang) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/session/receipt/${sessionId}/${lang}`;
      axios
        .get(url, {
          headers: MiscService.generateHeaders(),
          responseType: 'arraybuffer',
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  moveSessionToAnotherSlot = (slotId, payload) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/session-slot/move-from-session-id/${slotId}`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  updateSessionDescription = (payload) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/session-update-description`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  uploadClaimDoc = (payload) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/v1/insurance/upload-claim-doc`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  sendClaimDoc = (payload) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/v1/insurance/send-claim-doc`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getPrescriptionCopy = (sessionId, lang) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/session/prescription-copy/${sessionId}?lang=${lang}`;
      axios
        .get(url, {
          headers: MiscService.generateHeaders(),
          responseType: 'arraybuffer',
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  rerunPaymentReceivedEvents = (payload) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/run-payment-received-events/${payload.id}`;
      axios
        .get(url, {
          headers: MiscService.generateHeaders(),
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
}

export default Sessions;
