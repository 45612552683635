import React, { Component } from 'react';
import {
  EuiPage,
  EuiPageBody,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiFieldText,
  EuiModalFooter,
  EuiButton,
  EuiButtonEmpty,
  EuiFlexItem,
  EuiFlexGroup,
} from '@elastic/eui';
import moment from 'moment';
import TablePage from '../components/TablePage';
import EventsService from '../services/Events';
import { debounce } from 'lodash';

const eService = new EventsService();

class Events extends Component {
  state = {
    page: 1,
    limit: 10,
    total: 0,
    order: 'DESC',
    orderBy: 'timestamp',
    loading: false,
    isAction: false,
    data: [],
    column: [
      {
        field: 'secondary_key',
        name: 'Secondary Key',
      },
      {
        field: 'code',
        name: 'Code',
      },
      {
        field: 'state',
        name: 'State',
        render: (state) => this.eventState(state),
      },
      {
        field: 'result_state',
        name: 'Result State',
        render: (state) => this.resultState(state),
      },
      {
        field: 'result_log',
        name: 'Result Log',
      },
      {
        field: '',
        name: 'Aksi',
        render: () => (
          <div id={'action-id'}>
            <EuiButtonEmpty onClick={this.showModal} size='xs'>
              Detail
            </EuiButtonEmpty>
          </div>
        ),
      },
    ],
    search:
      (this.props.location.state && this.props.location.state.secondary_key) ||
      '',
    selectedItem: {},
  };

  eventState = (state) => {
    let states = {
      1: 'SCHEDULED',
      2: 'CANCELLED',
      3: 'DONE',
      4: 'IMMEDIATE',
      5: 'MAX_RETRY_REACHED',
    };

    return states[state] || '-';
  };

  resultState = (state) => {
    let states = {
      1: 'ERROR',
      2: 'SUCCESS',
      3: 'DATA_CLEARED',
      4: 'CHANNEL_CANCEL_OP',
    };

    return states[state] || '-';
  };

  page = (page) => {
    page = page || 1;
    this.setState({ loading: true }, () => {
      let payload = {
        page: page,
        limit: this.state.limit,
        secondary_key: this.state.search || '',
        order: this.state.order,
        orderBy: this.state.orderBy,
      };

      eService
        .list(payload)
        .then((response) => {
          this.setState(
            {
              page: response.page,
              limit: response.limit,
              total: response.total,
              order: response.order,
              orderBy: response.order_by,
              data: response.list || [],
              loading: false,
            },
            () => {
              this.forceUpdate();
            }
          );
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            loading: false,
            data: [],
          });
        });
    });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  componentDidMount = () => {
    this.page(1);
    this.setState({ isAction: true });
  };

  showModal = () => {
    this.setState({ modal: true });
  };

  onItemClick = (item) => {
    this.setState({
      selectedItem: item,
    });
  };

  handleOnSearch = (value) => {
    this.setState({
      search: value,
      loading: true,
    });
    this.handleSearchDebounce();
  };

  onRetryEvent = (id) => {
    this.setState({ loading: true });
    eService
      .retryEvent(id)
      .then((value) => {
        this.page(1);
        this.setState({
          loading: false,
          modal: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          modal: false,
        });
      });

  }

  onCancelEvent = (id) => {
    this.setState({ loading: true });
    eService
      .cancelEvent(id)
      .then((value) => {
        this.page(1);
        this.setState({
          loading: false,
          modal: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          modal: false,
        });
      });

  }


  handleSearchDebounce = debounce(() => {
    this.page(1);
  }, 1000);

  render() {
    return (
      <>
        {this.state.modal && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => {
                this.setState({ modal: false });
              }}
              initialFocus='[name=popswitch]'
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>Detail Event</EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                <EuiFlexGroup>
                  <EuiFlexItem>Secondary Key</EuiFlexItem>
                  <EuiFlexItem>
                    {this.state.selectedItem.secondary_key}
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>Code</EuiFlexItem>
                  <EuiFlexItem>{this.state.selectedItem.code}</EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>Attempt</EuiFlexItem>
                  <EuiFlexItem>{this.state.selectedItem.attempt}</EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>Scheduled Time</EuiFlexItem>
                  <EuiFlexItem>
                    {this.state.selectedItem.scheduled_time
                      ? moment
                        .unix(this.state.selectedItem.scheduled_time)
                        .format('YYYY-MM-DD HH:mm:ss')
                      : '-'}
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>State</EuiFlexItem>
                  <EuiFlexItem>
                    {this.eventState(this.state.selectedItem.state)}
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>Attempt Time</EuiFlexItem>
                  <EuiFlexItem>
                    {this.state.selectedItem.attempt_time
                      ? moment
                        .unix(this.state.selectedItem.attempt_time)
                        .format('YYYY-MM-DD HH:mm:ss')
                      : '-'}
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>Result State</EuiFlexItem>
                  <EuiFlexItem>
                    {this.resultState(this.state.selectedItem.result_state)}
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                  <EuiFlexItem>Result Log</EuiFlexItem>
                  <EuiFlexItem>
                    {this.state.selectedItem.result_log || '-'}
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiModalBody>
              <EuiModalFooter>
                <EuiButton fill color='primary' onClick={() => this.onRetryEvent(this.state.selectedItem.id)}>
                  Ulangi Event
                </EuiButton>
                <EuiButton fill color='danger' onClick={() => this.onCancelEvent(this.state.selectedItem.id)}>
                  Batalkan Event
                </EuiButton>
                <EuiButton onClick={() => this.setState({ modal: false })}>
                  Tutup
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
        <EuiPage className='class'>
          <EuiPageBody
            style={{ flexDirection: 'row' }}
            className={'content-container'}
          >
            <TablePage
              title={'Events'}
              placeholder={'Events'}
              data={this.state.data}
              column={this.state.column}
              disablePagination={this.state.loading}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={this.page}
              onItemClick={this.onItemClick}
              loading={this.state.loading}
              searchComp={
                <>
                  <EuiFlexGroup style={{ marginRight: 10 }}>
                    <EuiFlexItem>
                      <EuiFieldText
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            this.handleOnSearch(this.state.search);
                          }
                        }}
                        value={this.state.search}
                        onChange={(e) => this.handleOnSearch(e.target.value)}
                        placeholder={'Secondary key...'}
                        append={
                          <EuiButtonEmpty
                            onClick={() =>
                              this.handleOnSearch(this.state.search)
                            }
                          >
                            Cari
                          </EuiButtonEmpty>
                        }
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiButton
                        onClick={() => {
                          this.setState(
                            {
                              search: '',
                            },
                            () => this.page(1)
                          );
                        }}
                      >
                        Reset pencarian
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </>
              }
            />
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default Events;
