import swal from 'sweetalert';

class Error {

  handle(err) {
    console.log("error------------------------------", err)
    let msg = `Silakan menghubungi administrator atau tim Telekonsul.`
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 401:
          swal({
            title: '',
            type: 'warning',
            text: 'Sesi Anda telah habis. Silakan masuk kembali dengan nama pengguna dan sandi',
          })
            .then(willLogout => {
              window.localStorage.removeItem('token');
              window.localStorage.removeItem('currentUser');
              window.location = '/';
            })
          break;
        case 403:
          swal({ title: '', text: 'Anda tidak memiliki hak akses terhadap data / operasi ini.', confirmButtonText: 'OK' })
            .then(willLogout => {
              window.localStorage.removeItem('token');
              window.localStorage.removeItem('currentUser');
              window.location = '/';
            })
          break;
        case 400:
          swal({ title: '', text: 'Data yang terkirim belum lengkap/benar. Silakan diperiksa kembali.', confirmButtonText: 'OK' });
          break;
        case 404:
          swal({ title: '', text: 'Data tidak ditemukan.', confirmButtonText: 'OK' });
          break;
        case 500:
          swal({ title: '', text: `Terdapat galat pada peladen/server ${msg} ${err.response.data.message != undefined ? '\n\n\n Pesan: ' + err.response.data.message : ''}`, confirmButtonText: 'OK', html: true });
          break;
        case 502:
          swal({ title: '', text: `Peladen/server bermasalah. ${msg} ${err.response.data.message != undefined ? '\n\n\n Pesan: ' + err.response.data.message : ''}`, confirmButtonText: 'OK', html: true });
          break;
        case 503:
          swal({ title: '', text: `Peladen/server bermasalah. ${msg} ${err.response.data.message != undefined ? '\n\n\n Pesan: ' + err.response.data.message : ''}`, confirmButtonText: 'OK', html: true });
          break;
        case 504:
          swal({ title: '', text: `Peladen/server terlalu lama merespon. ${msg} ${err.response.data.message != undefined ? '\n\n\n Pesan: ' + err.response.data.message : ''}`, confirmButtonText: 'OK', html: true });
          break;
        default:
        // Do nothing. This should be handled by the requester itself.
      }
    } else {
      swal({ title: '', text: `Peladen/server tidak dapat dihubungi. Cek koneksi Anda atau ${msg}`, confirmButtonText: 'OK', html: true });
    }
  }
}

export default Error;
