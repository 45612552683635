import React, { useEffect, useState } from 'react';
import {
  EuiPage,
  EuiPageBody,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiPopover,
  EuiButtonIcon,
} from '@elastic/eui';

import Laboratory from '../services/Laboratory';
import TablePage from '../components/TablePage';
import DiagnosticEditModalComponent from '../components/DiagnosticModal';

const laboratoryService = new Laboratory();

const LaboratoryTestDetail = (props) => {
  const { uuid } = props.match.params;
  const [laboratoryItem, setLaboratoryItem] = useState(null);
  const [selectedLaboratoryItem, setSelectedLaboratoryItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPopOverOpen, setPopOver] = useState(false);

  useEffect(() => {
    const getData = async () => {
      if (!uuid || (uuid && uuid.length < 1)) {
        props.history.push('/');
        return;
      }
      setLoading(true);
      const item = await diagnosticBySessionId(uuid);

      if (item.length > 0) {
        setLaboratoryItem(item);
      }
      setLoading(false);
    };
    getData();
  }, [props.history, props.match.params, uuid]);

  const column = [
    {
      field: 'order_id',
      name: 'Kode',
    },
    {
      field: 'registration_number',
      name: 'Nomor Registrasi',
      sortable: true,
    },
    {
      field: 'created_at',
      name: 'Tanggal Dibuat',
      render: (v) => laboratoryService.renderDate(v),
    },
    {
      field: 'lab_name',
      name: 'Nama Lab',
      sortable: true,
    },
    {
      field: 'lab_expiry_date',
      name: 'Masa Berlaku',
      sortable: true,
    },
    {
      field: 'patient_name',
      name: 'Nama Pasien',
      width: '10%',
      sortable: true,
    },
    {
      field: 'preparations',
      name: 'Persiapan',
      width: '10%',
      sortable: true,
    },
    {
      field: 'name',
      name: 'Jenis Sampel',
    },
    {
      field: 'lab_address',
      name: 'Lokasi',
      width: '10%',
      sortable: true,
    },
    {
      field: 'status',
      name: 'Status',
      render: (v) => laboratoryService.renderStatus(v),
    },
    {
      field: '',
      name: 'Aksi',
      render: (v) => (
        <>
          <EuiPopover
            ownFocus={isPopOverOpen}
            button={
              <EuiButtonIcon
                onClick={() => setPopOver(!isPopOverOpen)}
                iconType='boxesHorizontal'
                aria-label='option'
              />
            }
            isOpen={isPopOverOpen}
            closePopover={() => setPopOver(!isPopOverOpen)}
            anchorPosition='upCenter'
          >
            <EuiFlexGroup
              direction='column'
              gutterSize='s'
              alignItems='flexStart'
            >
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='xs'
                  flush='left'
                  // hilangkan saat status selesai / paid
                  // disabled={v.status < 2}
                  onClick={() => {
                    
                    setIsModalVisible(true);
                    setSelectedLaboratoryItem(v);
                  }}
                >
                  Ubah Uji Lab
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='xs'
                  flush='left'
                  disabled={v.status < 2}
                  isLoading={false}
                  onClick={() => getDiagnosticReceipt(v.session_id)}
                >
                  Unduh Kwitansi
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='xs'
                  flush='left'
                  isLoading={false}
                  onClick={() =>
                    getDiagnosticReferralDoc(
                      v.session_id,
                      v.patient_current_lang
                    )
                  }
                >
                  Unduh Dokumen Referensi Lab
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='xs'
                  flush='left'
                  onClick={() =>
                    props.history.push({
                      pathname: '/events',
                      state: { secondary_key: v.session_id },
                    })
                  }
                >
                  Log Events
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='xs'
                  flush='left'
                  onClick={() =>
                    props.history.push({
                      pathname: '/thirdparty-logs',
                      state: {
                        key: 'payload',
                        keyword: v.id,
                      },
                    })
                  }
                >
                  Log Bridging
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPopover>
        </>
      ),
    },
  ];

  return (
    <EuiPage className='class'>
      <EuiPageBody
        style={{ flexDirection: 'row', textAlign: 'center', width: '100%' }}
        className={'content-container'}
      >
        {isModalVisible && (
          <DiagnosticEditModalComponent
            item={selectedLaboratoryItem}
            showModal={isModalVisible}
            closeModal={() => setIsModalVisible(false)}
          />
        )}
        <TablePage
          title={'Uji Lab'}
          backButton={true}
          isLoading={loading}
          data={laboratoryItem}
          column={column}
          disablePagination={true}
        />
      </EuiPageBody>
    </EuiPage>
  );
};

const diagnosticBySessionId = async (id) => {
  try {
    let _diag = await laboratoryService.getDiagnosticRequests({
      key: 'diagnostic_ref_key.session_id',
      value: id,
    });
    if (_diag != null && _diag.items != null && _diag.items.length > 0) {
      var data = _diag.items;

      data.forEach((element) => {
        if (element['services']?.length > 0) {
          element['name'] = element['services'][0]['name'];
          element['type'] = element['services'][0]['type'];
          element['price'] = element['services'][0]['price'];
          element['external_id'] = element['services'][0]['external_id'];
          element['notes'] = element['services'][0]['notes'];
          element['preparations'] = element['services'][0]['preparations'];
        }
        if (element['lab_expiry_date'] === '0001-01-01 00:00:00') {
          element['lab_expiry_date'] = 'No expiration date';
        }
      });

      return data;
    }
  } catch (e) {
    // skip not found error
    if (e.response.status !== 404) {
      console.log(e);
    }
  }
  return null;
};

const getDiagnosticReceipt = (sessionId) => {
  try {
    laboratoryService.getDiagnosticReceiptBySession(sessionId).then((value) => {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(
        new Blob([value], { type: 'application/pdf' })
      );
      link.download = `lab-payment-receipt-${sessionId}.pdf`;
      link.click();
    });
  } catch (e) {
    // skip not found error
    if (e.response.status !== 404) {
      console.log(e);
    }
  }
  return null;
};

const getDiagnosticReferralDoc = (sessionId, lang) => {
  try {
    laboratoryService
      .getDiagnosticReferralDoc(sessionId, lang)
      .then((value) => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(
          new Blob([value], { type: 'application/pdf' })
        );
        link.download = `lab-referral-${sessionId}.pdf`;
        link.click();
      });
  } catch (e) {
    // skip not found error
    if (e.response.status !== 404) {
      console.log(e);
    }
  }
  return null;
};
export default LaboratoryTestDetail;
