import {
  EuiButton,
  EuiButtonEmpty,
  EuiForm,
  EuiFormRow,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTextArea,
} from '@elastic/eui';
import moment from 'moment';
import React from 'react';
import ErrorService from '../../services/Error.js';
import SessionService from '../../services/Sessions';

const sessionsService = new SessionService();
const errorService = new ErrorService();

const ChangeActivateEndedSession = (props) => {
  const { sessionItem, onActivate, closePopover } = props;
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [availableClinicOrganizations, setAvailableClinicOrganizations] =
    React.useState([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [reason, setReason] = React.useState(``);

  const handleSubmit = () => {
    let payload = {};
    payload.Status = 1;
    payload.Description = reason;
    payload.Id = sessionItem.id;

    setLoading(true);
    sessionsService
      .updateSessionStatus(payload)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        closeModal();
        onActivate();
        closePopover();
      })
      .catch((err) => {
        setLoading(false);
        errorService.handle(err);
      });
  };

  const closeModal = (resetSuccessState) => {
    if (resetSuccessState) {
      setSuccess(false);
    }
    setIsModalVisible(false);
    setReason('');
  };

  const showModal = () => setIsModalVisible(true);

  let oldSlotPanel = (
    <EuiPanel>
      <EuiText>
        <p>
          <b>{sessionItem?.patientName}</b>
          <br />
          <small>{sessionItem?.patientExternalId}</small>
          <br />
          <br />
          Jadwal Saat Ini
          <br />
          <small>
            <b>{sessionItem?.staffName}</b>
          </small>
          <br />
          <small>{sessionItem?.clinicName}</small>
          <br />
          <small>
            {`${moment(sessionItem?.scheduleDate).format('dddd')}`},{' '}
            {`${moment(sessionItem?.scheduleDate).format('LL')}`} pukul{' '}
            {sessionItem?.scheduleTime} <br />
          </small>
          <br />
        </p>
      </EuiText>
    </EuiPanel>
  );

  let form = (
    <EuiPanel>
      <EuiForm>
        <EuiFormRow label='Alasan sesi diaktifkan kembali*'>
          <div>
            <EuiTextArea
              placeholder='Masukan alasan...'
              aria-label='description'
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </EuiFormRow>
      </EuiForm>
    </EuiPanel>
  );

  let modal;
  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal} initialFocus='[name=popswitch]'>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              Aktifkan Kembali Temu-janji Konsultasi
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            {oldSlotPanel}
            <EuiSpacer />
            <br />
            {loading ? (
              <p>
                <EuiLoadingSpinner size='xl' />
              </p>
            ) : (
              form
            )}
          </EuiModalBody>

          {loading ? (
            ''
          ) : !success ? (
            <EuiModalFooter>
              <EuiButton onClick={handleSubmit} fill disabled={!reason}>
                Submit
              </EuiButton>
              <EuiButtonEmpty onClick={closeModal}>Batal</EuiButtonEmpty>
            </EuiModalFooter>
          ) : (
            <EuiModalFooter>
              <EuiButtonEmpty onClick={() => closeModal(true)}>
                Batal
              </EuiButtonEmpty>
            </EuiModalFooter>
          )}
        </EuiModal>
      </EuiOverlayMask>
    );
  }
  return (
    <>
      <EuiButtonEmpty size='xs' className='btnList' onClick={showModal}>
        Aktifkan Kembali Sesi
      </EuiButtonEmpty>
      {modal}
    </>
  );
};

export default ChangeActivateEndedSession