import React, { useEffect, useState } from 'react';

import {
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiText,
  EuiModalFooter,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiComboBox,
  EuiCollapsibleNavGroup,
  EuiHorizontalRule,
  EuiTextArea,
  EuiSpacer,
  EuiTitle,
  EuiConfirmModal,
} from '@elastic/eui';

import '../styles/PrescriptionModal.css';

import LaboratoryService from '../services/Laboratory';
import { debounce } from 'debounce';
import swal from 'sweetalert';

const laboratoryService = new LaboratoryService();

const DiagnosticEditModalComponent = (props) => {
  const { item, showModal, closeModal } = props;
  const [tempItem, setTempItem] = useState(item);
  const [showLabModal, setShowLabModal] = useState(showModal);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isAddItem, setIsAddItem] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [loadingOutlets, setLoadingOutlets] = useState(false);
  const [searchServiceName, setSearchServiceName] = useState('');
  const [loadingServices, setLoadingServices] = useState(false);
  const [labServices, setLabServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [outlets, setOutlets] = useState([
    { value: '', label: 'Pilih Outlet' },
  ]);

  const loadOutlets = () => {
    let payload = {
      limit: 1000,
    };
    setLoadingOutlets(true);
    laboratoryService
      .getDiagnosticLocations(payload)
      .then((result) => {
        let outletValue = [];
        if (result.data.length > 0) {
          for (let i in result.data) {
            outletValue.push({
              value: result.data[i].id,
              label: result.data[i].name,
            });
          }
        }

        setOutlets(outletValue);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingOutlets(false);
      });
  };

  useEffect(() => {
    if (showLabModal) {
      loadOutlets();
    }
  }, [showLabModal]);

  const handleClose = () => {
    setShowLabModal(false);
    closeModal();
  };

  const handOnSearch = (name) => {
    setLoadingServices(true);
    setSearchServiceName(name);
    handleSearchDebounce();
  };

  const handleSearchDebounce = debounce(async () => {
    await getSuggestion();
  }, 1000);

  const getSuggestion = async () => {
    try {
      let payload = {};
      payload.Page = 1;
      payload.Limit = 10;
      payload.Order = 'ASC';
      payload.Search = searchServiceName;
      let result = await laboratoryService.getDiagnosticService(payload);
      let item = [];
      if (result.data) {
        for (let i in result.data) {
          item.push({
            id: result.data[i].id,
            name: result.data[i].name,
            external_id: result.data[i].external_id,
            type: result.data[i].type,
            preparations: result.data[i].preparations,
            label: result.data[i].name,
            value: result.data[i].id,
            index: i,
          });
        }
        if (loadingServices) {
          setLabServices(item);
        }
      }
    } catch (e) {
      // parse error message
      let message = e?.message || '';
      if (e?.response?.data?.code) {
        message = e.response.data.code;
      }
      swal({
        title: 'List Data Uji Lab gagal dimuat',
        text: message,
        icon: 'error',
        button: 'OK',
      });
    } finally {
      setLoadingServices(false);
    }
  };

  const onSelectService = (e) => {
    setSelectedService(e);
    setLoadingServices(false);
  };
  const onItemAdd = () => {
    if (isAddItem) {
      setTempItem({
        ...tempItem,
        services: [...(tempItem?.services || []), selectedService],
      });
      setSelectedService(null);
    } else {
      let tempServices = tempItem?.services || [];
      // update selectedService index
      tempServices[selectedService.index] = selectedService;

      setTempItem({
        ...tempItem,
        services: tempServices,
      });
      setSelectedService(null);
    }
  };

  const sendDiagnosticLab = async () => {
    try {
      setIsLoadingSubmit(true);
      let payload = tempItem;
      // preparations must be array
      if (payload.preparations !== undefined && payload.preparations !== null) {
        payload.preparations = [payload.preparations];
      }
      let result = await laboratoryService.updateDiagnosticRequest(payload);
      if (result !== '') {
        swal({
          title: 'Berhasil mengirim data Uji Lab',
          icon: 'success',
          button: 'OK',
        }).then(() => {
          // refresh the page
          window.location.reload();
        });
      }
      setIsLoadingSubmit(false);
    } catch (e) {
      setIsLoadingSubmit(false);
      // parse error message
      let message = e?.message || '';
      if (e?.response?.data?.code) {
        message = e.response.data.code;
      }
      swal({
        title: 'Gagal mengirim data Uji Lab',
        text: message,
        icon: 'error',
        button: 'OK',
      });
    }
  };

  const renderNavItem = (labItem, hideButton) => {
    console.log('renderNavItem', labItem);
    let list = (labItem.services || []).map((item, index) => {
      let title = item.name || '-';

      return (
        <div>
          <EuiCollapsibleNavGroup
            key={index}
            iconType={undefined}
            title={<>{title}</>}
            isCollapsible
            initialIsOpen={false}
            style={{ padding: '0px !important' }}
          >
            <EuiFlexGroup direction='column' gutterSize='l'>
              <EuiFlexItem>
                {renderTextField('Nama', `${item.name}`)}
                {renderTextField('External ID', `${item.external_id}`)}
                {renderTextField('Notes', item.notes || '-')}
                {renderTextField('Preparations', item.preparations || '-')}
                {item.price !== undefined &&
                  renderTextField('Price', `Rp. ${item.price}`)}

                {/* edit and delete icon */}
                {editMode && !hideButton && (
                  <EuiFlexGroup
                    justifyContent='flexStart'
                    style={{ margin: 0, marginTop: 2, padding: 0 }}
                  >
                    <EuiFlexItem grow={false}>
                      {/* Edit button */}
                      <EuiButton
                        size='s'
                        display='base'
                        iconType='pencil'
                        color='secondary'
                        onClick={async () => {
                          setSelectedService({
                            ...item,
                            label: item.name,
                            value: item.id,
                          });
                          setLabServices([
                            { value: item.id, label: item.name },
                          ]);
                        }}
                      >
                        Edit
                      </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton
                        size='s'
                        display='base'
                        iconType='trash'
                        color='danger'
                        onClick={() => {
                          let services = [...(tempItem?.services || [])];
                          services.splice(index, 1);
                          // update tempItem with new services
                          setTempItem({
                            ...tempItem,
                            services,
                          });
                        }}
                      >
                        Hapus
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                )}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiCollapsibleNavGroup>
          {index < labItem.services.length - 1 && (
            <EuiHorizontalRule margin='xs' />
          )}
        </div>
      );
    });

    // show empty message if no item
    if ((labItem.services || []).length === 0) {
      return (
        <EuiFlexGroup justifyContent='center'>
          <EuiFlexItem grow={false}>
            <EuiText textAlign='center' color='subdued'>
              <p>Belum ada uji lab</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      );
    }

    return list;
  };

  return (
    showLabModal && (
      <>
        {showConfirmationModal ? (
          <EuiOverlayMask>
            <EuiConfirmModal
              title='Konfirmasi'
              onCancel={() => setShowConfirmationModal(false)}
              onConfirm={sendDiagnosticLab}
              cancelButtonText='Batal'
              confirmButtonText='Kirim'
              buttonColor='primary'
              confirmButtonDisabled={isLoadingSubmit}
              defaultFocusedButton='confirm'
            >
              <p>Apakah anda ingin mengirim perubahan ini ?</p>
            </EuiConfirmModal>
          </EuiOverlayMask>
        ) : (
          <EuiOverlayMask>
            <EuiModal
              className='custom-modal'
              // required
              onClose={handleClose}
            >
              {/* Header */}
              {!selectedService && (
                <EuiModalHeader
                  style={{
                    paddingBottom: 0,
                  }}
                >
                  <EuiFlexGroup
                    direction='column'
                    gutterSize='s'
                    style={{ minWidth: '600px' }}
                  >
                    <EuiFlexItem>
                      <EuiText>
                        <h2>Detail Uji Lab</h2>
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFlexItem>
                        <EuiFlexGroup alignItems='center'>
                          <EuiFlexItem grow={1}>
                            <EuiText>
                              <p>Uji Lab</p>
                            </EuiText>
                          </EuiFlexItem>
                          <EuiFlexItem grow={5}>
                            <EuiText>
                              {tempItem?.lab_vendor.toUpperCase()}
                            </EuiText>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFlexItem>

                      <EuiFlexGroup alignItems='center'>
                        <EuiFlexItem grow={1}>
                          <EuiText>
                            <p>Outlet</p>
                          </EuiText>
                        </EuiFlexItem>
                        {!loadingOutlets && (
                          <EuiFlexItem grow={5}>
                            <EuiComboBox
                              fullWidth
                              compressed
                              isClearable={true}
                              loading={loadingOutlets.toString()}
                              placeholder='Pilih Outlet'
                              options={outlets}
                              onChange={(e) => {
                                if (e.length > 0) {
                                  setTempItem({
                                    ...tempItem,
                                    lab_id: e[0].value ?? '',
                                    lab_name: e[0].label ?? '',
                                  });
                                } else {
                                  setTempItem({
                                    ...tempItem,
                                    lab_id: '',
                                    lab_name: '',
                                  });
                                }
                              }}
                              value={tempItem?.lab_id}
                              aria-label='Uji Lab'
                              singleSelection={{ asPlainText: true }}
                              selectedOptions={getSelectedOption(
                                outlets,
                                tempItem?.lab_id
                              )}
                            />
                          </EuiFlexItem>
                        )}
                      </EuiFlexGroup>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiModalHeader>
              )}

              {/* Body */}
              <EuiModalBody>
                <EuiFlexGroup direction='column' gutterSize='s'>
                  <EuiFlexItem>
                    {!selectedService && <EuiHorizontalRule margin='s' />}
                    {selectedService ? (
                      <RenderFormInput
                        isAddItem={isAddItem}
                        selectedService={selectedService}
                        loadingServices={loadingServices}
                        labServices={labServices}
                        handOnSearch={handOnSearch}
                        onChange={setSelectedService}
                        setLabServices={onSelectService}
                      />
                    ) : (
                      renderNavItem(tempItem)
                    )}
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiModalBody>

              {/* Footer */}
              <EuiModalFooter style={{ paddingTop: 0 }}>
                <EuiFlexGroup direction='column'>
                  <EuiFlexItem>
                    <EuiFlexGroup direction='column' gutterSize='xs'>
                      <EuiHorizontalRule margin='s' />
                      <EuiFlexItem>
                        <EuiText>
                          <strong>Catatan Lab:</strong>
                        </EuiText>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        {editMode ? (
                          <EuiTextArea
                            fullWidth
                            rows={3}
                            placeholder='Catatan Uji Lab'
                            value={tempItem?.notes}
                            onChange={(e) => {
                              setTempItem({
                                ...tempItem,
                                notes: e.target.value,
                              });
                            }}
                          />
                        ) : (
                          <EuiText>
                            <p>{tempItem?.notes || '-'}</p>
                          </EuiText>
                        )}
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    {selectedService ? (
                      <EuiFlexGroup justifyContent='spaceBetween'>
                        <EuiFlexItem grow={false}>
                          <EuiButton
                            fill
                            color={isAddItem ? 'primary' : 'secondary'}
                            // Action on item add
                            onClick={onItemAdd}
                          >
                            {isAddItem ? 'Tambah Uji Lab' : 'Edit Uji Lab'}
                          </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                          <EuiButton
                            color='danger'
                            onClick={() => {
                              setSelectedService(null);
                              setIsAddItem(false);
                            }}
                          >
                            {'Batal'}
                          </EuiButton>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    ) : (
                      <EuiFlexGroup justifyContent='spaceBetween'>
                        <EuiFlexGroup
                          justifyContent='flexStart'
                          alignItems='center'
                        >
                          <EuiFlexItem grow={false}>
                            <EuiButton
                              fill
                              color={editMode ? 'primary' : 'secondary'}
                              isLoading={isLoadingSubmit}
                              onClick={() => {
                                if (editMode) {
                                  // submit form
                                  setShowConfirmationModal(true);
                                } else {
                                  setEditMode(true);
                                }
                              }}
                            >
                              {editMode
                                ? 'Simpan dan Ubah Uji Lab'
                                : 'Ubah Uji Lab'}
                            </EuiButton>
                          </EuiFlexItem>
                          {editMode && (
                            <EuiFlexItem grow={false}>
                              <EuiButton
                                color='primary'
                                fill
                                onClick={() => {
                                  setIsAddItem(true);
                                  setSelectedService({});
                                }}
                              >
                                Tambah Uji Lab
                              </EuiButton>
                            </EuiFlexItem>
                          )}
                        </EuiFlexGroup>
                        <EuiFlexItem grow={false}>
                          <EuiButton
                            color={'danger'}
                            onClick={() => {
                              if (editMode) {
                                setEditMode(false);
                                setTempItem(item);
                                setSelectedService(null);
                              } else {
                                handleClose();
                              }
                            }}
                          >
                            {editMode ? 'Batal' : 'Tutup'}
                          </EuiButton>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    )}
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
      </>
    )
  );
};

const renderTextField = (label, value) => {
  return (
    <EuiFlexGroup alignItems='center'>
      <EuiFlexItem grow={2}>
        <EuiText>
          <p>{label}</p>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiText>
          <p>:</p>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={5}>
        <EuiText>
          <p>{value}</p>
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

const RenderFormInput = (props) => {
  const {
    isAddItem,
    selectedService,
    loadingServices,
    labServices,
    handOnSearch,
    onChange,
  } = props;

  // Assign default value for edit mode
  return (
    <EuiFlexGroup direction='column' gutterSize='m'>
      <EuiSpacer size='m' />

      {/* Input Lab */}
      <EuiFlexItem>
        <EuiFlexGroup justifyContent='spaceBetween'>
          <EuiFlexItem>
            <EuiTitle size='xs'>
              <h3>{isAddItem ? 'Tambah Layanan' : 'Edit Layanan'}</h3>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiFlexItem>
        <EuiFlexGroup alignItems='center'>
          <EuiFlexItem grow={1}>
            <EuiText>
              <p>Nama Uji Lab</p>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={5}>
            <EuiComboBox
              fullWidth
              isClearable
              async
              isLoading={loadingServices}
              onSearchChange={handOnSearch}
              placeholder='Silahkan ketik nama layanan'
              options={labServices}
              onChange={(e) => {
                onChange({
                  ...e[0],
                  index: selectedService?.index || 0,
                });
                console.log('tempSelectedService: ', selectedService);
              }}
              selectedOptions={getSelectedOption(
                labServices,
                selectedService?.id || ''
              )}
              value={selectedService?.id || ''}
              aria-label='Lab'
              singleSelection={{ asPlainText: true }}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

const getSelectedOption = (items, id) => {
  let selected = items.find((x) => x.value === id);

  selected = selected ? [selected] : [];

  return selected;
};

export default DiagnosticEditModalComponent;
