import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';
import camelCase from 'camelcase-keys-deep';

const ErrorService = new Error();
const MiscService = new Misc();

class Clinics {
  getClinics = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.Order || 'DESC';
      payload.search = payload.search || '';
      let url = `${Config.apiHost}/api/clinics?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&search=${payload.search}`;
      if (payload.search && payload.search.length > 0) {
        url += `&search=${payload.search}`;
      }
      if (payload.organizationId && payload.organizationId.length > 0) {
        url += `&organizationId=${payload.organizationId}`;
      }
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          let data = camelCase(response.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  create = (payload) => {
    console.log(payload);
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/clinics`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  update = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/clinic/${payload.id}`;
      axios
        .put(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  delete = (id) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/clinic/${id}`;
      axios
        .delete(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  import = (payload) => {
    const { organizationIds, file } = payload;
    const ids = (organizationIds || []).map((v) => v.value).join(',');
    const url = `${Config.apiHost}/api/clinic/import?organization_ids=${ids}`;
    const formData = new FormData();
    formData.append('organization_ids', ids);
    formData.append('file', file[0]);
    return new Promise((resolve, reject) => {
      axios
        .post(url, formData, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  uploadIconFile = (file) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/blob/upload?bucketName=clinic`;
      var formData = new FormData();
      formData.append('file', file);
      axios
        .post(url, formData, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  getFile = (blobId, ignoreError = false) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/blob/${blobId}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          if (!ignoreError) {
            ErrorService.handle(err);
          }
        });
    });
  };
}

export default Clinics;
