import React, { Component } from 'react';
import { debounce } from 'lodash';
import {
  EuiPage,
  EuiPageBody,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiForm,
  EuiFormRow,
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiConfirmModal,
  EuiSelect,
  EuiFlexItem,
  EuiTextArea,
} from '@elastic/eui';
import TablePage from '../components/TablePage';
import AppSetting from '../services/AppSettings';
import Utils from '../Utils';
import ErrorService from '../services/Error';
import swal from 'sweetalert';

const AppSettingsService = new AppSetting();
const errorService = new ErrorService();

class AppSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      limit: 10,
      order: 'ASC',
      search: '',
      loading: true,
      orderBy: 'key',
      data: [],
      column: [
        {
          field: 'key',
          name: 'Kunci',
        },
        {
          field: 'value',
          name: 'Nilai',
        },
        {
          field: 'visibilityName',
          name: 'Visibilitas',
        },
      ],

      // Form
      id: '',
      key: '',
      value: '',
      visibility: 0,
      visibilityName: '',
      isNewItem: false,
      visibilities: [
        {
          value: 0,
          text: 'Pilih visibilitas',
        },
        {
          value: 1,
          text: 'Publik',
        },
        {
          value: 2,
          text: 'Privat',
        },
      ],
      validationFields: {
        key: {
          type: 'string',
          isInvalid: false,
          errors: ['Tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        value: {
          type: 'string',
          isInvalid: false,
          errors: ['Tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
      },
    };
  }

  componentDidMount = () => {
    const search = this.props.location.search;
    const searchIndex = search.search('q=');
    if (searchIndex !== -1) {
      const searchQuery = search.slice(searchIndex + 2);
      this.handleOnSearch(searchQuery);
    }
    this.page(1);
  };

  add = () => {
    this.prepareNewItem();
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      isNewItem: false,
      key: '',
      value: '',
      visibility: false,
    });
  };

  page = (page) => {
    page = page || 1;
    this.setState({ loading: true });
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };
    AppSettingsService.getAppSettings(payload)
      .then((result) => {
        console.log(result);
        for (let i in result.items) {
          if (result.items[i].visibility === 1) {
            result.items[i].visibilityName = 'Publik';
          } else if (result.items[i].visibility === 2) {
            result.items[i].visibilityName = 'Privat';
          } else {
            result.items[i].visibilityName = '-';
          }
        }
        console.log(result);
        this.setState(
          {
            page: result.page,
            limit: result.limit,
            total: result.total,
            order: result.order,
            orderBy: result.orderBy,
            data: result.items,
            loading: false,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          data: [],
        });
      });
  };

  handleOnSearch = (value) => {
    this.setState({ search: value, loading: true });
    this.handleSearchDebounce();
  };

  handleSearchDebounce = debounce(() => {
    this.page(1);
  }, 2000);

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  show = (item) => {
    this.setState({ key: item.key, key: item.key, showModal: true });
  };

  prepareNewItem = () => {
    this.setState({
      id: null,
      key: '',
      value: '',
      visibility: 0,
      showModal: true,
      isNewItem: true,
    });
  };

  handleChange = (e) => {
    let value = e.target.value || null;
    let obj = {};

    obj[e.target.name] = value;
    obj['failedAttempt'] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };

  save = async () => {
    let state = { ...this.state };
    this.setState({ saveLoading: true }, () => {
      Utils.validateFields(state).then(async (result) => {
        this.setState({ validationFields: result.validationFields });
        this.forceUpdate();
        if (!result.isValid) {
          console.log(result);
          return;
        }

        if (!(this.state.id && this.state.id.length > 0)) {
          try {
            let value = await AppSettingsService.getAppSetting(this.state.key);
            console.log(value);
            if (value && value.data && value.data.length > 0) {
              return alert('Key ini sudah digunakan. Silakan gunakan key lain/baru.');
            }
          } catch (err) {
            // Ignore
            console.log(err);
          }
        }

        let payload = {
          id:
            (this.state.id && this.state.id.length) > 0 ? this.state.id : null,
          key: this.state.key,
          value: this.state.value,
          visibility: parseInt(this.state.visibility),
        };
        console.log(payload);
        AppSettingsService.createAndUpdate(payload)
          .then((result) => {
            this.setState({ saveLoading: false, loading: true });
            this.closeModal();
            this.page(1);
          })
          .catch((err) => {
            this.setState({ saveLoading: false });
            errorService.handle(err);
          });
      });
    });
  };

  delete = () => {
    if (!(this.state.key && this.state.key.length > 0)) {
      return;
    }
    if (!window.confirm('Apakah Anda yakin ingin menghapus item ini?')) {
      return;
    }
    AppSettingsService.delete(this.state.key)
      .then((result) => {
        this.setState({ saveLoading: false, loading: true });
        this.closeModal();
        this.page(1);
      })
      .catch((err) => {
        this.setState({ saveLoading: false });
        errorService.handle(err);
      });
  };
  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      key: '',
      value: '',
    });
  };

  onItemClick = (item) => {
    this.setState({
      id: item.id,
      key: item.key,
      value: item.value,
      visibility: item.visibility,
      showModal: true,
    });
  };

  onVisibilityChange = (e) => {
    let value = e.target.value || null;
    let name = '';
    for (let i in this.state.visibilities) {
      if (this.state.visibilities[i].value === value) {
        name =
          this.state.visibilities[i].name || this.state.visibilities[i].text;
      }
    }
    this.setState({ visibility: value, visibilityName: name });
  };

  validateJson = () => {
    try {
      JSON.parse(this.state.value);
      return true;
    } catch (e) {
      return false;
    }
  };

  render() {
    return (
      <>
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: 'row' }}
            className={'content-container'}
          >
            <TablePage
              title={'Pengaturan Aplikasi'}
              placeholder={'Kata kunci'}
              searchComp={
                <EuiFlexItem>
                  <EuiFieldText
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        this.search(this.state.search);
                      }
                    }}
                    value={this.state.search}
                    onChange={(e) => this.handleOnSearch(e.target.value)}
                    placeholder={this.props.placeholder}
                    append={
                      <EuiButtonEmpty
                        onClick={() => this.search(this.state.search)}
                      >
                        Cari
                      </EuiButtonEmpty>
                    }
                  />
                </EuiFlexItem>
              }
              buttonLabel={'Tambah Baru'}
              buttonFunc={() => this.add()}
              data={this.state.data}
              column={this.state.column}
              location={this.props.location}
              disablePagination={this.state.loading}
              loading={this.state.loading}
              page={this.state.page}
              limit={this.state.limit}
              total={this.state.total}
              prev={this.prevPage}
              next={this.nextPage}
              toPage={this.page}
              onItemClick={this.onItemClick}
            />
          </EuiPageBody>
        </EuiPage>
        {this.state.showDeleteModal ? (
          <EuiOverlayMask>
            <EuiConfirmModal
              title="Hapus"
              onCancel={this.closeDeleteModal}
              onConfirm={this.closeDeleteModal}
              cancelButtonText="Batal"
              confirmButtonText="Hapus"
              buttonColor="danger"
              defaultFocusedButton="confirm"
            >
              <p>Apakah anda ingin menghapus item ini ?</p>
            </EuiConfirmModal>
          </EuiOverlayMask>
        ) : null}

        {this.state.showModal && (
          <EuiOverlayMask>
            <EuiModal
              style={{ width: 600 }}
              onClose={() => this.closeModal()}
              initialFocus="[name=popswitch]"
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>Pengaturan aplikasi</EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                <EuiForm>
                  <div style={{ marginTop: 15 }}>
                    <EuiFormRow
                      label="Kunci"
                      fullWidth
                      isInvalid={this.state.validationFields['key'].isInvalid}
                      error={
                        this.state.validationFields['key'].isInvalid &&
                        this.state.validationFields['key'].errors
                      }
                    >
                      <EuiFieldText
                        fullWidth
                        placeholder="Kunci"
                        value={this.state.key}
                        name="key"
                        onChange={this.handleChange}
                        aria-label="key"
                      />
                    </EuiFormRow>
                    <EuiFormRow
                      label="Nilai"
                      fullWidth
                      isInvalid={this.state.validationFields['value'].isInvalid}
                      error={this.state.validationFields['value'].isInvalid}
                    >
                      <EuiTextArea
                        fullWidth
                        placeholder="Nilai"
                        value={this.state.value}
                        name="value"
                        onChange={this.handleChange}
                        aria-label="value"
                      />
                    </EuiFormRow>
                    <EuiFormRow label="Visiblitas" fullWidth>
                      <EuiSelect
                        placeholder="Visibilitas"
                        fullWidth
                        options={this.state.visibilities}
                        value={this.state.visibility}
                        name="visibility"
                        onChange={this.onVisibilityChange}
                        aria-label="Visibilitas"
                      />
                    </EuiFormRow>
                  </div>
                </EuiForm>
              </EuiModalBody>

              <EuiModalFooter>
                <EuiButtonEmpty onClick={() => this.closeModal()}>
                  Batal
                </EuiButtonEmpty>
                {this.state.value && this.state.value.includes('{') && (
                  <EuiButton
                    color={'secondary'}
                    onClick={() => {
                      let isJson = this.validateJson();
                      if (isJson) {
                        swal({
                          title: '',
                          icon: 'success',
                          text: 'JSON Valid',
                        });
                      } else {
                        swal({
                          title: '',
                          icon: 'error',
                          text: 'JSON Tidak Valid',
                        });
                      }
                    }}
                    fill
                  >
                    Validasi JSON
                  </EuiButton>
                )}
                {this.state.key && !this.state.isNewItem && (
                  <EuiButton
                    color={'danger'}
                    onClick={() => this.delete()}
                    fill
                  >
                    Hapus
                  </EuiButton>
                )}
                <EuiButton onClick={() => this.save()} fill>
                  Simpan
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
      </>
    );
  }
}

export default AppSettings;
