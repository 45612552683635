import React, {  Fragment, Component } from 'react';

import {
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiPageContentBody,
  EuiTitle,
  EuiFieldText,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiPage,
  EuiPageBody,
  EuiFormRow,
  EuiSpacer,
  EuiTab,
  EuiTabs,
  EuiSelect,
  EuiFieldNumber,
  EuiOverlayMask,
  EuiConfirmModal,
  EuiButtonIcon,
  EuiButtonToggle,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiListGroup,
  EuiListGroupItem,
  EuiModalFooter,
  EuiBasicTable,
  EuiButtonEmpty,
  EuiDatePicker,
  EuiFieldPassword,
  EuiCheckbox,
  EuiComboBox,
  EuiCodeBlock,
  EuiSuperSelect,
  EuiText,
} from '@elastic/eui';
import Table from '../components/Table';
import Patients from '../services/Patients';
import Errors from '../services/Error';
import Utils from '../Utils';
import OrganizationService from '../services/Organizations';
import moment from 'moment';
import Logs from '../services/Logs';
import Sessions from '../services/Sessions';
import TablePage from '../components/TablePage';
import printJs from 'print-js';
import swal from 'sweetalert';
import { Calendar } from 'react-modern-calendar-datepicker';
import Datepicker from '../components/Datepicker';
import Recordings from '../services/Recordings';
import downloadjs from 'downloadjs';
import Blobs from '../services/Blob';
import Config from '../Config';
import SettingServices from '../services/Settings';
import RegionCodes from '../services/RegionCodes';
import JSONPretty from 'react-json-pretty';
const NusantaraValid = require('nusantara-valid');
const organizationService = new OrganizationService();
const patientService = new Patients();
const errorService = new Errors();
const logService = new Logs();
const sessionService = new Sessions();
const recordingService = new Recordings();
const blobService = new Blobs();
const settingService = new SettingServices();
const patientUpdateRequiresMR = Config.patientUpdateRequiresMR;
const patientUpdateRequiresOrganization =
  Config.patientUpdateRequiresOrganization;
const regionCodesService = new RegionCodes();

class PatientDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewthirdPartyModal: false,
      thirdPartyTitle: '',
      thirdPartyAccount: {},
      selectedTabId: 'datadiri',
      data: [],
      column: [],
      medicalRecords: [],
      medicalRecordPage: 0,
      medicalRecordSize: 5,
      patientUpdateChangeRelatedSessions: false,
      isPhoneNumberChanged: false,
      medicalRecordColumn: [
        {
          field: 'staff_name',
          name: 'Perekam',
        },
        {
          field: 'created_time',
          name: 'Tanggal direkam',
          render: (name, item) => (
            <span>
              {item.created_time && item.created_time > 0
                ? new Date(item.created_time * 1000).toLocaleString()
                : ''}
            </span>
          ),
        },
        {
          field: 'notes',
          name: 'Catatan',
        },
      ],
      id: '',
      name: '',
      dob: moment(),
      email: '',
      externalId: '',
      referral: '',
      nik: '',
      originalnik: '',
      organizationId: '',
      organizationName: '',
      phoneNumber: '',
      address: '',
      page: 1,
      limit: 10,
      order: 'DESC',
      orderBy: 'schedule',
      sessionsData: [],
      submitLoading: false,
      submitPreferredLoading: false,
      deleteLoading: false,
      deleteConfirmModal: false,
      viewRawDataModal: false,
      recordingKey: '',
      addressName: '',
      rt: '',
      rw: '',
      selectedProvince: '',
      selectedCity: '',
      selectedDistrict: '',
      selectedSubDistrict: '',

      current_selected_province: '',
      current_selected_city: '',
      current_selected_district: '',
      current_selected_sub_district: '',
      current_selected_preferred_pharmacy: '',
      current_selected_preferred_diagnostic_lab: '',

      postalCode: '',
      parent: '',
      province: [],
      city: [],
      district: [],
      subDistrict: [],

      current_province: [],
      current_city: [],
      current_district: [],
      current_sub_district: [],
      current_preferred_diagnostic_lab: [],
      current_preferred_pharmacy: [],

      selectedProvinceName: '',
      selectedCityName: '',
      selectedDistrictName: '',
      selectedSubDistrictName: '',

      current_selected_province_name: '',
      current_selected_city_name: '',
      current_selected_district_name: '',
      current_selected_sub_district_name: '',
      current_selected_preferred_pharmacy_name: '',
      current_selected_preferred_diagnostic_lab_name: '',

      preferred_pharmacy_valid: true,
      preferred_diagnostic_lab_valid: true,

      sub_district: [],

      rawData: '',
      validationFields: {
        externalId: {
          type: 'string',
          isInvalid: false,
          errors: [''],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        organizationId: {
          type: 'string',
          isInvalid: false,
          errors: ['Tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        name: {
          type: 'string',
          isInvalid: false,
          errors: ['Tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        email: {
          type: 'string',
          isInvalid: false,
          errors: ['Tidak boleh kosong'],
          isValidFunc: function (value) {
            return true; // allowed to be empty
          },
        },
        phoneNumber: {
          type: 'string',
          isInvalid: false,
          errors: ['Tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        selectedGender: {
          type: 'string',
          isInvalid: false,
          errors: ['Tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0 && value !== '0';
          },
        },
        nik: {
          type: 'string',
          isInvalid: false,
          errors: [
            'NIK tidak valid. Tulis NIK yang valid (16-18 digit) atau kosongkan sama sekali.',
          ],
          isValidFunc: function (value) {
            value = value || '';
            let validLength =
              value.length === 0 || (value.length >= 16 && value.length <= 18);
            let validValue =
              value.length === 0 || (value.length >= 16 && value.length <= 18);
            return validLength && validValue;
          },
        },
        addressName: {
          type: 'string',
          isInvalid: false,
          errors: ['Nama jalan tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        rt: {
          type: 'string',
          isInvalid: false,
          errors: ['RT tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        rw: {
          type: 'string',
          isInvalid: false,
          errors: ['RW tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        selectedProvince: {
          type: 'string',
          isInvalid: false,
          errors: ['Provinsi tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        selectedCity: {
          type: 'string',
          isInvalid: false,
          errors: ['Kota tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        selectedDistrict: {
          type: 'string',
          isInvalid: false,
          errors: ['Kecamatan tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        selectedSubDistrict: {
          type: 'string',
          isInvalid: false,
          errors: ['Keluarahan tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
        postalCode: {
          type: 'string',
          isInvalid: false,
          errors: ['Kode pos tidak boleh kosong'],
          isValidFunc: function (value) {
            return value && value.length > 0;
          },
        },
      },
      sessionsColumn: [
        {
          field: 'clinic_name',
          name: 'Klinik',
        },
        {
          field: 'name',
          name: 'Nama staff/dokter',
        },
        {
          field: 'external_id',
          name: 'Nomor Pendaftaran',
        },
        {
          field: 'schedule',
          name: 'Tanggal',
        },
        {
          field: 'schedule_time',
          name: 'Pukul',
        },
        {
          field: 'slot_number',
          name: 'Urutan',
        },
        {
          field: 'room_id',
          name: 'ID ruangan',
        },
        {
          field: 'id',
          name: 'Log Sesi',
          render: (id) => (
            <EuiButtonToggle
              label='Tampilkan Log'
              iconType='eye'
              onChange={() => {
                this.loadSessionLogsBySession(id);
              }}
              isEmpty
              isIconOnly
            />
          ),
        },
        {
          field: 'id',
          name: 'Isoman',
          render: (id) => (
            <EuiButtonToggle
              label='Setel Login'
              iconType='popout'
              onChange={() => {
                this.showthirdPartyModal(id);
              }}
              isEmpty
              isIconOnly
            />
          ),
        },
        {
          field: 'id',
          name: 'Tautan',
          render: (id, sessionItem) => (
            <>
              {!moment(sessionItem.scheduleDate).isBefore(
                moment().format('YYYY-MM-DD')
              ) &&
              Config.weblink !== '' &&
              sessionItem.status === 1 ? (
                <a href={'/weblink-list/' + id}>SiapDok link</a>
              ) : null}
            </>
          ),
        },
      ],
      gender: [
        {
          value: '0',
          text: 'Silakan pilih jenis kelamin',
        },
        {
          value: '1',
          text: 'Pria',
        },
        {
          value: '2',
          text: 'Wanita',
        },
      ],
      selectedGender: '0',
      tags: [],
      disablePharmacy: false,
    };

    if (patientUpdateRequiresMR === false) {
      delete this.state.validationFields['externalId'];
    }
    if (patientUpdateRequiresOrganization === false) {
      delete this.state.validationFields['organizationId'];
    }
  }

  handleViewthirdPartyModal = () =>
    this.setState({
      viewthirdPartyModal: !this.state.viewthirdPartyModal,
    });

  handlethirdPartyAccount = (e) => {
    console.log('Target ', e.target.name);
    let value = e.target.value ?? '';
    if (e.target.name === 'username') {
      this.setState((prevState) => ({
        thirdPartyAccount: {
          ...prevState.thirdPartyAccount,
          third_party_username: value,
        },
      }));
    } else if (e.target.name == 'password') {
      this.setState((prevState) => ({
        thirdPartyAccount: {
          ...prevState.thirdPartyAccount,
          third_party_password: value,
        },
      }));
    }
    console.log('Update account ', this.state.thirdPartyAccount);
  };

  thirdPartyStartDate = (date) => {
    console.log(date);
    this.setState((prevState) => ({
      thirdPartyAccount: {
        ...prevState.thirdPartyAccount,
        start_time: date,
      },
    }));
    console.log(this.state.thirdPartyAccount);
  };

  thirdPartyEndDate = (date) => {
    console.log(date);
    this.setState((prevState) => ({
      thirdPartyAccount: {
        ...prevState.thirdPartyAccount,
        end_time: date,
      },
    }));
    console.log(this.state.thirdPartyAccount);
  };

  loadProvinceRegionCodes = () => {
    return new Promise((resolve, reject) => {
      let payload = {
        limit: 1000,
        order: 'ASC',
      };
      regionCodesService
        .getRegionCodes(payload)
        .then((response) => {
          console.log(response);
          let items = [];
          let selected = [];
          response.data.forEach((item) => {
            items.push({ value: item.code, text: item.name, label: item.name });
            if (item.code === this.state.selectedProvince) {
              selected.push({
                value: item.code,
                text: item.name,
                label: item.name,
              });
            }
          });
          console.log(items);
          this.setState(
            {
              province: items,
              selectedOptionsProvince: selected,
            },
            () => {
              resolve();
            }
          );
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  loadCityRegionCodes = () => {
    return new Promise((resolve, reject) => {
      let payload = {
        limit: 1000,
        order: 'ASC',
        parent: this.state.selectedProvince,
      };
      regionCodesService
        .getRegionCodes(payload)
        .then((response) => {
          console.log(response);
          let items = [];
          let selected = [];
          response.data.forEach((item) => {
            items.push({ value: item.code, text: item.name, label: item.name });
            if (item.code === this.state.selectedCity) {
              selected.push({
                value: item.code,
                text: item.name,
                label: item.name,
              });
            }
          });
          this.setState(
            {
              city: items,
              selectedOptionsCity: selected,
            },
            () => {
              resolve();
            }
          );
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  loadDistrictRegionCodes = () => {
    return new Promise((resolve, reject) => {
      let payload = {
        limit: 1000,
        order: 'ASC',
        parent: this.state.selectedCity,
      };
      regionCodesService
        .getRegionCodes(payload)
        .then((response) => {
          console.log(response);
          let items = [];
          let selected = [];
          response.data.forEach((item) => {
            items.push({ value: item.code, text: item.name, label: item.name });
            if (item.code === this.state.selectedDistrict) {
              selected.push({
                value: item.code,
                text: item.name,
                label: item.name,
              });
            }
          });
          console.log(items);
          this.setState(
            {
              district: items,
              selectedOptionsDistrict: selected,
            },
            () => {
              resolve();
            }
          );
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  loadSubDistrictRegionCodes = () => {
    return new Promise((resolve, reject) => {
      let payload = {
        limit: 1000,
        order: 'ASC',
        parent: this.state.selectedDistrict,
      };
      regionCodesService
        .getRegionCodes(payload)
        .then((response) => {
          console.log(response);
          let items = [];
          let selected = [];
          if (response.data !== undefined) {
            response.data.forEach((item) => {
              items.push({
                value: item.code,
                text: item.name,
                label: item.name,
              });
              if (item.code === this.state.selectedSubDistrict) {
                selected.push({
                  value: item.code,
                  text: item.name,
                  label: item.name,
                });
              }
            });
          }

          console.log(items);
          this.setState(
            {
              subDistrict: items,
              selectedOptionsSubDistrict: selected,
            },
            () => {
              resolve();
            }
          );
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  loadCurrentProvinceRegionCodes = () => {
    return new Promise((resolve, reject) => {
      let payload = {
        limit: 1000,
        order: 'ASC',
      };
      regionCodesService
        .getRegionCodes(payload)
        .then((response) => {
          let items = [];
          let selected = [];
          response.data.forEach((item) => {
            items.push({ value: item.code, text: item.name, label: item.name });
            if (item.code === this.state.current_selected_province) {
              selected.push({
                value: item.code,
                text: item.name,
                label: item.name,
              });
            }
          });
          console.log(items);
          this.setState(
            {
              current_province: items,
              current_selected_options_province: selected,
            },
            () => {
              resolve();
            }
          );
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  loadCurrentCityRegionCodes = () => {
    return new Promise((resolve, reject) => {
      let payload = {
        limit: 1000,
        order: 'ASC',
        parent: this.state.current_selected_province,
      };
      regionCodesService
        .getRegionCodes(payload)
        .then((response) => {
          console.log(response);
          let items = [];
          let selected = [];
          response.data.forEach((item) => {
            items.push({ value: item.code, text: item.name, label: item.name });
            if (item.code === this.state.current_selected_city) {
              selected.push({
                value: item.code,
                text: item.name,
                label: item.name,
              });
            }
          });
          this.setState(
            {
              current_city: items,
              current_selected_options_city: selected,
            },
            () => {
              resolve();
            }
          );
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  loadCurrentDistrictRegionCodes = () => {
    return new Promise((resolve, reject) => {
      let payload = {
        limit: 1000,
        order: 'ASC',
        parent: this.state.current_selected_city,
      };
      regionCodesService
        .getRegionCodes(payload)
        .then((response) => {
          console.log(response);
          let items = [];
          let selected = [];
          response.data.forEach((item) => {
            items.push({ value: item.code, text: item.name, label: item.name });
            if (item.code === this.state.current_selected_district) {
              selected.push({
                value: item.code,
                text: item.name,
                label: item.name,
              });
            }
          });
          console.log(items);
          this.setState(
            {
              current_district: items,
              current_selected_options_district: selected,
            },
            () => {
              resolve();
            }
          );
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  loadCurrentSubDistrictRegionCodes = () => {
    return new Promise((resolve, reject) => {
      let payload = {
        limit: 1000,
        order: 'ASC',
        parent: this.state.current_selected_district,
      };
      regionCodesService
        .getRegionCodes(payload)
        .then((response) => {
          console.log(response);
          let items = [];
          let selected = [];
          response.data.forEach((item) => {
            items.push({ value: item.code, text: item.name, label: item.name });
            if (item.code === this.state.current_selected_sub_district) {
              selected.push({
                value: item.code,
                text: item.name,
                label: item.name,
              });
            }
          });
          console.log(items);
          this.sub_district = items;
          this.setState(
            {
              current_sub_district: items,
              current_selected_options_sub_district: selected,
            },
            () => {
              resolve();
            }
          );
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  loadCurrentPreferredPharmacyCodes = () => {
    return new Promise((resolve, reject) => {
      let payload = this.state.current_selected_preferred_pharmacy;
      regionCodesService
        .getPharmacyCodes(payload)
        .then((response) => {
          console.log('DATA FARMASI =>> ', response);
          let items = [];
          let selected = [];
          items.push({
            value: response.id,
            inputDisplay: (
              <Fragment>
                <EuiText size='s'>
                  <p>{response.name}</p>
                </EuiText>
                <EuiText  size='xs' color="subdued">
                  <p>No Telp: {response.phone_number ?? '-'}</p>
                </EuiText>
              </Fragment>
            ),
          });
          this.setState(
            {
              current_preferred_pharmacy: items,
              current_selected_options_preferred_pharmacy: selected,
            },
            () => {
              resolve();
            }
          );
        })
        .catch((err) => {
          console.log(err);
          if (err && err.message && err.message.indexOf('404') > -1) {
            // Do nothing, let it load
          } else {
            reject(err);
          }
        });
    });
  };

  loadPreferredPharmacySuggestion = (id) => {
    return new Promise((resolve, reject) => {
      regionCodesService
        .getPharmacy(id)
        .then((response) => {
          let items = [...this.state.current_preferred_pharmacy];
          let selected = [];
          if (response.pharmacy_locations) {
            response.pharmacy_locations.forEach((item) => {
              if (item.vendor === 'kimiafarma') {
                let index = items.length;
                items.push({
                  value: item.id,
                  inputDisplay: (
                    <Fragment>
                      <EuiText size='s'>
                        <p>KF Terdekat {index}: {item.name}</p>
                      </EuiText>
                      <EuiText  size='xs' color="subdued">
                        <p>No Telp: {item.phone_number ?? '-'}</p>
                      </EuiText>
                    </Fragment>
                  ),
                });
              }
            });
          }
          this.setState({
            current_preferred_pharmacy: items,
            current_selected_options_preferred_pharmacy: selected,
            preferred_pharmacy_valid: false,
          });
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  loadCurrentPreferredPharmacy = () => {
    return new Promise((resolve, reject) => {
      let payload = this.state.current_selected_sub_district;
      regionCodesService
        .getPharmacy(payload)
        .then((response) => {
          let pharmacyItems = [];
          let labItems = [];
          let selected = [];
          if (
            response.pharmacy_locations &&
            response.pharmacy_locations.length > 0 &&
            response.pharmacy_locations[0].vendor !== 'kimiafarma'
          ) {
            this.sub_district.forEach((item) => {
              this.loadPreferredPharmacySuggestion(item.value);
            });
          }
          if (response.pharmacy_locations) {
            response.pharmacy_locations.forEach((item) => {
              pharmacyItems.push({
                value: item.id,
                inputDisplay: (
                  <Fragment>
                    <EuiText size='s'>
                      <p>{item.name}</p>
                    </EuiText>
                    <EuiText  size='xs' color="subdued">
                      <p>No Telp: {item.phone_number ?? '-'}</p>
                    </EuiText>
                  </Fragment>
                ),
              });
            });
            this.setState(
              {
                current_preferred_pharmacy: pharmacyItems,
                current_selected_options_preferred_pharmacy: selected,
                preferred_pharmacy_valid: false,
              },
              () => {
                resolve();
              }
            );
          }
          if (response.diagnostic_locations) {
            response.diagnostic_locations.forEach((item) => {
              labItems.push({
                value: item.id,
                text: item.name,
                label: item.name,
              });
            });
            this.setState(
              {
                current_preferred_diagnostic_lab: labItems,
                current_selected_options_diagnostic_lab: selected,
                preferred_diagnostic_lab_valid: false,
              },
              () => {
                resolve();
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  loadCurrentPreferredDiagnosticLabCodes = () => {
    return new Promise((resolve, reject) => {
      let payload = this.state.current_selected_preferred_diagnostic_lab;
      regionCodesService
        .getDiagnosticLabCodes(payload)
        .then((response) => {
          let items = [];
          let selected = [];
          items.push({
            value: response.name,
            text: response.name,
            label: response.name,
          });
          selected.push({
            value: response.name,
            text: response.name,
            label: response.name,
          });
          this.setState(
            {
              current_preferred_diagnostic_lab: items,
              current_selected_options_preferred_diagnostic_lab: selected,
            },
            () => {
              resolve();
            }
          );
        })
        .catch((err) => {
          console.log(err);
          if (err && err.message && err.message.indexOf('404') > -1) {
            // Do nothing, let it load
          } else {
            reject(err);
          }
        });
    });
  };

  closethirdPartyModal = () =>
    this.setState({
      thirdPartyModal: <></>,
    });

  updateSessionAuth = () => {
    this.handleViewthirdPartyModal();
    this.setState((prevState) => ({
      thirdPartyAccount: {
        ...prevState.thirdPartyAccount,
        start_time: this.state.thirdPartyAccount.start_time.format,
        end_time: this.state.thirdPartyAccount.end_time.format,
      },
    }));
    sessionService
      .updateThirdpartySessionAuth(this.state.thirdPartyAccount)
      .then((res) => {
        this.setState({
          thirdPartyAccount: {},
        });
        swal({
          title: 'Berhasil',
          type: 'success',
          text: `${this.state.thirdPartyTitle} telah dibuat`,
        });
      });
  };

  showthirdPartyModal = (sessionId) => {
    this.setState((prevState) => ({
      thirdPartyAccount: {
        id: sessionId,
      },
    }));
    sessionService
      .getThirdpartySessionAuth(sessionId)
      .then((result) => {
        console.log('thirdParty Account', result);
        this.handleViewthirdPartyModal();
        if (result != null && result.id != '') {
          result.start_time = moment(
            moment.utc(result.start_time).format('YYYY-MM-DD')
          );
          result.end_time = moment(
            moment.utc(result.end_time).format('YYYY-MM-DD')
          );
          this.setState({
            thirdPartyAccount: result,
          });
        }
      })
      .catch(() => {
        this.handleViewthirdPartyModal();
        console.log('No account');
      });
    console.log('thirdParty Account', this.state.thirdPartyAccount);
  };

  componentDidMount() {
    let uuid = this.props.match.params.uuid;
    if (!uuid || (uuid && uuid.length < 1)) {
      this.props.history.push('/');
      return;
    }
    settingService
      .getAppSettings('disable-pharmacy')
      .then((res) => {
        this.setState({
          disablePharmacy: res.data === "true",
        });
      })
      .catch((err) => {});
    settingService
      .getAppSettings('thirdparty-session-auth-title')
      .then((res) => {
        this.setState({
          thirdPartyTitle: res.data,
        });
      })
      .catch((err) => {});
    patientService
      .getPatient(uuid)
      .then((result) => {
        result.dob = moment.utc(result.dob).format('DD-MM-YYYY');
        let address = result.address.split(',');

        // incase address has multiple comma
        // append to 0 index address except the last one since it was value of RT/RW
        for (let i = 1; i < address.length - 1; i++) {
          address[0] += ',' + address[i];
        }

        // keep old address patient format
        let rt = address[address.length - 1]?.split('/')[0]?.split('.')[1];
        let rw = address[address.length - 1]?.split('/')[1]?.split('.')[1];

        if (!rt) {
          rt = address[address.length - 1]?.split('RT ')[1]?.split('RW ')[0];
        }
        if (!rw) {
          rw = address[address.length - 1]?.split('RW ')[1];
        }

        let tags = [];
        for (let i = 0; i < (result.tags || []).length; i++) {
          tags.push({
            label: result.tags[i],
          });
        }

        this.setState({
          email: result.email,
          externalId: result.externalId,
          referral: result.referral,
          id: result.id,
          name: result.name,
          nik: String(result.nik),
          originalnik: String(result.nik),
          organizationId: result.organizationId,
          phoneNumber: result.phoneNumber,
          address: result.address,
          dob: moment.utc(result.dob, 'DD-MM-YYYY'),
          selectedGender: result.gender.toString(),
          addressName: address[0],
          rt,
          rw,

          addressLat: result.address_lat,
          addressLong: result.address_long,

          selectedProvince: result.province,
          selectedCity: result.city,
          selectedDistrict: result.district,
          selectedSubDistrict: result.subDistrict,

          current_selected_province: result.current_province,
          current_selected_city: result.current_city,
          current_selected_district: result.current_district,
          current_selected_sub_district: result.current_sub_district,

          current_provinceCode: result.current_province,
          current_selected_preferred_pharmacy: result.preferred_pharmacy,
          current_selected_preferred_diagnostic_lab:
            result.preferred_diagnostic_lab,

          postalCode: result.postalCode,
          rawData: result.rawData,
          tags,
        });
        this.page(1);
        this.loadProvinceRegionCodes();
        this.loadCityRegionCodes();
        this.loadDistrictRegionCodes();
        this.loadSubDistrictRegionCodes();

        this.loadCurrentProvinceRegionCodes();
        this.loadCurrentCityRegionCodes();
        this.loadCurrentDistrictRegionCodes();
        this.loadCurrentSubDistrictRegionCodes();

        this.loadCurrentPreferredPharmacyCodes();
        this.loadCurrentPreferredDiagnosticLabCodes();

        return patientService.getPatientMedicalRecords({
          patientId: this.state.id,
        });
      })
      .then((result) => {
        if (result.medical_records && result.medical_records.length > 0) {
          this.setState({
            medicalRecordTotal: result.total,
            medicalRecords: result.medical_records,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });

    if (Config.environment === 'staging') {
      this.state.sessionsColumn.push({
        field: 'id',
        render: (id) => (
          <EuiButtonToggle
            label='Hapus sesi'
            iconType='trash'
            onChange={() => {
              this.deleteSession(id);
            }}
            isEmpty
            isIconOnly
          />
        ),
      });
    }

    this.loadOrganizations();
    const data = [];
    const column = [
      {
        field: 'name',
        name: 'name',
      },
      {
        field: 'email',
        name: 'email',
      },
      {
        field: 'location',
        name: 'location',
      },
      {
        field: 'account',
        name: 'account',
      },
      {
        field: 'date',
        name: 'date',
      },
      {
        field: 'amount',
        name: 'amount',
      },
      {
        field: 'phone',
        name: 'phone',
      },
      {
        field: 'version',
        name: 'version',
      },
    ];
    for (let index = 0; index < 50; index++) {
      data.push({
        id: String(index),
        name: String(index) + 'name',
        email: String(index) + 'email',
        location: String(index) + 'location',
        account: String(index) + 'account',
        date: String(index) + 'date',
        amount: String(index) + 'amount',
        phone: String(index) + 'phone',
        version: String(index) + 'version',
      });
    }

    this.setState({ data, column });
  }

  onSelectedTabChanged = (id) => {
    this.setState({ selectedTabId: id });
    if (id === 'pertemuan') {
      this.page(1);
    }
  };
  onGenderChange = (e) => {
    let value = e.target.value || null;
    let name = '';
    let obj = {};
    for (let i in this.state.gender) {
      if (this.state.gender[i].value === value) {
        name = this.state.gender[i].name || this.state.gender[i].text;
      }
    }
    this.setState({
      selectedGender: value,
    });
    obj[e.target.name] = value;
    obj['failedAttempt'] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };
  loadOrganizations = () => {
    let payload = {
      limit: 1000,
    };
    organizationService
      .getOrganizations(payload)
      .then((result) => {
        let options = [];
        options.push({ value: '', text: 'Pilih organisasi...' });
        for (let i in result.items) {
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
            label: result.items[i].name,
          });
        }
        this.setState({ organizations: options });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          organizations: [],
        });
      });
  };

  save = () => {
    console.log(this.state);
    let state = { ...this.state };
    this.setState({ submitLoading: true }, () => {
      Utils.validateFields(state).then((result) => {
        this.setState({ validationFields: result.validationFields });
        this.forceUpdate();
        if (!result.isValid) {
          console.log(result);
          swal({
            title: 'Kesalahan',
            icon: 'error',
            text: `Silakan lengkapi terlebih dahulu kolom isian yang harus diisi di tab Data Diri atau Referensi Apotek/Lab`,
            button: 'OK',
          });
          this.setState({ submitLoading: false, selectedTabId: 'datadiri' });
          return;
        }
        let payload = {
          id: this.state.id,
          name: this.state.name,
          organizationId: this.state.organizationId,
          email: this.state.email,
          phoneNumber: Utils.reformatPhoneNumber(this.state.phoneNumber),
          dob: this.state.dob,
          externalId: this.state.externalId,
          nik: this.state.nik,
          gender: parseInt(this.state.selectedGender, 10),
          patientUpdateChangeRelatedSessions:
            this.state.patientUpdateChangeRelatedSessions,
          address: `${this.state.addressName},RT.${this.state.rt}/RW.${this.state.rw}`,
          province: this.state.selectedProvince,
          city: this.state.selectedCity,
          district: this.state.selectedDistrict,
          subDistrict: this.state.selectedSubDistrict,

          current_province: this.state.current_selected_province,
          current_city: this.state.current_selected_city,
          current_district: this.state.current_selected_district,
          current_sub_district: this.state.current_selected_sub_district,
          preferred_pharmacy: this.state.current_selected_preferred_pharmacy,
          preferred_diagnostic_lab:
            this.state.current_selected_preferred_diagnostic_lab,

          postalCode: this.state.postalCode,

          address_lat: this.state.addressLat,
          address_long: this.state.addressLong,
          rt: this.state.rt,
          rw: this.state.rw,
          tags: (this.state.tags || []).map((item) => item.label),
        };
        console.log(payload);
        // Update existing
        patientService
          .update(payload)
          .then((result) => {
            console.log('hasil save', result);
            this.setState({ submitLoading: false });
            window.history.back();
          })
          .catch((err) => {
            this.setState({ submitLoading: false });
            errorService.handle(err);
          });
      });
    });
  };

  savePreferredLabAndPharmacy = () => {
    this.setState({ submitPreferredLoading: true }, () => {
      let payload = {
        id: this.state.id,
        pharmacy_id: this.state.current_selected_preferred_pharmacy,
        lab_id: this.state.current_selected_preferred_diagnostic_lab,
        province: this.state.current_selected_province,
        city: this.state.current_selected_city,
        district: this.state.current_selected_district,
        sub_district: this.state.current_selected_sub_district,
      };
      console.log(payload);
      patientService
        .updatePatientPreferredLabAndPharmacy(payload)
        .then((result) => {
          console.log(result);
          swal({
            icon: 'success',
            title: 'Berhasil',
            text: 'Pilihan farmasi dan lab pasien diubah',
            confirmButtonText: 'OK',
          });
          this.setState({ submitPreferredLoading: false });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ submitPreferredLoading: false });
          errorService.handle(err);
        });
    });
  };

  delete = () => {
    if (!(this.state.id && this.state.id.length > 0)) {
      return;
    }
    this.setState({ deleteLoading: true });
    patientService
      .delete(this.state.id)
      .then((result) => {
        this.setState({ deleteLoading: false });
        window.history.back();
      })
      .catch((err) => {
        console.log(err.response);
        this.setState({ deleteLoading: false });
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message === 'patient-is-in-homecare'
        ) {
          swal({
            icon: 'error',
            title: 'Pasien ini masih terdaftar di HomeCare.',
            text: 'Silakan checkout pasien terlebih dahulu.',
            confirmButtonText: 'OK',
          });
        } else {
          errorService.handle(err);
        }
      });
  };

  deleteSession = (id) => {
    swal({
      text: 'Apakah anda yakin untuk menghapus sesi ini ?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({ loading: true });
        sessionService
          .deleteSessionById(id)
          .then((result) => {
            swal('Sesi telah berhasil dihapus', {
              icon: 'success',
            });
            this.page(1);
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
            errorService.handle(err);
          });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  page = (page) => {
    page = page || 1;
    let payload = {
      page: page,
      limit: this.state.limit,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };
    this.setState({ loading: true });
    sessionService
      .getSessionsByPatientId(this.state.id, payload)
      .then((result) => {
        for (let i in result.sessions) {
          result.sessions[i].schedule = moment
            .unix(result.sessions[i].schedule)
            .format('DD-MM-YYYY');
          result.sessions[i].schedule_time = moment
            .unix(result.sessions[i].schedule_time)
            .utc()
            .format('HH:mm:ss');
        }
        this.setState(
          {
            page: result.page,
            limit: result.limit,
            total: result.total,
            order: result.order,
            orderBy: result.orderBy,
            sessionsData: result.sessions,
            loading: false,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        let obj = {
          sessionsData: [],
          loading: false,
        };
        this.setState(obj);
      });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  printLogs = (payload) => {
    let items = [];
    for (let i in payload) {
      let item = {
        logs: `${payload[i].timestamp} - ${payload[i].userType} ${payload[i].type}`,
      };
      items.push(item);
    }
    printJs({
      printable: items,
      properties: [{ field: 'logs', displayName: 'List logs' }],
      type: 'json',
    });
  };
  parseJSONString = (json) => {
    let parsed = JSON.parse(json);
    return parsed;
  };
  loadSessionLogsBySession = (id) => {
    logService
      .getLogsBySessionId(id)
      .then((result) => {
        for (let i in result.data) {
          result.data[i].timestamp = moment(result.data[i].timestamp).format(
            'YYYY-MM-DD HH:mm'
          );
          switch (result.data[i].type) {
            case 'HEARTBEAT':
              result.data[i].type = 'sedang di dalam sesi';
              break;
            case 'OUT':
              result.data[i].type = 'telah keluar dari sesi';
              break;
            case 'SESSION_STARTED':
              result.data[i].type = 'Sesi telah dimulai';
              break;
            case 'SESSION_ENDED':
              result.data[i].type = 'Sesi telah berakhir';
              break;
            default:
              break;
          }
          switch (result.data[i].userType) {
            case 'PATIENT':
              result.data[i].userType = 'Pasien';
              break;
            case 'DOCTOR':
              result.data[i].userType = 'Dokter';
              break;
            case 'ASSISTANT':
              result.data[i].userType = 'Asisten';
              break;
            default:
              break;
          }
        }
        recordingService
          .getRecordingBySessionID(id)
          .then((result) => {
            console.log(result);
            this.setState({
              recordingKey: result.recordingKey,
              sessionId: result.sessionId,
            });
          })
          .catch((err) => {
            console.log(err);
          });
        this.setState({
          showModal: true,
          logsData: result.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  downloadRecoreding = (id) => {
    blobService
      .getBlob(id)
      .then((result) => {
        downloadjs(
          `data:video/mp4;base64,${result}`,
          `recording-${this.state.sessionId}`,
          'video/mp4'
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      logsData: [],
    });
  };

  renderTabs = () => {
    const tabs = [
      {
        id: 'datadiri',
        name: 'Data Diri',
        disabled: false,
      },
      {
        id: 'datamedis',
        name: 'Data Medis',
        disabled: false,
      },
      {
        id: 'pertemuan',
        name: 'Pertemuan',
        disabled: false,
      },
      {
        id: 'tagihan',
        name: 'Tagihan',
        disabled: false,
      },
      {
        id: 'lab',
        name: 'Referensi Apotek/Lab',
        disabled: false,
      },
    ];
    return tabs.map((tab, index) => (
      <EuiTab
        {...(tab.href && { href: tab.href, target: '_blank' })}
        onClick={() => this.onSelectedTabChanged(tab.id)}
        isSelected={tab.id === this.state.selectedTabId}
        disabled={tab.disabled}
        key={index}
      >
        {tab.name}
      </EuiTab>
    ));
  };

  dateChange = (date) => {
    let parsedDate = date.day + '-' + date.month + '-' + date.year;
    this.setState({
      dob: moment.utc(parsedDate, 'DD-MM-YYYY'),
    });
    console.log(this.state.dob);
  };

  handleChange = (e) => {
    let value = e.target.value || null;
    let obj = {};
    if (e.target.name === 'phoneNumber' && value !== null) {
      value = value.replace(/[^0-9]/g, '');
      this.setState({
        patientUpdateChangeRelatedSessions: true,
        isPhoneNumberChanged: true,
      });
    }
    if (e.target.name === 'name' && value !== null) {
      value = value.replace(/[^a-zA-Z ]+/g, '');
    }
    obj[e.target.name] = value;
    obj['failedAttempt'] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };

  onOrganizationChange = (e) => {
    let value = e.target.value || null;
    let name = '';
    for (let i in this.state.organizations) {
      if (this.state.organizations[i].value === value) {
        name =
          this.state.organizations[i].name || this.state.organizations[i].text;
      }
    }
    this.setState({ organizationId: value, organizationName: name });
  };

  onProvinceChange = (e) => {
    this.setState({
      selectedOptionsProvince: e,
    });
    if (e.length > 0) {
      this.setState(
        {
          selectedProvince: e[0].value,
          selectedOptionsProvince: e,
          selectedProvinceName: e[0].text,
          selectedCity: '',
          selectedOptionsCity: [],
          selectedDistrict: '',
          selectedOptionsDistrict: [],
          selectedSubDistrict: '',
          selectedOptionsSubDistrict: [],
        },
        () => {
          this.loadCityRegionCodes();
        }
      );
    }
  };

  onCityChange = (e) => {
    this.setState({
      selectedOptionsCity: e,
    });
    if (e.length > 0) {
      this.setState(
        {
          selectedCity: e[0].value,
          selectedOptionsCity: e,
          selectedCityName: e[0].text,
        },
        () => {
          this.loadDistrictRegionCodes();
        }
      );
    }
  };

  onDistrictChange = (e) => {
    this.setState({
      selectedOptionsDistrict: e,
    });
    if (e.length > 0) {
      this.setState(
        {
          selectedDistrict: e[0].value,
          selectedOptionsDistrict: e,
          selectedDistrictName: e[0].text,
        },
        () => {
          this.loadSubDistrictRegionCodes();
        }
      );
    }
  };

  onSubDistrictChange = (e) => {
    this.setState({
      selectedOptionsSubDistrict: e,
    });
    if (e.length > 0) {
      this.setState({
        selectedSubDistrict: e[0].value,
        selectedOptionsSubDistrict: e,
        selectedSubDistrictName: e[0].text,
      });
    }
  };

  onCurrentProvinceChange = (e) => {
    this.setState({
      current_selected_options_province: e,
    });
    if (e.length > 0) {
      this.setState(
        {
          current_selected_province: e[0].value,
          current_selected_options_province: e,
          current_selected_province_name: e[0].text,
          current_selected_city: '',
          current_selected_options_city: [],
          current_selected_district: '',
          current_selected_options_district: [],
          current_selected_sub_district: '',
          current_selected_options_sub_district: [],
          current_selected_preferred_pharmacy: '',
          current_selected_options_preferred_pharmacy: [],
          current_selected_preferred_diagnostic_lab: '',
          current_selected_options_preferred_diagnostic_lab: [],
        },
        () => {
          this.loadCurrentCityRegionCodes();
        }
      );
    }
  };

  onCurrentCityChange = (e) => {
    this.setState({
      current_selected_options_city: e,
    });
    if (e.length > 0) {
      this.setState(
        {
          current_selected_city: e[0].value,
          current_selected_options_city: e,
          current_selected_city_name: e[0].text,
          current_selected_district: '',
          current_selected_options_district: [],
          current_selected_sub_district: '',
          current_selected_options_sub_district: [],
          current_selected_preferred_pharmacy: '',
          current_selected_options_preferred_pharmacy: [],
          current_selected_preferred_diagnostic_lab: '',
          current_selected_options_preferred_diagnostic_lab: [],
        },
        () => {
          this.loadCurrentDistrictRegionCodes();
        }
      );
    }
  };

  onCurrentDistrictChange = (e) => {
    this.setState({
      current_selected_options_district: e,
    });
    if (e.length > 0) {
      this.setState(
        {
          current_selected_district: e[0].value,
          current_selected_options_district: e,
          current_selected_district_name: e[0].text,
          current_selected_sub_district: '',
          current_selected_options_sub_district: [],
          current_selected_preferred_pharmacy: '',
          current_selected_options_preferred_pharmacy: [],
          current_selected_preferred_diagnostic_lab: '',
          current_selected_options_preferred_diagnostic_lab: [],
        },
        () => {
          this.loadCurrentSubDistrictRegionCodes();
        }
      );
    }
  };

  onCurrentSubDistrictChange = (e) => {
    this.setState({
      current_selected_options_sub_district: e,
    });
    if (e.length > 0) {
      this.setState(
        {
          current_selected_sub_district: e[0].value,
          current_selected_options_sub_district: e,
          current_selected_sub_district_name: e[0].text,
          current_selected_preferred_pharmacy: '',
          current_selected_options_preferred_pharmacy: [],
          current_selected_preferred_diagnostic_lab: '',
          current_selected_options_preferred_diagnostic_lab: [],
        },
        () => {
          this.loadCurrentPreferredPharmacy();
        }
      );
    }
  };

  onCurrentPreferredPharmacyChange = (e) => {
    this.setState({
      current_selected_preferred_pharmacy: e,
    })
  };

  onCurrentPreferredDiagnosticLabChange = (e) => {
    if (e === 0) {
      this.setState({
        current_selected_preferred_diagnostic_lab: '',
      });
    }
    this.setState({
      current_selected_options_preferred_diagnostic_lab: e,
    });
    if (e.length > 0) {
      this.setState({
        current_selected_preferred_diagnostic_lab: e[0].value,
        current_selected_options_preferred_diagnostic_lab: e,
        current_selected_preferred_diagnostic_lab_name: e[0].text,
      });
    }
  };

  loadMedicalRecords = (page) => {
    patientService
      .getPatientMedicalRecords({ patientId: this.state.id, page: page + 1 })
      .then((result) => {
        this.setState({
          medicalRecordTotal: result.total,
          medicalRecords: result.medical_records,
          medicalRecordPage: page,
        });
      })
      .catch((err) => {});
  };

  renderTabsContent = () => {
    const dobMoment = moment(this.state.dob);
    switch (this.state.selectedTabId) {
      case 'datadiri':
        return (
          <EuiPageContentBody>
            <EuiForm className='form-layout'>
              <div style={{ marginTop: 15 }}>
                <EuiFormRow
                  label='Nama'
                  isInvalid={this.state.validationFields['name'].isInvalid}
                  error={
                    this.state.validationFields['name'].isInvalid &&
                    this.state.validationFields['name'].errors
                  }
                >
                  <EuiFieldText
                    placeholder='Nama'
                    value={this.state.name}
                    name='name'
                    onChange={this.handleChange}
                    aria-label='name'
                  />
                </EuiFormRow>
                {patientUpdateRequiresMR !== false && (
                  <EuiFormRow
                    label='Nomor Rekam Medis'
                    isInvalid={
                      this.state.validationFields['externalId'].isInvalid
                    }
                    error={this.state.validationFields['externalId'].isInvalid}
                  >
                    <EuiFieldText
                      placeholder='Nomor Rekam Medis'
                      value={this.state.externalId}
                      name='externalId'
                      onChange={this.handleChange}
                      aria-label='externalId'
                    />
                  </EuiFormRow>
                )}

                <EuiFormRow label='Referral'>
                  <EuiFieldText
                    placeholder='Referral'
                    value={this.state.referral}
                    name='referral'
                    onChange={this.handleChange}
                    aria-label='referral'
                  />
                </EuiFormRow>
                <EuiFormRow label='Tags'>
                  <EuiComboBox
                    placeholder='Tag'
                    options={[]}
                    label={'Tags'}
                    isClearable={true}
                    selectedOptions={this.state.tags || []}
                    name='Tag'
                    onChange={(tags) => this.setState({ tags })}
                    onCreateOption={(val) => {
                      console.log(val);
                      let tags = this.state.tags || [];
                      tags.push({ label: val });
                      this.setState({ tags: tags });
                    }}
                    aria-label='Tag'
                  />
                </EuiFormRow>

                <EuiFormRow
                  label='Surel'
                  isInvalid={this.state.validationFields['email'].isInvalid}
                  error={this.state.validationFields['email'].isInvalid}
                >
                  <EuiFieldText
                    placeholder='Surel'
                    value={this.state.email}
                    name='email'
                    onChange={this.handleChange}
                    aria-label='email'
                  />
                </EuiFormRow>

                <EuiFormRow
                  label='Nomor Telepon'
                  isInvalid={
                    this.state.validationFields['phoneNumber'].isInvalid
                  }
                  error={
                    this.state.validationFields['phoneNumber'].isInvalid &&
                    this.state.validationFields['phoneNumber'].errors
                  }
                >
                  <EuiFieldText
                    placeholder='Telepon'
                    value={this.state.phoneNumber}
                    name='phoneNumber'
                    onChange={this.handleChange}
                    aria-label='phoneNumber'
                  />
                </EuiFormRow>
                {this.state.isPhoneNumberChanged ? (
                  <EuiFormRow>
                    <EuiCheckbox
                      label='Ubah juga nomor telepon yang terdaftar pada sesi layanan pasien ini'
                      checked={this.state.patientUpdateChangeRelatedSessions}
                      onChange={() => {
                        this.setState({
                          patientUpdateChangeRelatedSessions:
                            !this.state.patientUpdateChangeRelatedSessions,
                        });
                      }}
                    />
                  </EuiFormRow>
                ) : null}
                <EuiFormRow
                  label='NIK'
                  isInvalid={this.state.validationFields['nik'].isInvalid}
                  error={
                    this.state.validationFields['nik'].isInvalid &&
                    this.state.validationFields['nik'].errors
                  }
                >
                  <EuiFieldText
                    placeholder='NIK'
                    value={this.state.nik}
                    name='nik'
                    onChange={this.handleChange}
                    aria-label='nik'
                  />
                </EuiFormRow>

                <EuiFormRow
                  label='Nama Jalan'
                  isInvalid={
                    this.state.validationFields['addressName'].isInvalid
                  }
                  error={
                    this.state.validationFields['addressName'].isInvalid &&
                    this.state.validationFields['addressName'].errors
                  }
                >
                  <EuiFieldText
                    placeholder='Nama Jalan'
                    value={this.state.addressName}
                    name='addressName'
                    onChange={this.handleChange}
                    aria-label='addressName'
                  />
                </EuiFormRow>
                <EuiFormRow
                  label='RT'
                  isInvalid={this.state.validationFields['rt'].isInvalid}
                  error={
                    this.state.validationFields['rt'].isInvalid &&
                    this.state.validationFields['rt'].errors
                  }
                >
                  <EuiFieldText
                    placeholder='RT'
                    value={this.state.rt}
                    name='rt'
                    onChange={this.handleChange}
                    aria-label='rt'
                  />
                </EuiFormRow>
                <EuiFormRow
                  label='RW'
                  isInvalid={this.state.validationFields['rw'].isInvalid}
                  error={
                    this.state.validationFields['rw'].isInvalid &&
                    this.state.validationFields['rw'].errors
                  }
                >
                  <EuiFieldText
                    placeholder='RW'
                    value={this.state.rw}
                    name='rw'
                    onChange={this.handleChange}
                    aria-label='rw'
                  />
                </EuiFormRow>
                <EuiFormRow
                  label='Provinsi'
                  isInvalid={
                    this.state.validationFields['selectedProvince'].isInvalid
                  }
                  error={
                    this.state.validationFields['selectedProvince'].isInvalid &&
                    this.state.validationFields['selectedProvince'].errors
                  }
                >
                  <div>
                    <EuiComboBox
                      placeholder='Provinsi'
                      options={this.state.province}
                      name='province'
                      onChange={(e) => {
                        this.onProvinceChange(e);
                      }}
                      value={this.state.selectedProvince}
                      isClearable={true}
                      aria-label='Provinsi'
                      singleSelection={{ asPlainText: true }}
                      selectedOptions={this.state.selectedOptionsProvince}
                    />
                  </div>
                </EuiFormRow>
                <EuiFormRow
                  label='Kota'
                  isInvalid={
                    this.state.validationFields['selectedCity'].isInvalid
                  }
                  error={
                    this.state.validationFields['selectedCity'].isInvalid &&
                    this.state.validationFields['selectedCity'].errors
                  }
                >
                  <div>
                    <EuiComboBox
                      placeholder='Kota'
                      options={this.state.city}
                      name='city'
                      onChange={(e) => {
                        this.onCityChange(e);
                      }}
                      value={this.state.selectedCity}
                      isClearable={true}
                      aria-label='Kota'
                      singleSelection={{ asPlainText: true }}
                      selectedOptions={this.state.selectedOptionsCity}
                      isDisabled={this.state.selectedProvince.length === 0}
                    />
                  </div>
                </EuiFormRow>
                <EuiFormRow
                  label='Kecamatan'
                  isInvalid={
                    this.state.validationFields['selectedDistrict'].isInvalid
                  }
                  error={
                    this.state.validationFields['selectedDistrict'].isInvalid &&
                    this.state.validationFields['selectedDistrict'].errors
                  }
                >
                  <div>
                    <EuiComboBox
                      placeholder='Kecamatan'
                      options={this.state.district}
                      name='district'
                      onChange={(e) => {
                        this.onDistrictChange(e);
                      }}
                      value={this.state.selectedDistrict}
                      isClearable={true}
                      aria-label='Kecamatan'
                      singleSelection={{ asPlainText: true }}
                      selectedOptions={this.state.selectedOptionsDistrict}
                      isDisabled={this.state.selectedCity.length === 0}
                    />
                  </div>
                </EuiFormRow>
                <EuiFormRow
                  label='Kelurahan'
                  isInvalid={
                    this.state.validationFields['selectedSubDistrict'].isInvalid
                  }
                  error={
                    this.state.validationFields['selectedSubDistrict']
                      .isInvalid &&
                    this.state.validationFields['selectedSubDistrict'].errors
                  }
                >
                  <div>
                    <EuiComboBox
                      placeholder='Kelurahan'
                      options={this.state.subDistrict}
                      name='subDistrict'
                      onChange={(e) => {
                        this.onSubDistrictChange(e);
                      }}
                      value={this.state.selectedSubDistrict}
                      isClearable={true}
                      aria-label='Kelurahan'
                      singleSelection={{ asPlainText: true }}
                      selectedOptions={this.state.selectedOptionsSubDistrict}
                      isDisabled={this.state.selectedDistrict.length === 0}
                    />
                  </div>
                </EuiFormRow>
                <EuiFormRow
                  label='Kode Pos'
                  isInvalid={
                    this.state.validationFields['postalCode'].isInvalid
                  }
                  error={
                    this.state.validationFields['postalCode'].isInvalid &&
                    this.state.validationFields['postalCode'].errors
                  }
                >
                  <EuiFieldText
                    placeholder='Kode Pos'
                    value={this.state.postalCode}
                    name='postalCode'
                    onChange={this.handleChange}
                    aria-label='postalCode'
                  />
                </EuiFormRow>
                <EuiFlexGroup
                  style={{ maxWidth: 356, marginTop: 5, marginBottom: 5 }}
                >
                  <EuiFlexItem>
                    <EuiFormRow label='Lat'>
                      <EuiFieldNumber
                        placeholder='0'
                        value={this.state.addressLat}
                        name='addressLat'
                        onChange={this.handleChange}
                        aria-label='addressLat'
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow label='Long'>
                      <EuiFieldNumber
                        placeholder='0'
                        value={this.state.addressLong}
                        name='addressLong'
                        onChange={this.handleChange}
                        aria-label='addressLong'
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFormRow
                  label='Jenis Kelamin'
                  isInvalid={
                    this.state.validationFields['selectedGender'].isInvalid
                  }
                  error={
                    this.state.validationFields['selectedGender'].isInvalid &&
                    this.state.validationFields['selectedGender'].errors
                  }
                >
                  <EuiSelect
                    placeholder='gender'
                    options={this.state.gender}
                    value={this.state.selectedGender}
                    name='selectedGender'
                    onChange={this.onGenderChange}
                    aria-label='Jenis Kelamin'
                  />
                </EuiFormRow>
                <EuiFormRow label='Tanggal lahir'>
                  <Datepicker
                    onChange={(date) => this.dateChange(date)}
                    value={dobMoment}
                  />
                </EuiFormRow>
                {patientUpdateRequiresOrganization !== false && (
                  <EuiFormRow
                    label='Organisasi'
                    isInvalid={
                      this.state.validationFields['organizationId'].isInvalid
                    }
                    error={
                      this.state.validationFields['organizationId'].isInvalid &&
                      this.state.validationFields['organizationId'].errors
                    }
                  >
                    <EuiSelect
                      placeholder='Organisasi'
                      options={this.state.organizations}
                      value={this.state.organizationId}
                      name='organizationName'
                      onChange={this.onOrganizationChange}
                      aria-label='Organisasi'
                    />
                  </EuiFormRow>
                )}
              </div>
              <EuiSpacer size='l' />
              <EuiFlexGroup justifyContent={'spaceBetween'}>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    isLoading={this.state.deleteLoading}
                    onClick={() => {
                      this.setState({ deleteConfirmModal: true });
                    }}
                    fill
                    color='danger'
                  >
                    {this.state.deleteLoading ? 'Menghapus...' : 'Hapus'}
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton
                    fill
                    isLoading={this.state.submitLoading}
                    onClick={this.save}
                  >
                    {this.state.submitLoading ? 'Menyimpan...' : 'Simpan'}
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiForm>
            <EuiForm className='form-layout'>
              <EuiSpacer size='l' />
              <EuiFlexGroup justifyContent={'flexEnd'}>
                <EuiFlexItem grow={true}>
                  <EuiButton
                    isLoading={false}
                    onClick={() => {
                      this.setState({ viewRawDataModal: true });
                    }}
                    outline
                  >
                    {'Lihat Isian Formulir Pendaftaran'}
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiForm>
          </EuiPageContentBody>
        );
      case 'datamedis':
        const pagination = {
          pageIndex: this.state.medicalRecordPage,
          pageSize: this.state.medicalRecordSize,
          totalItemCount: this.state.medicalRecordTotal / 2,
          hidePerPageOptions: true,
        };
        return (
          <EuiPageContentBody>
            <EuiBasicTable
              items={this.state.medicalRecords}
              noItemsMessage={'Belum ada data.'}
              columns={this.state.medicalRecordColumn}
              pagination={pagination}
              onChange={(page) => {
                this.loadMedicalRecords(page.page.index);
              }}
            />
          </EuiPageContentBody>
        );
      case 'pertemuan':
        return (
          <EuiPageContentBody>
            {this.state.loading && (
              <p>
                <EuiLoadingSpinner size='xl' />
              </p>
            )}
            {!this.state.loading && (
              <EuiPage className='class'>
                <TablePage
                  data={this.state.sessionsData}
                  column={this.state.sessionsColumn}
                  location={this.props.location}
                  disablePagination={this.state.loading}
                  loading={this.state.loading}
                  page={this.state.page}
                  limit={this.state.limit}
                  total={this.state.total}
                  prev={this.prevPage}
                  next={this.nextPage}
                  toPage={this.page}
                />
              </EuiPage>
            )}
          </EuiPageContentBody>
        );
      case 'tagihan':
        return (
          <EuiPageContentBody>
            <Table data={this.state.data} columns={this.state.column} />
          </EuiPageContentBody>
        );
      case 'lab':
        return (
          <EuiPageContentBody>
            <EuiForm className='form-layout' style={{ maxWidth: 400 }}>
              <div style={{ marginTop: 15 }}>
                <EuiFormRow label='Provinsi'>
                  <div>
                    <EuiComboBox
                      placeholder='Provinsi'
                      options={this.state.current_province}
                      name='province'
                      onChange={(e) => {
                        this.onCurrentProvinceChange(e);
                      }}
                      value={this.state.current_selected_province}
                      isClearable={true}
                      aria-label='Provinsi'
                      singleSelection={{ asPlainText: true }}
                      selectedOptions={
                        this.state.current_selected_options_province
                      }
                    />
                  </div>
                </EuiFormRow>
                <EuiFormRow label='Kota'>
                  <div>
                    <EuiComboBox
                      placeholder='Kota'
                      options={this.state.current_city}
                      name='city'
                      onChange={(e) => {
                        this.onCurrentCityChange(e);
                      }}
                      value={this.state.current_selected_city}
                      isClearable={true}
                      aria-label='Kota'
                      singleSelection={{ asPlainText: true }}
                      selectedOptions={this.state.current_selected_options_city}
                      isDisabled={
                        this.state.current_selected_province.length === 0
                      }
                    />
                  </div>
                </EuiFormRow>
                <EuiFormRow label='Kecamatan'>
                  <div>
                    <EuiComboBox
                      placeholder='Kecamatan'
                      options={this.state.current_district}
                      name='district'
                      onChange={(e) => {
                        this.onCurrentDistrictChange(e);
                      }}
                      value={this.state.current_selected_district}
                      isClearable={true}
                      aria-label='Kecamatan'
                      singleSelection={{ asPlainText: true }}
                      selectedOptions={
                        this.state.current_selected_options_district
                      }
                      isDisabled={this.state.current_selected_city.length === 0}
                    />
                  </div>
                </EuiFormRow>
                <EuiFormRow label='Kelurahan'>
                  <div>
                    <EuiComboBox
                      placeholder='Kelurahan'
                      options={this.state.current_sub_district}
                      name='subDistrict'
                      onChange={(e) => {
                        this.onCurrentSubDistrictChange(e);
                      }}
                      value={this.state.current_selected_sub_district}
                      isClearable={true}
                      aria-label='Kelurahan'
                      singleSelection={{ asPlainText: true }}
                      selectedOptions={
                        this.state.current_selected_options_sub_district
                      }
                      isDisabled={
                        this.state.current_selected_district.length === 0
                      }
                    />
                  </div>
                </EuiFormRow>
                <EuiFormRow label='Lokasi Apotek' >
                  <EuiSuperSelect
                    placeholder='Pilih Lokasi Apotek'
                    options={this.state.current_preferred_pharmacy}
                    name='preferredPharmacy'
                    onChange={(e) => {
                      this.onCurrentPreferredPharmacyChange(e);
                    }}
                    valueOfSelected={this.state.current_selected_preferred_pharmacy}
                    aria-label='preferredPharmacy'
                    disabled={
                      this.state.current_selected_sub_district.length === 0 ||
                      (this.state.preferred_pharmacy_valid &&
                        this.state.preferred_pharmacy_valid?.length === 0) || this.state.disablePharmacy
                    }
                    hasDividers
                  />
                </EuiFormRow>
                <EuiFormRow label='Lokasi Lab'>
                  <EuiComboBox
                    placeholder='Lokasi Lab'
                    options={this.state.current_preferred_diagnostic_lab}
                    name='preferredDiagnosticLab'
                    onChange={(e) => {
                      this.onCurrentPreferredDiagnosticLabChange(e);
                    }}
                    value={this.state.current_selected_preferred_diagnostic_lab}
                    isClearable={true}
                    aria-label='preferredDiagnosticLab'
                    singleSelection={{ asPlainText: true }}
                    selectedOptions={
                      this.state
                        .current_selected_options_preferred_diagnostic_lab
                    }
                    isDisabled={
                      this.state.current_selected_sub_district.length === 0 ||
                      (this.state.preferred_diagnostic_lab_valid &&
                        this.state.current_selected_preferred_diagnostic_lab
                          .length === 0)
                    }
                  />
                </EuiFormRow>

                <EuiButton
                  fill
                  isLoading={this.state.submitPreferredLoading}
                  onClick={this.savePreferredLabAndPharmacy}
                >
                  {this.state.submitPreferredLoading
                    ? 'Menyimpan...'
                    : 'Simpan'}
                </EuiButton>
              </div>
            </EuiForm>
          </EuiPageContentBody>
        );
      default:
        break;
    }
  };

  render() {
    return (
      <>
        {this.state.viewRawDataModal && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => {
                this.setState({ viewRawDataModal: false });
              }}
              initialFocus='[name=popswitch]'
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>
                  Isian Formulir Pendaftaran
                </EuiModalHeaderTitle>
              </EuiModalHeader>

              <EuiModalBody>
                {this.state.rawData !== '' ? (
                  <>
                    <EuiFlexGroup>
                      <EuiFlexItem>
                        <EuiCodeBlock language='json' isCopyable>
                          <JSONPretty
                            data={this.parseJSONString(this.state.rawData)}
                          ></JSONPretty>
                        </EuiCodeBlock>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </>
                ) : null}
              </EuiModalBody>
              <EuiModalFooter>
                <EuiButton
                  color={'primary'}
                  onClick={() => this.setState({ viewRawDataModal: false })}
                  fill
                >
                  Tutup
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
        {this.state.deleteConfirmModal && (
          <EuiOverlayMask>
            <EuiConfirmModal
              title='Anda yakin ingin menghapus pasien ini ?'
              onCancel={() => {
                this.setState({ deleteConfirmModal: false });
              }}
              onConfirm={this.delete}
              cancelButtonText='Batal'
              confirmButtonText='Hapus'
              buttonColor='danger'
              defaultFocusedButton='confirm'
            ></EuiConfirmModal>
          </EuiOverlayMask>
        )}
        {this.state.showModal && (
          <EuiOverlayMask onClick={this.closeModal}>
            <EuiModal onClose={this.closeModal}>
              <EuiModalHeader>
                <EuiModalHeaderTitle>Log Sesi</EuiModalHeaderTitle>
              </EuiModalHeader>
              <EuiModalBody>
                <EuiListGroup flush bordered={false} id='logs-list'>
                  {this.state.logsData.map((item, key) => {
                    let label = `${item.timestamp} - ${item.userType} ${item.type}`;
                    return (
                      <>
                        <EuiListGroupItem isActive key={key} label={label} />
                      </>
                    );
                  })}
                </EuiListGroup>
              </EuiModalBody>
              <EuiModalFooter>
                <EuiButton
                  onClick={() => {
                    this.downloadRecoreding(this.state.recordingKey);
                  }}
                  fill
                  color='primary'
                >
                  Unduh rekaman
                </EuiButton>
                <EuiButton
                  onClick={() => {
                    this.printLogs(this.state.logsData);
                  }}
                  fill
                  color='secondary'
                >
                  Cetak
                </EuiButton>
                <EuiButton onClick={this.closeModal} fill>
                  Tutup
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
        <EuiPage className='euiNavDrawerPage'>
          <EuiPageBody className={'content-container'}>
            <EuiPageContent>
              <EuiPageContentHeader>
                <EuiPageContentHeaderSection style={{}}>
                  <EuiFlexGroup wrap gutterSize='s'>
                    <EuiFlexItem grow={false}>
                      <EuiButtonIcon
                        color='primary'
                        onClick={() => window.history.back()}
                        iconType='arrowLeft'
                        aria-label='Back'
                        iconSize='xl'
                        size='m'
                      />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiTitle>
                        <h2>Pasien</h2>
                      </EuiTitle>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiPageContentHeaderSection>
                <EuiPageContentHeaderSection>
                  <EuiFlexGroup>
                    {this.props.buttonLabel ? (
                      <EuiFlexItem grow={false}>
                        <EuiButton fill onClick={this.props.butonFunc}>
                          {this.props.buttonLabel}
                        </EuiButton>
                      </EuiFlexItem>
                    ) : null}
                  </EuiFlexGroup>
                </EuiPageContentHeaderSection>
              </EuiPageContentHeader>
              <EuiTabs>{this.renderTabs()}</EuiTabs>
              {this.renderTabsContent()}
            </EuiPageContent>
          </EuiPageBody>
        </EuiPage>
        {this.state.viewthirdPartyModal ? (
          <EuiOverlayMask>
            <EuiModal
              onClose={this.handleViewthirdPartyModal}
              initialFocus='[name=popswitch]'
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>
                  <h1>{this.state.thirdPartyTitle}</h1>
                </EuiModalHeaderTitle>
              </EuiModalHeader>
              <EuiModalBody>
                <EuiForm id='modalFormId' component='form'>
                  <EuiFormRow label='Username'>
                    <EuiFieldText
                      name='username'
                      value={
                        this.state.thirdPartyAccount['third_party_username']
                      }
                      onChange={this.handlethirdPartyAccount}
                    />
                  </EuiFormRow>
                  <EuiFormRow label='Password'>
                    <EuiFieldPassword
                      type={'dual'}
                      name='password'
                      value={
                        this.state.thirdPartyAccount['third_party_password']
                      }
                      onChange={this.handlethirdPartyAccount}
                    />
                  </EuiFormRow>
                  <EuiFormRow label='Berlaku dari tanggal'>
                    <EuiDatePicker
                      selected={this.state.thirdPartyAccount.start_time}
                      onChange={this.thirdPartyStartDate}
                    />
                  </EuiFormRow>
                  <EuiFormRow label='Sampai tanggal'>
                    <EuiDatePicker
                      selected={this.state.thirdPartyAccount.end_time}
                      onChange={this.thirdPartyEndDate}
                    />
                  </EuiFormRow>
                </EuiForm>
              </EuiModalBody>
              <EuiModalFooter>
                <EuiButtonEmpty onClick={this.handleViewthirdPartyModal}>
                  Cancel
                </EuiButtonEmpty>
                <EuiButton
                  type='submit'
                  form='modalFormId'
                  onClick={this.updateSessionAuth}
                  fill
                >
                  Save
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
            )
          </EuiOverlayMask>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default PatientDetail;
