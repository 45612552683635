import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';

const ErrorService = new Error();
const MiscService = new Misc();

class Patients {
  getPatients = payload => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.Order || 'ASC';
      payload.search = payload.search || '';
      payload.excluding_deleted = payload.excluding_deleted || true;
      payload.externalPatients = payload.externalPatients || ''; 
      let url = `${Config.apiHost}/api/patients?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&search=${payload.search}&excludingDeleted=${payload.excluding_deleted}&externalPatients=${payload.externalPatients}`;
      if (payload.search && payload.search.length > 0) {
        url += `&search=${payload.search}`;
      }
      if (payload.organizationId && payload.organizationId.length > 0) {
        url += `&organizationId=${payload.organizationId}`;
      }
      axios
        .get(url, {headers: MiscService.generateHeaders()})
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  getPatientMedicalRecords = opts => {
    return new Promise((resolve, reject) => {
      opts = opts || {};
      opts.page = opts.page || 1;
      opts.limit = opts.limit || 10;
      let url = `${Config.apiHost}/api/medical-records-by-patient-id/${opts.patientId}?page=${opts.page}&limit=${opts.limit}`;
      axios
        .get(url, {headers: MiscService.generateHeaders()})
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          if (!(err && err.message.includes('404'))) {
            ErrorService.handle(err);
          }
        });
    });
  };
  create = payload => {
    console.log(payload);
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/patients`;
      axios
        .post(url, payload, {headers: MiscService.generateHeaders()})
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  update = payload => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/patients/${payload.id}`;
      axios
        .put(url, payload, {headers: MiscService.generateHeaders()})
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  delete = id => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/patients/${id}`;
      axios
        .delete(url, {headers: MiscService.generateHeaders()})
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  import = (payload) => {
    const {organizationId, file, external} = payload;
    console.log(organizationId);
    let url = `${Config.apiHost}/api/patient/import?organization_id=${organizationId}`;
    if (external !==null && external ) url = `${Config.apiHost}/api/import-external-patient?organization_id=${organizationId}`;
    console.log(url)
    const formData = new FormData();
    formData.append('file', file[0]);
    return new Promise((resolve, reject) => {
      axios
        .post(url, formData, {headers: MiscService.generateHeaders()})
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getPatient = uuid => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/patient/${uuid}`;
      axios
        .get(url, {headers: MiscService.generateHeaders()})
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  getPatientsByClinic = payload => {
    return new Promise((resolve, reject) => {
      payload = JSON.stringify(payload);
      let url = `${Config.apiHost}/api/patients-by-clinic`;
      axios
        .post(url, payload, {headers: MiscService.generateHeaders()})
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  updatePatientPreferredLabAndPharmacy = payload => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/patient/preferred-lab-pharmacy/${payload.id}`;
      axios
        .put(url, payload, {headers: MiscService.generateHeaders()})
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  }
}

export default Patients;
