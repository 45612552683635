import React, { useEffect, useState } from 'react';
import {
  EuiPage,
  EuiPageBody,
  EuiTitle,
  EuiModalBody,
  EuiSelect,
  EuiForm,
  EuiSwitch,
  EuiOverlayMask,
  EuiFormRow,
  EuiModal,
  EuiModalHeaderTitle,
  EuiModalHeader,
  EuiFlexItem,
  EuiFieldText,
  EuiComboBox,
  EuiButtonEmpty,
  EuiButton,
  EuiModalFooter,
  EuiDatePicker,
} from '@elastic/eui';
import Utils from '../Utils';
import ErrorService from '../services/Error.js';
import TablePage from '../components/TablePage';
import SessionSlotService from '../services/SessionSlots';
import { debounce } from 'lodash';
import moment from 'moment';
import OrganizationService from '../services/Organizations';
import ProductService from '../services/Products.js';
import ClinicService from '../services/Clinics';
import Staff from '../services/Staffs';
import swal from 'sweetalert';

const OfflineSchedule = () => {
  const errorService = new ErrorService();
  const sessionSlotService = new SessionSlotService();
  const organizationService = new OrganizationService();
  const clinicService = new ClinicService();
  const staffService = new Staff();
  const productService = new ProductService();

  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState('');
  const [activeSessions, setActiveSessions] = useState(0);

  const [organizations, setOrganizations] = useState([]);
  const [organizationId, setOrganizationId] = useState('');

  const [externalId, setExternalId] = useState('');

  const [clinics, setClinics] = useState([]);
  const [clinicId, setClinicId] = useState('');
  const [selectedOptionsClinic, setSelectedOptionsClinic] = useState([]);

  const [staffs, setStaffs] = useState([]);
  const [staffId, setStaffId] = useState('');
  const [selectedOptionsStaff, setSelectedOptionsStaff] = useState([]);

  const [assistants, setAssistants] = useState([]);
  const [assistantId, setAssistantId] = useState('');
  const [selectedOptionsAssistant, setSelectedOptionsAssistant] = useState([]);

  const [specificDate, setSpecificDate] = useState('');

  const [tags, setTags] = useState([]);

  const [duration, setDuration] = useState('');
  const durations = [
    {
      text: '10',
      value: '600000',
    },
    {
      text: '15',
      value: '900000',
    },
    {
      text: '30',
      value: '1800000',
    },
    {
      text: '45',
      value: '2700000',
    },
    {
      text: '60',
      value: '3600000',
    },
    {
      text: '240',
      value: '14400000',
    },
  ];
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [startTimeRangeOptions, setStartTimeRangeOptions] = useState([]);
  const [endTimeRangeOptions, setEndTimeRangeOptions] = useState([]);

  const [products, setProducts] = useState([]);
  const [productId, setProductId] = useState('');

  const [excludeSync, setExcludeSync] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [maxCount, setMaxCount] = useState(0);

  const [search, setSearch] = useState('');
  const [searchDate, setSearchDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [dateRange, setDateRange] = useState('all');
  const dateRangeOptions = [
    {
      text: 'Semua tanggal',
      value: 'all',
    },
    {
      text: 'Tanggal',
      value: 'specificDate',
    },
  ];

  useEffect(() => {
    getSessionSlots();
    loadOrganizations();
    loadProducts();
  }, []);

  const column = [
    {
      field: 'externalId',
      name: 'External ID',
    },
    {
      field: 'organizationName',
      name: 'Organisasi',
    },

    {
      field: 'clinicName',
      name: 'Klinik',
    },
    {
      field: 'staffName',
      name: 'Dokter',
    },
    {
      field: 'startTime',
      name: 'Awal',
    },
    {
      field: 'endTime',
      name: 'Akhir',
    },
    {
      field: 'specificDate',
      name: 'Tanggal',
      render: (item) => {
        return item;
      },
    },
    {
      field: 'duration',
      name: 'Durasi (menit)',
      render: (item) => {
        return item / 60000 + ' Menit';
      },
    },
    {
      field: 'productName',
      name: 'Produk/Tarif',
    },
  ];

  const handleOnSearchDate = (value) => {
    const newDate = moment(value).format('YYYY-MM-DD');
    setSearchDate(newDate);
    getSessionSlots(1);
  };

  const handleOnSearch = (e) => {
    setLoading(true);
    setSearch(e);
    handleSearchDebounce();
  };

  const handleSearchDebounce = debounce(() => {
    getSessionSlots(1);
  }, 2000);

  const nextPage = () => {
    getSessionSlots(page + 1);
  };

  const prevPage = () => {
    getSessionSlots(page - 1);
  };

  const add = () => {
    prepareNewItem();
  }

  const getSessionSlots = (page) => {
    setLoading(true);
    let payload = {
      page: page || 1,
      limit: 10,
      search: search,
      specificDate: searchDate,
      consultationType: 1,
    };
    sessionSlotService
      .getSessionSlots(payload)
      .then((result) => {
        setData(result.items);
        setPage(result.page);
        setLimit(result.limit);
        setTotal(result.total);
      })
      .catch((err) => {
        console.log(err);
        setData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const loadOrganizations = () => {
    return new Promise((resolve, reject) => {
      let payload = {
        limit: 1000,
      };
      organizationService
        .getOrganizations(payload)
        .then((result) => {
          let options = [];
          options.push({ value: '', text: 'Pilih organisasi...' });
          for (let i in result.items) {
            options.push({
              value: result.items[i].id,
              text: result.items[i].name,
            });
          }
          setOrganizations(options);
        })
        .catch((err) => {
          setOrganizations([]);
          reject(err);
        });
    });
  };

  const loadProducts = () => {
    return new Promise((resolve, reject) => {
      productService
        .getProducts()
        .then((result) => {
          let options = [];
          options.push({ value: '', text: 'Pilih produk...' });
          for (let i in result.items) {
            options.push({
              value: result.items[i].id,
              text: `${result.items[i].title} - Rp. ${result.items[i].price.units}`,
            });
          }
          setProducts(options);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const onOrganizationChange = (e) => {
    let value = e.target.value || null;
    setOrganizationId(value);
    setSelectedOptionsClinic([]);
    loadClinics(value);
  };

  const loadClinics = async (organizationId) => {
    try {
      let payload = {
        limit: 1000,
        organizationId: organizationId,
      };
      let options = [];
      let result = await clinicService.getClinics(payload);
      for (let i in result.items) {
        options.push({
          value: result.items[i].id,
          label: result.items[i].name,
          text: result.items[i].name,
        });
      }
      setClinics(options);
    } catch (err) {
      console.log(err);
    }
  };

  const prepareNewItem = () => {
    setShowModal(true);
    setId('');
    setClinicId('');
    setOrganizationId('');
    setStaffId('');
    setAssistantId('');
    setStartTime('00:00:00');
    setEndTime('00:00:00');
    setDuration(900000);
    setProductId('');
    setStartTimeRangeOptions(Utils.generateTimeRangeOptions(15, '00:00:00'));
    setEndTimeRangeOptions(Utils.generateTimeRangeOptions(15, '00:00:00'));
    setSelectedOptionsAssistant([]);
    setSelectedOptionsClinic([]);
    setSelectedOptionsStaff([]);
    setSpecificDate(null);
    setExcludeSync(false);
    setIsActive(true);
    setExternalId('');
  };

  const onPickedClinic = (e) => {
    if (e.length > 0) {
      setClinicId(e[0].value);
      setSelectedOptionsClinic(e);

      loadStaffsByClinicAndOrganizationId(e[0].value, organizationId);
      setStaffId(null);
      setAssistantId(null);
      setStaffs([]);
      setSelectedOptionsStaff([]);
      setSelectedOptionsAssistant([]);
      setProductId(null);
    }
  };

  const loadStaffsByClinicAndOrganizationId = (clinicId, organizationId) => {
    return new Promise((resolve, reject) => {
      staffService
        .getStaffs({ clinicId, limit: 1000 })
        .then((result) => {
          if ((result.items && result.items.length < 1) || !result.items) {
            setClinicId();
            alert('Belum ada dokter dari klinik ini');
            return;
          }
          let hasDoctor = false;
          let options = [];
          let assistantOptions = [];
          for (let i in result.items) {
            if (result.items[i].type === 'DOCTOR') {
              hasDoctor = true;
              options.push({
                text: result.items[i].name,
                value: result.items[i].id,
                label: result.items[i].name,
              });
            } else {
              assistantOptions.push({
                text: result.items[i].name,
                value: result.items[i].id,
                label: result.items[i].name,
              });
            }
          }

          if (!hasDoctor) {
            setClinicId();
            alert('Belum ada dokter dari klinik ini');
            return;
          }
          setStaffs(options);
          setAssistants(assistantOptions);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  const loadMaxCount = (id) => {
    sessionSlotService
      .getMaxCount(id)
      .then((result) => {
        setMaxCount(result.max_count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onItemClick = (item) => {
    if (item.organizationId && item.organizationId.length > 0 && item.clinicId && item.clinicId.length > 0) {
      loadMaxCount(item.id);
      loadClinics(item.organizationId)
        .then(() => {
          loadStaffsByClinicAndOrganizationId(item.clinicId, item.organizationId);
        })
        .then(() => {
          let assistantsOption = [];
          if (item.assistantId) {
            assistantsOption = [
              {
                text: item.assistantName,
                label: item.assistantName,
                value: item.assistantId,
              },
            ];
          }

          let tagsData = [];
          for (let i in item.tags) {
            tagsData.push({ label: item.tags[i] });
          }

          setId(item.id);
          setActiveSessions(item.activeSessions);
          setOrganizationId(item.organizationId);
          setClinicId(item.clinicId);
          setStaffId(item.staffId);
          setAssistantId(item.assistantId);
          setStartTime(item.startTime);
          setEndTime(item.endTime);
          setDuration(item.duration);
          setProductId(item.productId);
          setSpecificDate(moment(item.specificDate));
          setExcludeSync(item.excludeSync);
          setIsActive(item.isActive);
          setExternalId(item.externalId);
          setSelectedOptionsClinic([
            {
              text: item.clinicName,
              label: item.clinicName,
              value: item.clinicId,
            },
          ]);
          setSelectedOptionsStaff([
            {
              text: item.staffName,
              label: item.staffName,
              value: item.staffId,
            },
          ]);
          setSelectedOptionsAssistant(assistantsOption);
          setStartTimeRangeOptions(Utils.generateTimeRangeOptions(item.duration / 60000, '00:00:00'));
          setEndTimeRangeOptions(Utils.generateTimeRangeOptions(item.duration / 60000, '00:00:00'));
          setTags(tagsData);
          setShowModal(true);
        });
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setId('');
    setOrganizationId('');
    setClinicId('');
    setStaffId('');
    setExcludeSync(false);
    setIsActive(false);
    setProductId('');
    setSpecificDate(null);
    setSelectedOptionsStaff([]);
    setSelectedOptionsClinic([]);
    setSelectedOptionsAssistant([]);
  };

  const onDateRangeChange = (e) => {
    let value = e.target.value || null;
    setDateRange(value);
    setSearchDate('');
    getSessionSlots(1);
  };


  const deleteSchedule = () => {
    if (!(id && id.length > 0)) {
      return;
    }
    const msgText = 'Terdapat ' + activeSessions + ' pasien terdaftar. \n';
    if (!window.confirm(msgText + 'Apakah Anda yakin ingin menghapus jadwal ini?')) {
      return;
    }

    sessionSlotService
      .delete(id)
      .then((result) => {
        console.log(result);
        closeModal();
        getSessionSlots(1);
      })
      .catch((err) => {
        errorService.handle(err);
      });
  };


  const saveSchedule = () => {
    let labelTags = [];
    for (let i in tags) {
      labelTags.push(tags[i].label);
    }

    let payload = {
      clinicId: clinicId,
      staffId: staffId,
      assistantId: assistantId,
      organizationId: organizationId,
      startTime: startTime,
      endTime: endTime,
      duration: parseInt(duration, 10),
      productId: productId,
      scheduleType: 1,
      excludeSync: excludeSync,
      isActive: isActive,
      externalId: externalId,
      specificDate: specificDate.format('YYYY-MM-DD'),
      consultationType: 1,
      labelTags,
    };


    if (id && id.length > 0) {
      payload.id = id;
      // Update existing
      sessionSlotService
        .update(payload)
        .then((result) => {
          if (maxCount > 0) {
            sessionSlotService.updateMaxCount(
              payload.id,
              maxCount
            );
          }
          closeModal();
          getSessionSlots(1);
        })
        .catch((err) => {

          if (
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message === 'already-have-sessions'
          ) {
            swal({
              icon: 'error',
              title: 'Terdapat sesi terdaftar.',
              text: 'Mohon maaf, slot jadwal tidak dapat diubah karena sudah ada sesi pasien yang terdaftar.',
              confirmButtonText: 'OK',
            });
          } else {
            errorService.handle(err);
          }
        });
    } else {
      // Create new one
      sessionSlotService
        .create(payload, false)
        .then((result) => {
          closeModal();
          getSessionSlots(1);
        })
        .catch((err) => {
          errorService.handle(err);
        });
    }
  };

  return (
    <>
      <EuiPage className="class">
        <EuiPageBody style={{ flexDirection: 'row' }} className={'content-container'}>
          <div style={{ width: '100%' }}>
            <div style={{ textAlign: 'left' }}>
              <EuiTitle>
                <h2>Pengaturan Jadwal Tatap Muka</h2>
              </EuiTitle>
              <br />
              <br />
            </div>
            <div>
              <TablePage
                title={''}
                placeholder={'Cari'}
                searchDate={
                  <EuiFlexItem>
                    {dateRange === 'specificDate' && (
                      <EuiDatePicker
                        selected={searchDate === '' ? moment() : moment(searchDate)}
                        onChange={(e) => {
                          handleOnSearchDate(e._d);
                        }}
                      />
                    )}
                  </EuiFlexItem>
                }
                searchComp={
                  <EuiFlexItem>
                    <EuiFieldText
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          handleOnSearch(search);
                        }
                      }}
                      value={search}
                      onChange={(e) => handleOnSearch(e.target.value)}
                      placeholder="Cari ..."
                      append={<EuiButtonEmpty onClick={() => handleOnSearch(search)}>Cari</EuiButtonEmpty>}
                    />
                  </EuiFlexItem>
                }
                searchOpt={
                  <EuiFlexItem grow={false}>
                    <EuiSelect style={{ display: 'inline-block !important' }} placeholder="Cari berdasarkan" options={dateRangeOptions} value={dateRange} name="dateRange" onChange={onDateRangeChange} />
                  </EuiFlexItem>
                }
                buttonLabel={"Tambah"}
                buttonFunc={add}
                data={data}
                column={column}
                disablePagination={loading}
                loading={loading}
                page={page}
                limit={limit}
                total={total}
                prev={prevPage}
                next={nextPage}
                toPage={getSessionSlots}
                onItemClick={onItemClick}
              />
            </div>
          </div>
        </EuiPageBody>
      </EuiPage>
      {showModal && (
        <EuiOverlayMask>
          <EuiModal onClose={() => closeModal()}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>Slot Jadwal</EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <EuiForm>
                <EuiFormRow label="Organisasi">
                  <EuiSelect placeholder="Organisasi" options={organizations} value={organizationId} name="organizationName" onChange={onOrganizationChange} aria-label="Organisasi" />
                </EuiFormRow>
                {organizationId && organizationId.length > 0 && (
                  <EuiFormRow>
                    <div>{(!clinics || (clinics && clinics.length < 1)) && <span>Belum ada klinik untuk organisasi ini.</span>}</div>
                  </EuiFormRow>
                )}
                {clinics && clinics.length > 0 && organizationId && organizationId.length > 0 && (
                  <EuiFormRow label="External ID">
                    <div>
                      <EuiFieldText
                        placeholder="External ID"
                        value={externalId}
                        onChange={(e) => {
                          setExternalId(e.target.value);
                        }}
                        aria-label={externalId}
                      />
                    </div>
                  </EuiFormRow>
                )}
                {clinics && clinics.length > 0 && organizationId && organizationId.length > 0 && (
                  <EuiFormRow label="Klinik">
                    <div>
                      <EuiComboBox
                        placeholder="Klinik"
                        options={clinics}
                        value={selectedOptionsClinic[0]?.value}
                        name="clinicName"
                        onChange={(e) => {
                          onPickedClinic(e);
                        }}
                        isClearable={false}
                        aria-label="Klinik"
                        singleSelection={{ asPlainText: true }}
                        selectedOptions={selectedOptionsClinic}
                      />
                    </div>
                  </EuiFormRow>
                )}
                {clinics && clinics.length > 0 && clinicId && clinicId.length > 0 && staffs && staffs.length > 0 && organizationId && organizationId.length > 0 && (
                  <div style={{ marginTop: 15 }}>
                    <EuiFormRow label="Dokter">
                      <div>
                        <EuiComboBox
                          placeholder="Dokter"
                          options={staffs}
                          name="staffId"
                          onChange={(e) => {
                            let value = '';
                            if (e.length > 0) {
                              value = e[0].value;
                            }
                            setStaffId(value);
                            setSelectedOptionsStaff(e);
                          }}
                          value={staffId}
                          isClearable={true}
                          aria-label="Klinik"
                          singleSelection={{ asPlainText: true }}
                          selectedOptions={selectedOptionsStaff}
                        />
                      </div>
                    </EuiFormRow>
                    <EuiFormRow label="Asisten">
                      <div>
                        <EuiComboBox
                          placeholder="Asisten"
                          label="Asisten"
                          options={assistants}
                          name="assistantId"
                          onChange={(e) => {
                            let value = '';
                            if (e.length > 0) {
                              value = e[0].value;
                            }
                            setAssistantId(value);
                            setSelectedOptionsAssistant(e);
                          }}
                          value={assistantId}
                          isClearable={true}
                          aria-label="Asisten"
                          singleSelection={{ asPlainText: true }}
                          selectedOptions={selectedOptionsAssistant}
                        />
                      </div>
                    </EuiFormRow>
                  </div>
                )}
                {clinicId && clinicId.length > 0 && organizationId && organizationId.length > 0 && (
                  <>
                    <div style={{ marginTop: 15 }}>
                      <div>
                        <EuiFormRow label="Tanggal">
                          <EuiDatePicker
                            selected={specificDate}
                            onChange={(e) => {
                              setSpecificDate(e);
                            }}
                          />
                        </EuiFormRow>
                      </div>
                    </div>
                    <EuiFormRow label="Tag">
                      <EuiComboBox
                        placeholder="Tag"
                        options={[]}
                        label={'Tag'}
                        isClearable={true}
                        selectedOptions={tags || []}
                        name="Tag"
                        onCreateOption={(val) => {
                          let tagsArr = tags || [];
                          tagsArr.push({ label: val });
                          setTags(tags);
                        }}
                        aria-label="Tag"
                      />
                    </EuiFormRow>
                    <EuiFormRow label="Durasi (menit)">
                      <EuiSelect
                        placeholder="Durasi"
                        options={durations}
                        value={duration}
                        name="duration"
                        onChange={(e) => {
                          let value = e.target.value || null;
                          if (!value || (value && value.length < 1)) {
                            return;
                          }
                          setDuration(value);

                          const defaultTime = Utils.generateTimeRangeOptions(value / 60000, '00:00:00');

                          setStartTime(null);
                          setEndTime(null);
                          setStartTimeRangeOptions(defaultTime);
                          setEndTimeRangeOptions(defaultTime);
                        }}
                        aria-label="Durasi"
                      />
                    </EuiFormRow>
                    <EuiFormRow label="Awal">
                      <EuiSelect
                        placeholder="Awal"
                        options={startTimeRangeOptions}
                        value={startTime}
                        name="startTime"
                        onChange={(e) => {
                          let value = e.target.value || null;
                          if (!value || (value && value.length < 1)) {
                            return;
                          }
                          setStartTime(value);
                          setEndTime(null);
                          setEndTimeRangeOptions(Utils.generateTimeRangeOptions(duration / 60000, value));
                        }}
                        aria-label="Awal"
                      />
                    </EuiFormRow>
                    <EuiFormRow label="Akhir">
                      <EuiSelect
                        placeholder="Akhir"
                        options={endTimeRangeOptions}
                        value={endTime}
                        name="endTime"
                        onChange={(e) => {
                          let value = e.target.value || null;
                          if (!value || (value && value.length < 1)) {
                            return;
                          }
                          setEndTime(value);
                        }}
                        aria-label="Akhir"
                      />
                    </EuiFormRow>
                    <EuiFormRow label="Produk/Tarif">
                      <EuiSelect
                        placeholder="Produk/Tarif"
                        options={products}
                        value={productId}
                        name="productName"
                        onChange={(e) => {
                          let value = e.target.value || null;
                          setProductId(value);
                        }}
                        aria-label="Produk/Tarif"
                      />
                    </EuiFormRow>
                    <EuiFormRow label="Kecualikan dalam proses sync">
                      <EuiSwitch
                        label=""
                        onChange={() => {
                          setExcludeSync(!excludeSync);
                        }}
                        checked={excludeSync}
                      />
                    </EuiFormRow>
                    {id && id.length > 0 && (
                      <EuiFormRow label="Jadwal Aktif">
                        <EuiSwitch
                          label=""
                          onChange={(e) => {
                            setIsActive(e.target.checked);
                          }}
                          checked={isActive ?? false}
                        />
                      </EuiFormRow>
                    )}
                    {id && id.length > 0 && (
                      <EuiFormRow label="Jumlah Slot">
                        <EuiFieldText
                          placeholder="Jumlah Slot"
                          options={maxCount}
                          value={maxCount}
                          name="maxCount"
                          onChange={(e) => {
                            let value = e.target.value || null;
                            setMaxCount(value);
                          }}
                          aria-label="Jumlah Slot"
                        />
                      </EuiFormRow>
                    )}
                    <br />
                    <br />
                  </>
                )}
              </EuiForm>
            </EuiModalBody>
            <EuiModalFooter>
              <EuiButtonEmpty onClick={() => closeModal()}>Tutup</EuiButtonEmpty>
              {id && (
                <EuiButton color={"danger"} onClick={() => deleteSchedule()} fill>
                  Hapus
                </EuiButton>
              )}
              <EuiButton onClick={() => saveSchedule()} fill>
                Simpan
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      )}
    </>
  );
};

export default OfflineSchedule;
