import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';

const ErrorService = new Error();
const MiscService = new Misc();

class ToExcels {
  fromConsultations = (payload) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/download-consultations`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  fromDailyConsultations = (payload) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/download-daily-consultations`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  paymentRecap = (payload) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/download-payment-recap`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
}

export default ToExcels;
