import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';

const ErrorService = new Error();
const MiscService = new Misc();

class Events {
  list = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.limit = payload.limit || 10;
      payload.page = payload.page || 0;
      payload.order = payload.order || '';
      payload.order_by = payload.order_by || '';

      payload.state = payload.state || 0;
      payload.secondary_key = payload.secondary_key || '';
      payload.code = payload.code || '';

      let url = `${Config.apiHost}/api/events?limit=${payload.limit}&page=${payload.page}&order=${payload.order}&order_by=${payload.order_by}&state=${payload.state}&secondary_key=${payload.secondary_key}&code=${payload.code}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  cancelEvent = (id) => {
    return new Promise((resolve, reject) => {
      const headers = MiscService.generateHeaders();
      let url = `${Config.apiHost}/api/events/cancel/${id}`;
      console.log(headers);
      axios
        .put(url, null, { headers })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  retryEvent = (id) => {
    return new Promise((resolve, reject) => {
      const headers = MiscService.generateHeaders();
      let url = `${Config.apiHost}/api/events/retry/${id}`;
      console.log(headers);
      axios
        .put(url, null, { headers })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
}

export default Events;
