import syncFetch from "sync-fetch";

var config = () => {
  let data = {};
  const response = syncFetch(window.location.origin + "/admin.json", {
    headers: {
      Accept: "application/json",
    },
  });
  // default
  data.apiHost = data.apiHost || "https://localhost:4443";
  data.weblink = data.weblink || "https://localhost:4445";
  data.environmentLabel = data.environmentLabel || "";

  console.log(response);
  if (response.status === 200) {
    let res = response.json();
    data.apiHost = res.backendUrl || "https://localhost:4443";
    data.weblink = res.weblinkUrl || "";
    data.environment = res.environment || "";
    data.patientUpdateRequiresMR = res.patientUpdateRequiresMR || "";
    data.patientUpdateRequiresOrganization = res.patientUpdateRequiresOrganization || "";
    data.variant = res.variant || "";
    data.stage = res.environment || "";
    data.environmentLabel = res.environmentLabel || "";
    data.productName = res.productName || "" ;
    // superset
    data.supersetBaseUrl = res.supersetBaseUrl || ""; // base url (e.g: https://data.siapdok.id)
    data.supersetPages = res.supersetPages || []; // list superset page that will be displayed
    /* e.g:
    [
      {
        id: "payment-recap",
        name: "Rekap Pembayaran",
        path: "/superset/dashboard/rscm-rekap-pembayaran/?standalone=true"
      }
    ]
    */
  } else {
    console.log("missing-environment-json");
    console.log("Using default environment");
  }
  console.log(data);

  return data;
};

export default config();

// Example JSON in admin.json
// {
//   "backendUrl":"http://chatbot:8000"
// }

// Available parameter :
// -backendUrl
// -environment
