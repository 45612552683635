import Config from "../Config";
import axios from "axios";
import Error from './Error';
import Misc from "./Misc";

const ErrorService = new Error();
const MiscService = new Misc();

class Users {
  login = (payload) => {
    return new Promise((resolve, reject) => {
      axios.post(Config.apiHost + "/api/login",
        payload
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  }
  getUsers = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {}
      payload.page = payload.page || 1
      payload.limit = payload.limit || 10
      payload.order = payload.Order || "ASC"
      let url = `${Config.apiHost}/api/users?page=${payload.page}&limit=${payload.limit}&order=${payload.order}`
      if (payload.search && payload.search.length > 0 ) {
          url += `&search=${payload.search}`
      }
      axios.get(url, { headers: MiscService.generateHeaders() })
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        reject(err)
        ErrorService.handle(err)
      })
    });
  }
  create = payload => {
    console.log(payload)
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/users`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  update = payload => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      let url = `${Config.apiHost}/api/user/${payload.id}`;
      axios
        .put(url, payload, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  delete = id => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/user/${id}`;
      axios
        .delete(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  refresh = () => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/refresh-token`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}

export default Users;
