import axios from 'axios';
import Error from './Error';
import Misc from './Misc';
import Config from '../Config';

const ErrorService = new Error();
const MiscService = new Misc();

class Admin {
  getData = () => {
    return new Promise((resolve, reject) => {
      axios
        .get('./admin.json', { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  createManualEvent = (code, secondaryKey) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/create-manual-event?code=${code}&secondaryKey=${secondaryKey}`;
      axios
        .post(url, {}, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export default Admin;
