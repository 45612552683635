import Config from "../Config";
import axios from "axios";
import Error from './Error';
import Misc from "./Misc";
import camelCase from 'camelcase-keys-deep';

const ErrorService = new Error();
const MiscService = new Misc();

class Payments {

  getPayments = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {}
      payload.page = payload.page || 1
      payload.limit = payload.limit || 1
      payload.order = payload.Order || "DESC"
      payload.orderBy = payload.orderBy || "p.payment_time"

      let url = `${Config.apiHost}/api/thirdparty/payments?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&orderBy=${payload.orderBy}`
      if (payload.search && payload.search.length > 0 ) {
          url += `&search=${payload.search}`
      }

      if (payload.startDate && payload.startDate.length > 0) {
        url += `&startDate=${payload.startDate}`;
      }

      if (payload.endDate && payload.endDate.length > 0) {
        url += `&endDate=${payload.endDate}`;
      }

      if (payload?.filterProductType && payload?.filterProductType?.length > 0) {
        url += `&filterProductType=${payload.filterProductType}`;
      }

      if (payload?.filterPaymentMethod && payload?.filterPaymentMethod?.length > 0) {
        url += `&filterPaymentMethod=${payload.filterPaymentMethod}`;
      }

      axios.get(url, { headers: MiscService.generateHeaders() })
      .then((response) => {
          resolve(response.data);
      })
      .catch((err) => {
        reject(err)
        ErrorService.handle(err)
      })
    });
  }

  checkPayment = (id) => {
    return new Promise((resolve, reject) => {
      id = id || ""
      let url = `${Config.apiHost}/api/check-payment/${id}`
      axios.get(url, { headers: MiscService.generateHeaders() })
      .then((response) => {
          resolve(response.data);
      })
      .catch((err) => {
        reject(err)
        ErrorService.handle(err)
      })
    });  
  }

  getPaymentRecaps = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {}
      payload.page = payload.page || 1
      payload.limit = payload.limit || 1
      payload.order = payload.Order || "ASC"
      let url = `${Config.apiHost}/api/admin/payment-recaps?page=${payload.page}&limit=${payload.limit}&order=${payload.order}`
      if (payload.search && payload.search.length > 0 ) {
          url += `&search=${payload.search}`
      }
      axios.get(url, { headers: MiscService.generateHeaders() })
      .then((response) => {
          resolve(response.data);
      })
      .catch((err) => {
        reject(err)
        ErrorService.handle(err)
      })
    });
  }

  completePaymentByOrderId = (id) => {
    return new Promise((resolve, reject) => {
      id = id || ""
      let url = `${Config.apiHost}/api/v1/complete-payment-by-order-id/${id}`
      axios.get(url, { headers: MiscService.generateHeaders() })
      .then((response) => {
          resolve(response.data);
      })
      .catch((err) => {
        reject(err)
        ErrorService.handle(err)
      })
    });  
  }

}

export default Payments;
