import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';

const ErrorService = new Error();
const MiscService = new Misc();

class Blob {
  uploadFile = (file, bucket = '') => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/blob/upload`;
      const formData = new FormData();
      formData.append('file', file);

      if (bucket) {
        formData.append('bucketName', bucket);
      }

      axios
        .post(url, formData, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  uploadImage = (file) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/v1/banner/upload`;
      const formData = new FormData();
      formData.append('file', file);

      axios
        .post(url, formData, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getBlob = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiHost + '/api/blob/' + id, {
          headers: MiscService.generateHeaders(),
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
  getBanners = (limit, page, search) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          Config.apiHost +
            `/api/banners?limit=${limit}&page=${page}&search=${search}`,
          {
            headers: MiscService.generateHeaders(),
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getBannerByID = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(Config.apiHost + `/api/banner/${id}`, {
          headers: MiscService.generateHeaders(),
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  deleteBannerByID = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(Config.apiHost + `/api/banner/${id}`, {
          headers: MiscService.generateHeaders(),
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
}

export default Blob;
