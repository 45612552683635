import React from 'react';
import {
  EuiButtonEmpty,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  EuiFlexItem,
  EuiFlexGroup,
  EuiFieldText,
  EuiSelect,
  EuiTextArea,
  EuiLink,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButton,
  EuiIcon,
  EuiBadge,
  EuiButtonIcon,
  EuiCallOut,
  EuiGlobalToastList,
  EuiBasicTable,
  EuiComboBox,
  EuiSwitch,
  EuiDescriptionList,
  EuiTitle,
  EuiLoadingSpinner,
} from '@elastic/eui';

import { RIGHT_ALIGNMENT } from '@elastic/eui/lib/services';
import SessionService from '../services/Sessions';
import PatientsService from '../services/Patients';
import ErrorService from '../services/Error.js';
import MedicinesService from '../services/Medicines';
import Config from '../Config';
import AdminServices from '../services/Admin';

import swal from 'sweetalert';
import { debounce } from 'debounce';

const errorService = new ErrorService();
const sessionsService = new SessionService();
const patientService = new PatientsService();
const medicinesService = new MedicinesService();
const adminService = new AdminServices();

const AddPrescriptionsModal = (props) => {
  const [isLoading, setIsLoading] = React.useState();
  const [loadingMedicine, setLoadingMedicine] = React.useState(false);
  const [loadingStock, setLoadingStock] = React.useState(false);
  const [errMsgStock, setErrMsgStock] = React.useState('');
  
  const [toasts, setToasts] = React.useState([]);
  const [isModalForm, setIsModalForm] = React.useState(false);
  const showModal = () => setIsModalForm(true);
  const closeModal = () => setIsModalForm(false);
  const [isModalConfirm, setIsModalConfirm] = React.useState(false);
  const showModalConfirm = () => {
    hideEditNote();
    setIsModalConfirm(true);
  };
  const closeModalConfirm = () => setIsModalConfirm(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [patient, setPatient] = React.useState({});
  const [addForm, setAddForm] = React.useState(false);
  const showFormAdd = () => setAddForm(true);
  const hideFormAdd = () => {
    setAddForm(false);
    setFormEdit(false);
    setDosis(0);
    setNumero(0);
    setNote();
    setCheckCompounded(false);
    setPrescCompounded([]);
    setIfNeed(false);
  };
  const [formEdit, setFormEdit] = React.useState(false);
  const showFormEdit = () => setFormEdit(true);
  const hideFormEdit = () => {
    setAddForm(false);
    setFormEdit(false);
    setSelected([]);
    setDosis(0);
    setNumero(0);
    setNote();
    setCheckCompounded(false);
    setPrescCompounded([]);
    setIfNeed(false);
  };
  const [id, setId] = React.useState();
  const [selectedOptions, setSelected] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [itemRef, setItemRef] = React.useState();
  const [optionsUnit, setOptionsUnit] = React.useState([]);
  const [numero, setNumero] = React.useState(0.0);
  const [unitNumero, setUnitNumero] = React.useState('');
  const [dosis, setDosis] = React.useState(0.0);
  const [unitDosis, setUnitDosis] = React.useState('');
  const [optionsInstruction, setOptionsInstruction] = React.useState();
  const [instruction, setInstruction] = React.useState();
  const [optionsFrequency, setOptionsFrequency] = React.useState([]);
  const [frequency, setFrequency] = React.useState();
  const [note, setNote] = React.useState();
  const [notePrescription, setNotePrescription] = React.useState('');
  const [editNote, setEditNote] = React.useState(false);
  const showEditNote = () => setEditNote(true);
  const hideEditNote = () => {
    setItemRef((prev) => ({
      ...prev,
      note: notePrescription,
    }));
    setEditNote(false);
  };
  const [checkCompounded, setCheckCompounded] = React.useState(false);
  const [ifNeed, setIfNeed] = React.useState(false);
  const [prescCompounded, setPrescCompounded] = React.useState([]);
  const [isEPrescription, setIsEPrescription] = React.useState(false);

  const onChangeDrug = async (medicine) => {
    if (!isEPrescription) {
      try {
        const medicineId = [medicine[0].metadata.code]
        const res = await medicineStock(medicineId)
        if (res === undefined) {
          setErrMsgStock(`Stok obat ${medicine[0]?.label} kosong. Silahkan pilih alternatif lain`)
        }
        if (res.result.length > 0) {
          if (errMsgStock !== '') {
            setErrMsgStock('')
          }
          if (res.result[0].items[0].stockCount > 0) {
            medicine[0].metadata.stock = res.result[0].items[0].stockCount
            setSelected(medicine);
            if (medicine[0].size_unit) {
              setUnitNumero(medicine[0].size_unit);
              setUnitDosis(medicine[0].size_unit);
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      setErrMsgStock('');
      setSelected(medicine);
      if (medicine[0]?.size_unit) {
        setUnitNumero(medicine[0].size_unit);
        setUnitDosis(medicine[0].size_unit);
      }
    }
  };

  const onChangeNumero = (e) => {
    const re = /^[0-9 .\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setNumero(e.target.value);
    }
  };

  const onChangeDosis = (e) => {
    const re = /^[0-9 .\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setDosis(e.target.value);
    }
  };

  const onFormEdit = (id) => {
    showFormEdit();
    var index = items.map((e) => e.id).indexOf(id);
    let item = items[index];
    let medicine = [];
    medicine.push({
      id: item.medicine_id,
      name: item.medicine_name,
      label: item.medicine_name,
    });

    setId(id);
    setSelected(medicine);
    setNumero(item.numero);
    setUnitNumero(item.numero_unit);
    setFrequency(item.dose_frequency);
    setDosis(item.dose_size);
    setUnitDosis(item.dose_size_unit);
    setInstruction(item.instruction);
    setNote(item.note);
    showFormAdd();
    if ((item.compounded_items || []).length > 0) {
      setCheckCompounded(true);
      setPrescCompounded(item.compounded_items);
    }
  };

  const onClick = async () => {
    if (props.item.status !== 4) {
      swal({
        title: '',
        icon: 'error',
        type: 'error',
        text: `Mohon maaf, sesi masih aktif.\nSilakan ditutup terlebih dulu`,
      });
      return;
    }
    if (await alreadyExistPrescription(props.item.id)) {
      swal({
        title: '',
        icon: 'error',
        type: 'error',
        text: `Mohon maaf, resep obat sudah terbuat`,
      });
      return;
    }
    showModal();
    getPatientData().then((patient) => {
      getSizeUnit(patient.preferred_pharmacy_vendor);
      getMedicineInstructions();
      getFrequency();
      getPresc();
    });
  };

  const alreadyExistPrescription = async (sessionId) => {
    try {
      let _presc = await sessionsService.getPrescriptionBySessionId(sessionId);
      console.log(_presc !== null);
      console.log(_presc.items !== null);
      if (_presc !== null && _presc.items !== null) {
        return true;
      }
    } catch (e) {
      // skip not found error
      if (e.response.status !== 404) {
        console.log(e);
      }
    }
    return false;
  };

  const initItemRef = (patient) => {
    let data = {
      id: null,
      session_id: props.item.id,
      patient_id: props.item.patientId,
      author_id: props.item.staffId,
      external_id: props.item.externalId,
      note: notePrescription === '' ? null : notePrescription,
      preferred_pharmacy: patient.preferred_pharmacy,
      added_by_admin: true,
    };
    setItemRef(data);
  };

  const getPatientData = () => {
    return new Promise((resolve, reject) => {
      patientService
        .getPatient(props.item.patientId)
        .then((data) => {
          setPatient(data);
          initItemRef(data);
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  const handleSearchDebounce = debounce(async (searchValue) => {
    setLoadingMedicine(true);
    medicinesService
      .searchMedicines(searchValue, patient.preferred_pharmacy_vendor)
      .then((v) => {
    let item = [];
        for (let i in v.items) {
          item.push({
            id: v.items[i].id,
            name: v.items[i].name,
            label: v.items[i].name,
            size_unit: v.items[i].size_unit,
            metadata: v.items[i],
          });
          setOptions(item);
        }
      })
    .catch((err) => console.log(err)).finally(() => setLoadingMedicine(false));
  }, 600)

  const onSearchMedicine = (searchValue) => {
    setOptions([])
    console.log('called onSearchMedicine : ', searchValue, '');
    if (!searchValue) {
      return;
    }
    handleSearchDebounce(searchValue)
  };

  const getFrequency = () => {
    medicinesService
      .getMedicineDosages()
      .then((v) => {
        let items = [];
        let nameSame;
        for (let i in v.items) {
          if ((items.length > 0 && nameSame) == v.items[i].dose) {
            continue;
          }
          nameSame = v.items[i].dose;
          items.push({
            id: v.items[i].id,
            name: v.items[i].dose,
            text: v.items[i].dose,
          });
        }

        // add choose option
        items = [
          {
            text: 'Pilih satuan',
            value: '',
            disabled: true,
          },
          ...items,
        ];

        setOptionsFrequency(items);
      })
      .catch((err) => console.log(err));
  };

  const getMedicineInstructions = () => {
    medicinesService
      .getMedicineInstructions()
      .then((v) => {
        let items = [];
        for (let i in v.items) {
          items.push({
            id: v.items[i].id,
            name: v.items[i].name,
            text: v.items[i].name,
          });
        }

        // add choose option
        items = [
          {
            text: 'Pilih satuan',
            value: '',
            disabled: true,
          },
          ...items,
        ];

        setOptionsInstruction(items);
      })
      .catch((err) => console.log(err));
  };

  const getSizeUnit = (vendor) => {
    medicinesService
      .getSizeUnit(patient.preferred_pharmacy_vendor || vendor)
      .then((v) => {
        let items = [];
        for (let i in v.items) {
          items.push({
            id: v.items[i].id,
            name:
              v.items[i].external_id && v.items[i].external_id.length > 0
                ? v.items[i].external_id
                : v.items[i].name,
            text:
              v.items[i].external_id && v.items[i].external_id.length > 0
                ? v.items[i].external_id
                : v.items[i].name,
          });
        }

        // add choose option
        items = [
          {
            text: 'Pilih satuan',
            value: '',
            disabled: true,
          },
          ...items,
        ];

        setOptionsUnit(items);
      })
      .catch((err) => console.log(err));
  };

  const [listComp, setListComp] = React.useState([]);

  const toggleDetails = (item) => {
    const list = { ...listComp };
    if (list[item.id]) {
      delete list[item.id];
    } else {
      let comp = [];
      item.compounded_items.map((v) => {
        comp.push({
          title: v.medicine[0].name,
          description: `${v.numero} ${v.numero_unit}`,
        });
      });
      list[item.id] = <EuiDescriptionList listItems={comp} />;
    }
    setListComp(list);
  };

  const columns = [
    {
      field: 'medicine_name',
      name: 'Nama Obat',
      width: '32%',
      render: (id, item) => {
        return (
          <>
            {(item.compounded_items || []).length > 0 ? (
              <EuiBadge style={{ marginRight: '2px' }} color="#FCF7BC">
                R {item.if_needed ? '(Jika Perlu)' : ''}
              </EuiBadge>
            ) : (
              item.medicine_name
            )}
          </>
        );
      },
    },
    {
      field: 'numero',
      name: 'Numero',
      width: '11%',
      render: (id, item) => {
        return `${item.numero} ${item.numero_unit}`;
      },
    },
    {
      field: 'dosis',
      name: 'Dosis',
      width: '11%',
      render: (id, item) => {
        return `${item.dose_size} ${item.dose_size_unit}`;
      },
    },
    {
      field: 'dose_frequency',
      name: 'Frekuensi',
      width: '15%',
    },
    {
      field: 'instruction',
      name: 'Instruksi',
      width: '15%',
    },
    {
      field: 'note',
      name: 'Catatan Obat',
      width: '20%',
    },
    {
      field: 'id',
      name: 'Action',
      width: '10%',
      render: (id, item) => {
        return isModalConfirm ? (
          item.compounded_items.length > 0 ? (
            <EuiButtonIcon
              onClick={() => toggleDetails(item)}
              aria-label={listComp[item.id] ? 'Collapse' : 'Expand'}
              iconType={listComp[item.id] ? 'arrowUp' : 'arrowDown'}
            />
          ) : null
        ) : (
          <>
            <EuiButtonEmpty
              isDisabled={isDisabled}
              size="xs"
              className="btnList"
              onClick={() => onFormEdit(id)}
            >
              <EuiIcon type="documentEdit" color="warning" />
            </EuiButtonEmpty>
            <EuiButtonEmpty
              isDisabled={isDisabled}
              size="xs"
              className="btnList"
              onClick={() => deleteItem(id)}
            >
              <EuiIcon type="trash" color="danger" />
            </EuiButtonEmpty>
          </>
        );
      },
    },
    {
      align: RIGHT_ALIGNMENT,
      width: '40px',
      isExpander: true,
      render: (item) => {
        return isModalConfirm ? null : item.compounded_items.length > 0 ? (
          <EuiButtonIcon
            onClick={() => toggleDetails(item)}
            aria-label={listComp[item.id] ? 'Collapse' : 'Expand'}
            iconType={listComp[item.id] ? 'arrowUp' : 'arrowDown'}
          />
        ) : null;
      },
    },
  ];

  const clear = () => {
    setSelected([]);
    setDosis(0);
    setNumero(0);
    setUnitNumero('');
    setUnitDosis('');
    setNote();
    setCheckCompounded(false);
    setPrescCompounded([]);
    setIfNeed(false);
    hideFormAdd();
    hideFormEdit();
  };

  const addItem = () => {
    let numeroFloat = parseFloat(numero);
    let dosisFloat = parseFloat(dosis);

    console.log('selectedOptions =>', selectedOptions);
    console.log('numeroFloat =>', numeroFloat);
    console.log('dosisFloat =>', dosisFloat);
    console.log('unitNumero =>', unitNumero);
    console.log('unitDosis =>', unitDosis);
    console.log('prescCompounded =>', prescCompounded);

    let isValidMedicines = false;
    // check if medicine is compounded
    if (checkCompounded) {
      if (prescCompounded.length > 0) {
        let hasEmptyField = prescCompounded.some((v) => {
          return (
            !v.medicine ||
            v.medicine.length == 0 ||
            !v.numero ||
            !v.numero_unit ||
            v.numero_unit === '-'
          );
        });

        isValidMedicines = !hasEmptyField;
      }
    } else {
      isValidMedicines = selectedOptions.length > 0;
    }

    if (
      !isValidMedicines ||
      !numeroFloat ||
      !dosisFloat ||
      !unitNumero ||
      unitNumero === '-' ||
      !unitDosis ||
      unitDosis === '-' ||
      !frequency
    ) {
      setToasts(
        toasts.concat({
          title: <b>Terjadi kesalahan</b>,
          color: 'danger',
          iconType: 'alert',
          text: <p>Ada data yang belum di isi!. Silahkan periksa kembali</p>,
        })
      );
      return;
    }

    setItems([
      ...items,
      {
        id: items.length,
        medicine_id: checkCompounded ? null : selectedOptions[0].id,
        dose_frequency: frequency,
        medicine_name: checkCompounded ? null : selectedOptions[0].name,
        instruction: instruction,
        note: note,
        numero: numeroFloat,
        numero_unit: unitNumero,
        dose_size: dosisFloat,
        dose_size_unit: unitDosis,
        patient_id: props.item.patientId,
        author_id: props.item.staffId,
        session_id: props.item.id,
        if_needed: ifNeed,
        compounded_items: prescCompounded,
      },
    ]);
    setToasts(
      toasts.concat({
        title: <b>Success!!</b>,
        color: 'success',
        iconType: 'check',
        text: (
          <p>
            Obat{' '}
            {checkCompounded ? 'Racikan' : <b>{selectedOptions[0].name}</b>}{' '}
            berhasil ditambahkan!
          </p>
        ),
      })
    );
    clear();
  };

  const editItem = () => {
    let index = items.map((e) => e.id).indexOf(id);
    let item = items[index];

    let numeroFloat = parseFloat(numero);
    let dosisFloat = parseFloat(dosis);

    if (selectedOptions.length == 0 || !numeroFloat || !dosisFloat) {
      setToasts(
        toasts.concat({
          title: <b>Terjadi kesalahan</b>,
          color: 'danger',
          iconType: 'alert',
          text: <p>Ada data yang belum di isi!. Silahkan periksa kembali</p>,
        })
      );
      return;
    }

    item.medicine_name = selectedOptions[0].name;
    item.medicine_id = selectedOptions[0].id;
    item.instruction = instruction;
    item.note = note;
    item.numero = numeroFloat;
    item.numero_unit = unitNumero;
    item.dose_size = dosisFloat;
    item.dose_size_unit = unitDosis;
    item.compounded_items = prescCompounded;
    item.dose_frequency = frequency;

    setToasts(
      toasts.concat({
        title: <b>Berhasil di sunting</b>,
        color: 'success',
        iconType: 'check',
      })
    );

    clear();
  };

  const deleteItem = (id) => {
    var index = items.map((e) => e.id).indexOf(id);
    items.splice(index, 1);
    setItems([...items]);
  };

  const getPresc = async () => {
    try {
      let _presc = await sessionsService.getPrescriptionBySessionId(
        props.item.id
      );
      if (_presc != null && _presc.items != null) {
        setItems(_presc.items);
        setItemRef(_presc.ref);
        setIsDisabled(true);
      }
      if (_presc != null && _presc.ref.note != null) {
        setNotePrescription(_presc.ref.note);
      }
    } catch (e) {
      if (e.response.status !== 404) {
        console.log(e);
      }
    }
    return null;
  };

  const prescriptionRefSend = () => {
    itemRef.is_e_prescription = isEPrescription;
    let payload = itemRef;
    return new Promise((resolve, reject) => {
      medicinesService
        .postMedicinePrescriptionRef(payload)
        .then((data) => {
          console.log('prescriptionRefSend success =>', data);
          resolve(data);
        })
        .catch((err) => {
          console.log('prescriptionRefSend error =>', err);
          errorService.handle(err);
          reject(err);
        });
    });
  };

  const prescriptionSend = () => {
    return new Promise((resolve, reject) => {
      let request = [];
      (items || []).forEach((v, i) => {
        let payload = {
          session_id: props.item.id,
          patient_id: props.item.patientId,
          author_id: props.item.staffId,
          dose_frequency: v.dose_frequency,
          medicine_name: v.medicine_name,
          medicine_id: v.medicine_id,
          instruction: v.instruction,
          note: v.note,
          numero: v.numero,
          numero_unit: v.numero_unit,
          dose_size: v.dose_size,
          dose_size_unit: v.dose_size_unit,
          if_needed: v.if_needed,
          // map presc_compounded medicine to medicine_id
          compounded_items: (v.compounded_items || []).map((v) => {
            return {
              medicine_id: v.medicine[0].id,
              numero: parseFloat(v.numero),
              numero_unit: v.numero_unit,
            };
          }),
        };

        request.push(medicinesService.postMedicinePrescription(payload));
      });

      Promise.all(request)
        .then((data) => {
          console.log('prescriptionSend success =>', data);
          resolve(data);
        })
        .catch((err) => {
          console.log('prescriptionSend error =>', err);
          errorService.handle(err);
          reject(err);
        });
    });
  };

  // temporary unused since send prescription
  // need to be manually done in the prescriptions page
  const createEvent = () => {
    let code = 'THIRDPARTY_SEND_PRESCRIPTION';
    let secondaryKey = props.item.id; // Session Id
    adminService
      .createManualEvent(code, secondaryKey)
      .then((data) => {
        console.log('createEvent =>', data);
        setToasts(
          toasts.concat({
            title: <b>THIRDPARTY_SEND_PRESCRIPTION</b>,
            color: 'success',
            iconType: 'check',
          })
        );
      })
      .catch((err) => {
        setToasts(
          toasts.concat({
            title: <b>THIRDPARTY_SEND_PRESCRIPTION</b>,
            color: 'danger',
            iconType: 'alert',
            text: <p>{err}</p>,
          })
        );
        errorService.handle(err);
      });
  };

  const onSend = async () => {
    setIsLoading(true);
    try {
      await prescriptionRefSend();
      await prescriptionSend();
      swal({
        title: 'Berhasil',
        text: 'Resep berhasil dibuat',
        icon: 'success',
        button: 'OK',
      }).then(() => {
        // refresh the page
        window.location.reload();
      });
    } catch (e) {
      console.log(e);
      // parse error message
      let message = e?.message || '';
      if (e?.response?.data?.code) {
        message = e.response.data.code;
      }
      swal({
        title: 'Resep gagal diubah',
        text: message,
        icon: 'error',
        button: 'OK',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const vendor = (v) => {
    if (!v) return '-';
    switch (v) {
      case 'kimiafarma':
        return v.toUpperCase();
      case Config.variant:
        return v.toUpperCase();
      case undefined:
        return 'Loading...';
      case '':
        return 'Belum ada farmasi yang dipilih';
      default:
        return v.toUpperCase();
    }
  };

  const itemTitle = (title) => {
    return (
      <EuiFlexItem style={{ justifyContent: 'center', whiteSpace: 'pre-line' }}>
        {title}
      </EuiFlexItem>
    );
  };

  const addItemCompounded = () => {
    setPrescCompounded((s) => {
      return [
        ...s,
        {
          id: prescCompounded.length,
          medicine: [],
          numero: 0,
          numero_unit: optionsUnit[0].name,
        },
      ];
    });
  };

  const onChangeMedicineComp = async (v, index) => {
    console.log(v, index);
    try {
      const medicineId = [v[0].metadata.code]
      const res = await medicineStock(medicineId)
      if (res === undefined) {
        setErrMsgStock(`Stok obat ${v[0]?.label} kosong. Silahkan pilih alternatif lain`)
      }
      if (res.result.length > 0) {
        if (errMsgStock !== '') {
          setErrMsgStock('')
        }
        if (res.result[0].items[0].stockCount > 0) {
          let item = [...prescCompounded];
          let obj = item[index];
          obj.medicine = v;
          if (v.length > 0 && v[0].size_unit) {
            obj.numero_unit = v[0].size_unit;
          }
          obj.medicine[0].metadata.stock = res.result[0].items[0].stockCount
          item[index] = obj;
          setPrescCompounded([...item]);
        }
      }
    } catch (error) {
      console.log(error)
    }
  };

  const onChangeNumeroComp = (e, index) => {
    const re = /^[0-9 .\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      let item = [...prescCompounded];
      let obj = item[index];
      obj.numero = e.target.value;
      item[index] = obj;
      setPrescCompounded([...item]);
    }
  };

  const onChangeNumeroUnitComp = (e, index) => {
    let item = [...prescCompounded];
    let obj = item[index];
    obj.numero_unit = e.target.value;
    item[index] = obj;
    setPrescCompounded([...item]);
  };

  const deleteItemCompounded = (id) => {
    var index = prescCompounded.map((e) => e.id).indexOf(id);
    prescCompounded.splice(index, 1);
    setPrescCompounded([...prescCompounded]);
    if (prescCompounded.length === 0) {
      setCheckCompounded(false);
    }
  };

  const medicineStock = async (medicineIds) => {
    try {
      setLoadingStock(true);
      let payload = {
        medicine_ids: medicineIds,
        preferred_pharmacy_id: itemRef.preferred_pharmacy
      };
      let res = await medicinesService.getMedicinesStock(payload);
      if (res.result.length > 0) {
        return res
      }
    } catch (error) {
      console.log("getStockMedicine error =>", error);
    } finally {
      setLoadingStock(false);
    }
  };

  const formCompounded = (
    <>
      <EuiSpacer />
      <EuiForm className="form_compounded">
        <EuiFlexGroup
          columns={2}
          style={{ margin: 0 }}
          justifyContent="spaceBetween"
        >
          <EuiFlexItem
            style={{
              fontWeight: '700',
              justifyContent: 'center',
              whiteSpace: 'pre-line',
              margin: 0,
            }}
          >
            Daftar Racikan
          </EuiFlexItem>
          <EuiButton
            size="s"
            color="secondary"
            iconType="plusInCircle"
            onClick={addItemCompounded}
          >
            Tambah
          </EuiButton>
        </EuiFlexGroup>
        {(prescCompounded || []).map((item, i) => {
          return (
            <>
              <EuiSpacer size="s" />
              <EuiFlexGroup
                columns={2}
                style={{
                  margin: 0,
                  borderTop: '1px solid #d3d9e3',
                  paddingTop: '8px',
                }}
              >
                <EuiComboBox
                  isInvalid={item.medicine.length === 0}
                  placeholder="Masukkan nama obat terlebih dahulu"
                  options={options}
                  onSearchChange={onSearchMedicine}
                  onChange={(e) => onChangeMedicineComp(e, i)}
                  selectedOptions={item.medicine}
                  singleSelection={{ asPlainText: true }}
                  isLoading={loadingMedicine}
                  append={item.medicine.length > 0 && !isEPrescription && `${item.medicine[0].metadata.stock || 0}`}
                />
                <EuiFlexItem style={{ justifyContent: 'center' }}>
                  <EuiIcon
                    cursor="pointer"
                    type="trash"
                    color="danger"
                    size="m"
                    onClick={() => deleteItemCompounded(item.id)}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiFlexGroup columns={2} style={{ margin: 0 }}>
                <EuiFlexItem style={{ marginLeft: 0 }}>
                  <EuiFieldText
                    id={i}
                    isInvalid={item.numero <= 0}
                    placeholder="Masukkan numero"
                    value={item.numero}
                    onChange={(e) => onChangeNumeroComp(e, i)}
                  />
                </EuiFlexItem>
                <EuiFlexItem style={{ marginRight: 0 }} grow={false}>
                  <EuiSelect
                    id="sizeUnitNumero"
                    isInvalid={!item.numero_unit || item.numero_unit == '-'}
                    options={optionsUnit}
                    value={item.numero_unit}
                    onChange={(e) => onChangeNumeroUnitComp(e, i)}
                    aria-label="Numero"
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
          );
        })}
      </EuiForm>
    </>
  );

  const content = (
    <>
      <EuiForm>
        {!addForm ? (
          <>
            <EuiFormRow label="Apotek">
              <EuiFieldText
                name="vendor"
                value={vendor(patient.preferred_pharmacy_vendor)}
                readOnly={true}
              />
            </EuiFormRow>
            <EuiFormRow label="Outlet">
              <EuiFieldText
                name="outlet"
                value={
                  props.item.preferredPharmacy
                    ? props.item.preferredPharmacy
                    : 'Loading...'
                }
                readOnly={true}
              />
            </EuiFormRow>
            <br />

            <EuiBasicTable
              noItemsMessage={'Belum ada data'}
              itemIdToExpandedRowMap={listComp}
              isExpandable={true}
              items={items}
              columns={columns}
            />
          </>
        ) : null}
        {addForm ? (
          <EuiForm>
            <EuiSpacer />
            <EuiFlexGroup
              style={{ margin: 0 }}
              columns={2}
              justifyContent="spaceBetween"
            >
              <EuiFlexItem>
              <EuiTitle size="xs">
                <h3>{addForm ? 'Tambah Obat' : 'Edit Obat'}</h3>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {loadingStock && <EuiLoadingSpinner size="l" /> }
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiSwitch
                label="Racikan"
                checked={checkCompounded}
                onChange={(e) => setCheckCompounded(e.target.checked)}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiSwitch
                label="E-resep"
                checked={isEPrescription}
                onChange={(e) => setIsEPrescription(e.target.checked)}
              />
            </EuiFlexItem>
            </EuiFlexGroup>
            {errMsgStock !== '' ? <EuiFlexGroup
              style={{ margin: 0 }}
              columns={2}
              justifyContent="spaceBetween"
            ><EuiFlexItem grow={4}><EuiCallOut color="danger">{errMsgStock}</EuiCallOut></EuiFlexItem></EuiFlexGroup> : null}
            {isEPrescription && 
            <EuiFlexGroup
              style={{ margin: 0 }}
              columns={2}
            >
              <EuiFlexItem grow={1}>
                <EuiCallOut title="Perhatian!!!" color="warning" iconType="alert">
                  <EuiText>E-resep telah aktif, sistem tidak akan melakukan pengecekan terhadap stok obat.</EuiText>
                </EuiCallOut>
              </EuiFlexItem>
            </EuiFlexGroup>}
            {checkCompounded ? (
              formCompounded
            ) : (
              <EuiFlexGroup columns={2} style={{ margin: 0 }}>
                {itemTitle('Nama Obat')}
                <EuiFlexItem grow={4}>
                  <EuiComboBox
                    isInvalid={selectedOptions.length === 0}
                    placeholder="Masukkan nama obat terlebih dahulu"
                    options={options}
                    selectedOptions={selectedOptions}
                    onSearchChange={onSearchMedicine}
                    onChange={onChangeDrug}
                    singleSelection={{ asPlainText: true }}
                    isLoading={loadingMedicine}
                    append={selectedOptions.length > 0 && !isEPrescription &&  `${selectedOptions[0].metadata.stock || 0}`}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
            <EuiFlexGroup columns={3} style={{ margin: 0 }}>
              {itemTitle('Numero')}
              <EuiFlexItem grow={2}>
                <EuiFieldText
                  isInvalid={numero <= 0}
                  placeholder="Masukkan numero"
                  value={numero}
                  onChange={(e) => onChangeNumero(e)}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={2}>
                <EuiSelect
                  id="sizeUnitNumero"
                  isInvalid={!unitNumero || unitNumero === '-'}
                  options={optionsUnit}
                  value={unitNumero}
                  onChange={(e) => setUnitNumero(e.target.value)}
                  aria-label="Numero"
                />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup columns={3} style={{ margin: 0 }}>
              {itemTitle('Dosis')}
              <EuiFlexItem grow={2}>
                <EuiFieldText
                  isInvalid={dosis <= 0}
                  placeholder="Masukkan dosis"
                  value={dosis}
                  onChange={(e) => onChangeDosis(e)}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={2}>
                <EuiSelect
                  id="sizeUnitDosis"
                  isInvalid={!unitDosis || unitDosis === '-'}
                  options={optionsUnit}
                  value={unitDosis}
                  onChange={(e) => setUnitDosis(e.target.value)}
                  aria-label="Dosis"
                />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiCallOut title="Perhatian!!!" color="danger" iconType="alert">
              <p>
                Semua dosis obat dalam satuan dosis klinis terkecil:{' '}
                <b>{unitDosis}</b>
              </p>
            </EuiCallOut>
            <EuiFlexGroup columns={2} style={{ margin: 0 }}>
              {itemTitle('Frekuensi')}
              <EuiFlexItem grow={4}>
                <EuiSelect
                  id="frequency"
                  options={optionsFrequency}
                  value={frequency}
                  onChange={(e) => setFrequency(e.target.value)}
                  aria-label="Masukkan frequency"
                />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup columns={2} style={{ margin: 0 }}>
              {itemTitle('Instruksi')}
              <EuiFlexItem grow={4}>
                <EuiSelect
                  id="instruction"
                  options={optionsInstruction}
                  value={instruction}
                  onChange={(e) => setInstruction(e.target.value)}
                  aria-label="Masukkan instruksi"
                />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup columns={2} style={{ margin: 0 }}>
              {itemTitle('Catatan Obat')}
              <EuiFlexItem grow={4}>
                <EuiTextArea
                  placeholder="Masukkan catatan obat"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  aria-label="Note"
                />
              </EuiFlexItem>
            </EuiFlexGroup>
            {checkCompounded ? (
            <EuiFlexGroup columns={2} style={{ margin: 0, justifyContent: 'right' }}>
            <EuiFlexItem grow={false}>
            <EuiSwitch
                    label="Jika Perlu"
                    checked={ifNeed}
                    onChange={(e) => setIfNeed(e.target.checked)}
                  />
              </EuiFlexItem>
            </EuiFlexGroup>
                ) : null}
          </EuiForm>
        ) : null}

        <EuiSpacer />
      </EuiForm>
    </>
  );
  let modal;
  if (isModalForm) {
    modal = (
      <>
        <EuiOverlayMask>
          <EuiModal
            onClose={closeModal}
            maxWidth={false}
            style={{ maxWidth: 1200 }}
          >
            <div style={{ overflowY: 'auto' }}>
              {addForm ? null : (
                <EuiModalHeader>
                  <EuiModalHeaderTitle>
                    <span style={{ marginRight: 20 }}>Tambah Resep Obat</span>
                    <EuiButton
                      isDisabled={
                        patient.preferred_pharmacy_vendor === '' ||
                        patient.preferred_pharmacy_vendor === undefined ||
                        isDisabled
                      }
                      color={'secondary'}
                      size="s"
                      iconType={'plusInCircleFilled'}
                      fill
                      onClick={showFormAdd}
                    >
                      Tambah Obat
                    </EuiButton>
                  </EuiModalHeaderTitle>
                  {patient.preferred_pharmacy_vendor === undefined ? (
                    <EuiCallOut
                      title="Tidak bisa menambahkan Resep Obat"
                      color="danger"
                      iconType="alert"
                    >
                      <p>
                        Silakan pilih apotek terlebih dahulu.
                        <EuiLink href={'/patient/detail/' + patient.id}>
                          Click
                        </EuiLink>
                      </p>
                    </EuiCallOut>
                  ) : null}
                </EuiModalHeader>
              )}
              <EuiModalBody>{content}</EuiModalBody>
              
              { addForm && <EuiModalFooter style={{ paddingTop: 0 }}>
              <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
                <EuiButton
                  isDisabled={
                    patient.preferred_pharmacy_vendor === '' ||
                    patient.preferred_pharmacy_vendor === undefined ||
                    isDisabled
                  }
                  color={'danger'}
                  iconType="crossInACircleFilled"
                  onClick={hideFormAdd}
                >
                  Batal
                </EuiButton>
              </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <EuiButton
                  color={formEdit ? 'warning' : 'secondary'}
                  onClick={formEdit ? editItem : addItem}
                >
                  {formEdit ? 'Ubah' : 'Tambah'}
                </EuiButton>
              </EuiFlexItem>
              </EuiFlexGroup>
              </EuiModalFooter>}
            </div>
            {addForm ? null : (
              <>
                <EuiText
                  style={{
                    padding: '0 15px',
                    maxWidth: '75%',
                    margin: '10px 0 -15px 0',
                  }}
                >
                  <h4>
                    Catatan Resep:{' '}
                    <EuiIcon
                      color={isDisabled ? '#DDDDDD' : 'primary'}
                      style={{ cursor: 'pointer' }}
                      onClick={
                        isDisabled
                          ? null
                          : editNote
                          ? hideEditNote
                          : showEditNote
                      }
                      type="documentEdit"
                    />
                  </h4>
                  {editNote ? (
                    <EuiTextArea
                      placeholder="Masukkan catatan obat"
                      value={notePrescription}
                      onChange={(e) => setNotePrescription(e.target.value)}
                      aria-label="Note"
                    />
                  ) : (
                    <p>{notePrescription}</p>
                  )}
                </EuiText>
                <EuiModalFooter style={{ padding: '0 24px 24px !important' }}>
                  {isDisabled ? (
                    <EuiCallOut
                      title="Data Resep Obat sudah ada. Tidak dapat menambah/mengubah resep obat"
                      color="danger"
                      iconType="alert"
                    />
                  ) : null}
                  <EuiButton
                    fill
                    isLoading={isLoading}
                    isDisabled={
                      items.length === 0 || isDisabled
                        ? true
                        : isLoading
                        ? true
                        : false
                    }
                    onClick={showModalConfirm}
                  >
                    Kirim
                  </EuiButton>
                  <EuiButtonEmpty onClick={closeModal}>Batal</EuiButtonEmpty>
                </EuiModalFooter>
              </>
            )}
          </EuiModal>
          <EuiGlobalToastList
            toasts={toasts}
            dismissToast={() => setToasts([])}
            toastLifeTimeMs={6000}
          />
        </EuiOverlayMask>
      </>
    );
  }

  const contentConfirm = (
    <EuiForm>
      <EuiText style={{ fontWeight: '700', fontSize: '18px' }}>Apotek</EuiText>
      <EuiSpacer size="m" />
      <EuiText>{props.item.preferredPharmacy}</EuiText>
      <EuiSpacer size="xl" />
      <EuiBasicTable
        noItemsMessage={'Belum ada data'}
        itemIdToExpandedRowMap={listComp}
        items={items}
        columns={columns}
      />
    </EuiForm>
  );

  let modalConfirm;
  if (isModalConfirm) {
    modalConfirm = (
      <>
        <EuiOverlayMask>
          <EuiModal
            onClose={closeModalConfirm}
            maxWidth={false}
            style={{ maxWidth: 1000, minWidth: 650 }}
          >
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                Apakah yang diinput sudah benar?
              </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>{contentConfirm}</EuiModalBody>

            <EuiText
              style={{
                padding: '0 15px',
                maxWidth: '75%',
                margin: '10px 0 -15px 0',
              }}
            >
              <h4>Catatan Resep:</h4>
              <p>{notePrescription}</p>
            </EuiText>

            <EuiModalFooter>
              <EuiButtonEmpty onClick={closeModalConfirm}>Tidak</EuiButtonEmpty>
              <EuiButton onClick={onSend} isDisabled={items.length == 0} fill>
                {' '}
                Iya
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      </>
    );
  }
  return (
    <>
      <EuiButtonEmpty size="xs" className="btnList" onClick={onClick}>
        Tambah Resep
      </EuiButtonEmpty>
      {modal}
      {modalConfirm}
    </>
  );
};

export default AddPrescriptionsModal;
