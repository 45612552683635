import React, {Component} from 'react';
import {EuiPage, EuiPageBody, EuiLoadingSpinner, EuiButton, EuiBetaBadge, EuiBadge} from '@elastic/eui';
import WhatsappService from '../services/Whatsapp';

const whatsappService = new WhatsappService();

var interval;

class Whatsapp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      is_logged_in: false,
    };
  }

  componentDidMount = () => {
    if (interval) {
      clearInterval(interval);
    }
    this.setState({code: ''}, () => {
      this.getQrcode();
    });
  };
  componentWillUnmount = () => {
    clearInterval(interval);
  };
  getQrcode = () => {
    interval = setInterval(() => {
      if (this.state.is_logged_in) {
        this.setState({code: "", is_logged_in: true});
        clearInterval(interval);
        return;
      }
      whatsappService
        .getQrcode()
        .then(result => {
          console.log(result);
          this.setState(result);
        })
        .catch(err => {
          console.log(err);
          if (err && err.message && err.message.includes('404')) {
            this.setState({is_logged_in: true, code: ""});
          }
        });
    }, 5000);
  };

  logout = () => {
    whatsappService
      .logoutV2()
      .then(result => {
        console.log(result);
        this.setState({is_logged_in: false, code: ""}, () => {
          this.getQrcode();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    return (
      <>
        <EuiPage className="class">
          <EuiPageBody
            style={{flexDirection: 'row', textAlign: 'center', width: '100%'}}
            className={'content-container'}>
            {this.state.code !== "" ? (
              <div style={{margin: '0 auto', marginTop: 30}}>
                <p>
                  Sesi WhatsApp belum terdaftar. Silakan pindai QR code di bawah
                  ini.
                </p>
                <img
                  src={'data:image/png;base64, ' + this.state.code}
                  style={{margin: '0 auto', width: 500, height: 500}}
                />
              </div>
            ) : (
              <div style={{margin: '0 auto', marginTop: 30}}>
                {this.state.is_logged_in ? (
                  <div>
                    <p>Sesi WhatsApp sudah terdaftar.</p>
                    <br />
                    <EuiButton onClick={this.logout}>Logout</EuiButton>
                  </div>
                ) : (
                  <EuiLoadingSpinner size="xl" />
                )}
              </div>
            )}
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default Whatsapp;
