import React, { Component } from 'react';
import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiSpacer,
  EuiPageContentBody,
  EuiTitle,
  EuiButton,
} from '@elastic/eui';
import TablePage from '../components/TablePage';
import Sync from '../services/Sync';
import swal from 'sweetalert';

var getEventStateString = (state) => {
  let str = '';
  switch (state) {
    case 0:
      str = 'Unknown';
      break;
    case 1:
      str = 'Dijadwalkan';
      break;
    case 2:
      str = 'Dibatalkan';
      break;
    case 3:
      str = 'Selesai';
      break;
    case 4:
      str = 'Dijadwalkan segera';
      break;
    case 5:
      str = 'Berhenti dicoba karena melebihi batas maksimal pengulangan';
      break;
  }
  return str;
};
var getEventResultStateString = (state) => {
  let str = '';
  switch (state) {
    case 0:
      str = 'Menunggu worker';
      break;
    case 1:
      str = 'Galat';
      break;
    case 2:
      str = 'Sukses';
      break;
    case 3:
      str = 'Event direset';
      break;
    case 4:
      str = 'Dibatalkan worker';
      break;
  }
  return str;
};

var syncInterval = null

const SyncServices = new Sync();
class SyncBridge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      page: 1,
      limit: 50,
      total: 0,
      order: 'DESC',
      orderBy: 'timestamp',
      loading: false,
      saveLoading: false,
      deleteLoading: false,
      data: [],
      column: [
        {
          field: 'key',
          name: 'ID sekunder',
        },
        {
          field: 'type',
          name: 'Jenis sinkron',
        },
        {
          field: 'scheduled',
          name: 'Jadwal',
        },
        {
          field: 'attempted',
          name: 'Tereksekusi',
        },
        {
          field: 'state',
          name: 'Status',
        },
        {
          field: 'result',
          name: 'Hasil',
        },
      ],
    };
  }

  componentDidMount = () => {
    this.page(1);
    syncInterval = setInterval(() => {
      this.page(1);
    }, 60000);
  };

  componentWillUnmount = () => {
    clearInterval(syncInterval)
  };

  runThirdPartySync = () => {
    this.setState({ loading: true });
    SyncServices.runThirdPartySync()
      .then((result) => {
        this.setState({ loading: false });
        swal({
          title: '',
          icon: 'success',
          type: 'success',
          text: 'Jadwal sinkron telah dibuat',
        });
        this.page(1);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  page = (page) => {
    page = page || 1;
    this.setState({ loading: true });
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };
    var result;
    SyncServices.getThirdpartySyncEvents(payload)
      .then((response) => {
        result = response;
        result.list = result.list || [];
        let list = [];
        for (let i in result.list) {
          if (result.list[i].result_state === 3) continue; // Skip reseted
          list.push({
            key: result.list[i].secondary_key,
            scheduled: result.list[i].scheduled_time > 0 ? new Date(result.list[i].scheduled_time * 1000) : '-',
            attempted: result.list[i].attempt_time > 0 ? new Date(result.list[i].attempt_time * 1000) : 'Belum',
            state: getEventStateString(result.list[i].state),
            result: getEventResultStateString(result.list[i].result_state),
            type: result.list[i].code.indexOf('DAILY') > -1 ? 'Harian' : 'Per 5 menit',
          });
        }
        console.log(list)
        this.setState(
          {
            page: result.page,
            limit: result.limit,  
            total: result.total,
            order: this.state.order,
            orderBy: this.state.order_by,
            data: list,
            loading: false,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          data: [],
        });
      });
  };

  render() {
    return (
      <EuiPage style={{ textAlign: 'left' }}>
        <EuiPageBody className={'content-container'}>
          <EuiPageContent>
            <EuiTitle size="l">
              <h1>Sinkronisasi Pihak Ketiga</h1>
            </EuiTitle>
            <EuiSpacer />
            <EuiPageContentBody>
              <h2 style={{ fontWeight: 'bold' }}>
                Jalankan sinkronisasi
              </h2>
              <br />
              <EuiButton
                onClick={this.runThirdPartySync}
                isLoading={this.state.loading}
              >
                Jalankan sinkronisasi
              </EuiButton>
            </EuiPageContentBody>
            <br />
            <br />
            <TablePage
              title={'Riwayat sinkronisasi'}
              placeholder={'Daftar riwayat sinkronisasi'}
              data={this.state.data}
              column={this.state.column}
              disablePagination={this.state.loading}
              page={this.state.page}
              loading={this.state.loading}
            />
          </EuiPageContent>
        </EuiPageBody>
      </EuiPage>
    );
  }
}

export default SyncBridge;
