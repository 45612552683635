import React, { Component } from 'react';

import {
  EuiButton,
  EuiPage,
  EuiPageBody,
  EuiFormRow,
  EuiButtonIcon,
  EuiButtonEmpty,
  EuiModalFooter,
  EuiTextArea,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiPanel,
  EuiText,
  EuiPopover,
} from '@elastic/eui';
import moment from 'moment';
import Config from '../Config';
import Payments from '../services/Payments';
import SessionService from '../services/Sessions';
import swal from 'sweetalert';
import AddPrescriptionsModal from '../components/AddPrescriptionModal';
import AddDiagnosticsLabModal from '../components/AddDiagnosticsLabModal';
import SessionBadge from './consultation/Session';
import SoapBadge from './consultation/Soap';
import PrescriptionBadge from './consultation/Prescription';
import DiagnosticBadge from './consultation/Laboratory';
import CustomBadge from '../components/Badge';
import ChangeScheduleModal from './consultation/RescheduleModal';
import ChangeActivateEndedSession from './consultation/activateEndedSession';
import CloseSessionModal from './consultation/CloseSessionModal';
import TablePage from '../components/TablePage';


const paymentService = new Payments();
const sessionsService = new SessionService();

class ConsultationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      downloadLoading: false,
      showDescription: false,
      editDescription: false,
      prevDescription: false,
      loadingData: false,
      popOverSecond: {},
      session: {
        id: '',
        patientId: '',
        staffId: '',
        assistantId: '',
        scheduleStartUtc: '',
        scheduleEndUtc: '',
        scheduleType: 0,
        staffKey: '',
        assistantKey: '',
        patientKey: '',
        patientSecret: '',
        roomId: '',
        schedule: '',
        scheduleDate: '',
        scheduleTime: '',
        duration: 0,
        fee: 0,
        appFee: 0,
        oyFee: 0,
        discount: 0,
        grossFee: 0,
        slotNumber: 0,
        slotId: '',
        slotQuantity: 0,
        patientName: '',
        patientPhoneNumber: '',
        patientDOB: '',
        staffName: '',
        assistantName: '',
        clinicId: '',
        clinicName: '',
        status: 0,
        instant: false,
        initiatorId: '',
        pickedDate: '',
        pickedTime: '',
        orderId: '',
        orderNumber: '',
        paymentURL: '',
        paymentMethod: '',
        paymentSenderBank: '',
        patientExternalId: '',
        partnerTxId: '',
        oldSchedule: '',
        isAllowedToJoinSession: false,
        queueState: 0,
        queueTimestamp: '',
        externalId: '',
        sessionReceipt: '',
        sessionReceiptEn: '',
        anamnesis: 0,
        description: '',
        appVersion: '',
        appPlatform: '',
        medicalRecordStatus: 0,
        prescriptionStatus: 0,
        videoconfStatus: 0,
        rescheduleStatus: 0,
        insuranceClaimDoc: '',
        insuranceClaimAttachment: '',
        insuranceStatus: 0,
        insuranceVendor: '',
        current_language: 1,
        staffNameEn: '',
        clinicNameEn: '',
        claimDoc: '',
        medicalReferralLetter: '',
        patientReferral: '',
        labReferralLetter: '',
        labReferralLetterEn: '',
        finishedPlatform: '',
        prescriptionCopy: '',
        prescriptionCopyEn: '',
        preferredLab: '',
        preferredPharmacy: '',
        prescriptionOrderId: '',
        prescriptionPaymentURL: '',
        createdTime: '',
        productId: '',
        product: null,
        tags: null,
        dateOfBirth: 0,
        gender: 0,
      },
      column: [
        {
          field: 'date',
          name: 'Waktu',
          sortable: true,
          width: '10%',
          render: (_, item) => {
            let time = moment(item.scheduleTime, 'HH:mm:ss').format('HH:mm');
            return (
              <div
                className='eui-textBreakWord eui-textLeft textBtn'
                onClick={() =>
                  this.props.history.push('/consultation/detail/' + item.id)
                }
              >
                {item.scheduleDate}/ {time}/ {item.slotNumber}
              </div>
            );
          },
        },
        {
          field: 'staffName',
          name: 'Staff',
          sortable: true,
          width: '13%',
          render: (staffName, data) => (
            <div
              className='eui-textBreakWord eui-textLeft textBtn'
              onClick={() =>
                this.props.history.push('/staff/detail/' + data.staffId)
              }
            >
              {staffName}
            </div>
          ),
        },

        {
          field: 'patientName',
          name: 'Pasien',
          sortable: true,
          width: '15%',
          render: (patientName, data) => {
            return (
              <div
                id='patient-cell'
                className='eui-textBreakWord eui-textLeft textBtn'
                onClick={() =>
                  this.props.history.push('/patient/detail/' + data.patientId)
                }
              >
                {patientName}{' '}
                {data.isVirtualClinic ? (
                  <img
                    src={data.virtualClinicConfig.logo}
                    alt={data.virtualClinicConfig.name}
                    style={{ width: '60px', marginLeft: '5px' }}
                  />
                ) : null}
              </div>
            );
          },
        },
        {
          field: 'clinicName',
          name: 'Klinik',
          sortable: true,
          width: '13%',
          render: (clinicName) => (
            <div className='eui-textBreakWord eui-textLeft'>{clinicName}</div>
          ),
        },
        {
          field: 'id',
          name: 'Status',
          width: '8%',
          render: (sessionId, sessionItem) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '-webkit-fill-available',
              }}
              onClick={() =>
                this.props.history.push(
                  '/consultation/detail/' + sessionItem.id
                )
              }
            >
              <SessionBadge
                sessionId={sessionId}
                sessionList={[sessionItem]}
              />
              <SoapBadge sessionId={sessionId} />
              <PrescriptionBadge sessionId={sessionId} />
              <DiagnosticBadge sessionId={sessionId} />
              {this.isRescheduled(sessionItem.oldSchedule) ? (
                <CustomBadge
                  color={'#ffc03f'}
                  text={'Rescheduled'}
                  iconColor={'#ffc03f'}
                />
              ) : null}
            </div>
          ),
        },
        {
          field: 'id',
          name: 'Aksi',
          width: '8%',
          render: (id, sessionItem) => {
            let logKey = 'logs' + id;
            let statusKey = 'status' + id;
            let labKey = 'lab' + id;
            let paymentKey = 'payment' + id;
            return (
              <div className='eui-textBreakWord textBtn'>
                {/* SiapDok Link */}
                {!moment(sessionItem.scheduleDate).isBefore(
                  moment().format('YYYY-MM-DD')
                ) &&
                Config.weblink !== '' &&
                sessionItem.status === 1 ? (
                  <EuiButtonEmpty
                    size='xs'
                    className='btnList'
                    onClick={() => window.open('/weblink-list/' + id, '_blank')}
                  >
                    SiapDok link
                  </EuiButtonEmpty>
                ) : null}
                <EuiPopover
                  ownFocus={false}
                  button={
                    <EuiButtonEmpty
                      size='xs'
                      className='btnList'
                      onClick={() => this.onPopOverClickSecond(paymentKey)}
                    >
                      Pembayaran
                    </EuiButtonEmpty>
                  }
                  isOpen={this.state.popOverSecond[paymentKey]?.isOpen}
                  closePopover={() => this.onPopOverClickSecond(paymentKey)}
                  anchorPosition='upCenter'
                  panelPaddingSize='none'
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {sessionItem.status === 0 && (
                      <EuiButtonEmpty
                        size='xs'
                        className='btnList'
                        onClick={() => this.checkPayment(id)}
                      >
                        Cek Pembayaran
                      </EuiButtonEmpty>
                    )}
                    <EuiButtonEmpty
                      size='xs'
                      className='btnList'
                      disabled={false}
                      onClick={() =>
                        window.open(sessionItem.paymentURL, '_blank')
                      }
                    >
                      Link Pembayaran Sesi Telekonsul
                    </EuiButtonEmpty>
                    <EuiButtonEmpty
                      size='xs'
                      className='btnList'
                      disabled={sessionItem.status < 1}
                      onClick={() =>
                        this.openReceiptBlob(id, sessionItem.current_language)
                      }
                    >
                      Unduh Kwitansi
                    </EuiButtonEmpty>
                    {sessionItem.orderId && sessionItem.orderId.length > 0 && (
                      <EuiButtonEmpty
                        size='xs'
                        className='btnList'
                        disabled={sessionItem.status === 1}
                        onClick={() =>
                          this.completePayment(sessionItem.orderId)
                        }
                      >
                        Tandai Sesi Telah Dibayar
                      </EuiButtonEmpty>
                    )}
                  </div>

                </EuiPopover>
                <EuiPopover
                  ownFocus={false}
                  button={
                    <EuiButtonEmpty
                      size='xs'
                      className='btnList'
                      onClick={() => this.onPopOverClickSecond(labKey)}
                    >
                      Resep dan Lab
                    </EuiButtonEmpty>
                  }
                  isOpen={this.state.popOverSecond[labKey]?.isOpen}
                  closePopover={() => this.onPopOverClickSecond(labKey)}
                  anchorPosition='upCenter'
                  panelPaddingSize='none'
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <EuiButtonEmpty
                      size='xs'
                      className='btnList'
                      onClick={() =>
                        this.props.history.push({
                          pathname: '/pharmacies/kimia-farma',
                          state: { searchBy: 'session_id', searchString: id },
                        })
                      }
                    >
                      Lihat Resep
                    </EuiButtonEmpty>
                    <AddPrescriptionsModal item={sessionItem} />
                    <EuiButtonEmpty
                      size='xs'
                      className='btnList'
                      onClick={() =>
                        this.props.history.push(
                          '/laboratory-test/' + sessionItem.id
                        )
                      }
                    >
                      Lihat Lab
                    </EuiButtonEmpty>
                    <AddDiagnosticsLabModal item={sessionItem} />
                  </div>
                </EuiPopover>

                <EuiPopover
                  ownFocus={false}
                  button={
                    <EuiButtonEmpty
                      size='xs'
                      className='btnList'
                      onClick={() => this.onPopOverClickSecond(statusKey)}
                    >
                      Status sesi
                    </EuiButtonEmpty>
                  }
                  isOpen={this.state.popOverSecond[statusKey]?.isOpen}
                  closePopover={() => this.onPopOverClickSecond(statusKey)}
                  anchorPosition='upCenter'
                  panelPaddingSize='none'
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <ChangeScheduleModal
                      sessionItem={sessionItem}
                      onScheduleChange={() => this.page(this.state.page)}
                    />
                    {sessionItem.status > 1 && (
                      <ChangeActivateEndedSession
                        sessionItem={sessionItem}
                        onActivate={() => this.page(1)}
                        closePopover={() => this.onPopOverClickSecond(id)}
                      />
                    )}
                    {sessionItem.status === 1 && (
                      <CloseSessionModal
                        sessionItem={sessionItem}
                        onScheduleChange={() => this.page(1)}
                      />
                    )}
                    {sessionItem.status > 1 && (
                      <EuiButtonEmpty
                        size='xs'
                        className='btnList'
                        onClick={() => this.resetStatus(sessionItem)}
                      >
                        Reset Status
                      </EuiButtonEmpty>
                    )}
                   
                  </div>
                </EuiPopover>

                <EuiPopover
                  ownFocus={false}
                  button={
                    <EuiButtonEmpty
                      size='xs'
                      className='btnList'
                      onClick={() => this.onPopOverClickSecond(logKey)}
                    >
                      {'Logs'}
                    </EuiButtonEmpty>
                  }
                  isOpen={this.state.popOverSecond[logKey]?.isOpen}
                  closePopover={() => this.onPopOverClickSecond(logKey)}
                  anchorPosition='upCenter'
                  panelPaddingSize='none'
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <EuiButtonEmpty
                      size='xs'
                      className='btnList'
                      onClick={() =>
                        this.props.history.push({
                          pathname: '/events',
                          state: { secondary_key: id },
                        })
                      }
                    >
                      Log Events
                    </EuiButtonEmpty>
                    <EuiButtonEmpty
                      size='xs'
                      className='btnList'
                      onClick={() => {
                        var endDate;
                        var startDate = sessionItem.createdTime;
                        if (sessionItem.oldSchedule) {
                          let oldSchedule = moment(
                            sessionItem.oldSchedule.split('T')[0]
                          );
                          let scheduleDate = moment(sessionItem.scheduleDate);
                          if (oldSchedule.isBefore(scheduleDate)) {
                            endDate = scheduleDate.format('YYYY-MM-DD');
                          } else {
                            endDate = oldSchedule.format('YYYY-MM-DD');
                          }
                        } else {
                          endDate = sessionItem.scheduleDate;
                        }

                        this.props.history.push({
                          pathname: '/thirdparty-logs',
                          state: {
                            key: 'payload',
                            keyword: id,
                            startDate: startDate,
                            endDate: endDate,
                          },
                        });
                      }}
                    >
                      Log Bridging
                    </EuiButtonEmpty>
                  </div>
                </EuiPopover>
              </div>
            );
          },
          sortable: false,
        },
      ],
    };
  }
  componentDidMount() {
    this.getSessionDetail();
  }

  getSessionDetail() {
    let uuid = this.props.match.params.uuid;
    if (!uuid || (uuid && uuid.length < 1)) {
      this.props.history.push('/');
      return;
    }
    sessionsService
      .getSessionById(uuid)
      .then((result) => {
        // Set session to state
        console.log(result);
        this.setState({
          id: result.id,
          session: result,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  }

  completePayment = (id) => {
    if (id && id.length !== 36) {
      swal({
        title: '',
        icon: 'error',
        type: 'error',
        text: 'Mohon maaf, id order invalid',
      });
    }
    paymentService
      .completePaymentByOrderId(id)
      .then((result) => {
        swal({
          title: 'Sukses',
          icon: 'success',
          type: 'success',
          text: 'Sesi telah dibayar',
        }).then((value) => {
          this.page(this.state.page);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  openReceiptBlob = (id, lang) => {
    this.setState({ downloadLoading: true });
    var langCode = '';
    if (lang === 1) {
      langCode = 'en';
    } else {
      langCode = 'id';
    }
    sessionsService
      .getSessionReceipt(id, langCode)
      .then((value) => {
        var binaryData = [];
        binaryData.push(value);
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: 'application/pdf' })
        );
        link.download = `payment-receipt-${id}`;
        link.click();

        this.setState({
          downloadLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          downloadLoading: false,
        });
      });
  };


  isRescheduled = (oldSchedule) => {
    let time = moment(oldSchedule);
    return time.isValid() && time.year() !== 1;
  };


  checkPayment = (id) => {
    if (id && id.length !== 36) {
      swal({
        title: '',
        icon: 'error',
        type: 'error',
        text: 'Mohon maaf, id invalid',
      });
    }
    paymentService
      .checkPayment(id)
      .then((result) => {
        let text = '';
        if (result.status === 'paid') {
          text = 'Pembayaran telah lunas';
        } else {
          text = 'Pembayaran belum lunas';
        }
        swal({
          title: 'Cek pembayaran telah berhasil',
          icon: 'success',
          type: 'success',
          text: text,
        }).then((value) => {
          this.page(this.state.page);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onPopOverClickSecond = (id) => {
    let state = this.state;
    if (!state.popOverSecond[id]) {
      state.popOverSecond[id] = { isOpen: true };
    } else {
      state.popOverSecond[id] = { isOpen: !state.popOverSecond[id].isOpen };
    }
    this.setState(state);
  };


  render() {
    let session = this.state.session;
    let modalDescription;

    if (this.state.showDescription) {
      modalDescription = (
        <EuiOverlayMask>
          <EuiModal
            onClose={() => this.setState({ showDescription: false })}
            initialFocus='[name=description]'
          >
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                Deskripsi Temu-janji Konsultasi
              </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <EuiPanel>
                <EuiText>
                  <p>
                    <b>{this.state.sessionItem?.patientName}</b>
                    <br />
                    <small>
                      {this.state.sessionItem?.patientExternalId || '-'}
                    </small>
                    <br />
                    <br />
                    <small>
                      <b>{this.state.sessionItem?.staffName}</b>
                    </small>
                    <br />
                    <small>{this.state.sessionItem?.clinicName}</small>
                    <br />
                    <small>
                      {`${moment(this.state.sessionItem?.scheduleDate).format(
                        'dddd'
                      )}`}
                      ,{' '}
                      {`${moment(this.state.sessionItem?.scheduleDate).format(
                        'LL'
                      )}`}{' '}
                      pukul {this.state.sessionItem?.scheduleTime} <br />
                    </small>
                    <br />
                    <small>
                      Rescheduled:{' '}
                      <b>
                        {this.isRescheduled(this.state.sessionItem?.oldSchedule)
                          ? 'Ya'
                          : 'Tidak'}
                      </b>
                    </small>
                    <br />
                    {this.isRescheduled(
                      this.state.sessionItem?.oldSchedule
                    ) && (
                      <small>
                        Jadwal lama:{' '}
                        {moment(this.state.sessionItem?.oldSchedule).format(
                          'dddd, LL [pukul] HH:mm:ss'
                        )}
                      </small>
                    )}
                  </p>
                </EuiText>
              </EuiPanel>
              <EuiFormRow label='Deskripsi'>
                <div style={{ position: 'relative' }}>
                  {!this.state.editDescription && (
                    <EuiButtonIcon
                      style={{ position: 'absolute', top: 0, right: 0 }}
                      onClick={() => this.setState({ editDescription: true })}
                      iconType='indexEdit'
                      aria-label='option'
                      color='secondary'
                      size='m'
                    />
                  )}
                  <EuiTextArea
                    placeholder={
                      this.state.editDescription ? 'Masukan deskripsi...' : ''
                    }
                    aria-label='description'
                    value={this.state.description}
                    resize={this.state.editDescription ? 'both' : 'none'}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    readOnly={!this.state.editDescription}
                  />
                </div>
              </EuiFormRow>
            </EuiModalBody>
            {this.state.editDescription && (
              <EuiModalFooter>
                <EuiButton
                  isLoading={this.state.loadingDescription}
                  onClick={() => {
                    let payload = {};
                    payload.Status = this.state.sessionItem.status;
                    payload.Description = this.state.description;
                    payload.Id = this.state.sessionItem.id;

                    this.setState({ loadingDescription: true }, () => {
                      sessionsService
                        .updateSessionDescription(payload)
                        .then(() => {
                          swal({
                            title:
                              'Berhasil Sunting Deskripsi Temu-Janji Konsultasi',
                            icon: 'success',
                            type: 'success',
                          }).then((value) => {
                            this.setState(
                              {
                                loadingDescription: false,
                                editDescription: false,
                                showDescription: false,
                                loadingData: true,
                              },
                              () => this.page(this.state.page)
                            );
                          });
                        })
                        .catch((err) => {
                          console.log(err);
                          this.setState({ loadingDescription: false });
                        });
                    });
                  }}
                  fill
                  disabled={!this.state.description}
                >
                  Submit
                </EuiButton>
                <EuiButtonEmpty
                  onClick={() =>
                    this.setState({
                      editDescription: false,
                      description: this.state.prevDescription,
                    })
                  }
                >
                  Batal
                </EuiButtonEmpty>
              </EuiModalFooter>
            )}
          </EuiModal>
        </EuiOverlayMask>
      );
    }
    return (
      <>
        <EuiPage className='euiNavDrawerPage'>
          {modalDescription}
          <EuiPageBody className={'content-container'}>
            <TablePage
              title={'Detail Konsultasi'}
              backButton={true}
              data={[session]}
              column={this.state.column}
              loading={this.state.loadingData}
              disablePagination={true}
            />
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default ConsultationDetail;
