import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';

const ErrorService = new Error();
const MiscService = new Misc();

class ThirdpartyLogs {
  getThirdpartyLogs = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.limit = payload.limit || 10;
      payload.order = payload.order || 'DESC';
      payload.search = payload.search || '';
      payload.page = payload.page || '';
      payload.key = payload.key || '';

      payload.startDate = payload.startDate || '';
      payload.endDate = payload.endDate || '';
      let url = `${Config.apiHost}/api/thirdparty/logs?page=${payload.page}&limit=${payload.limit}&order=${payload.order}`;

      if (payload.search !== '' && payload.key !== '') {
        url += `&search=${payload.search}&key=${payload.key}`;
      }

      if (payload.startDate !== '' && payload.endDate !== '') {
        url += `&start_date=${payload.startDate}&end_date=${payload.endDate}`;
      }

      if (payload.startDateUnix !== 0) {
        url += `&start_date_unix=${payload.startDateUnix}`;
      }

      if (payload.endDateUnix !== 0) {
        url += `&end_date_unix=${payload.endDateUnix}`;
      }

      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getThirdpartyLogsById = (id) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/thirdparty/logs/${id}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };
}

export default ThirdpartyLogs;
